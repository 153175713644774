import { CaseData, CaseUser, PageState } from "@vp/models";
import { CaseDataFilter } from "../models/case-data-filter";
import { CaseFilterStateModel } from "./case-filter.state";

export class ResetState {
  public static readonly type = "[Case Filter] Reset";
}

export class SetFilter {
  public static readonly type = "[Case Filter] Set Filter";
  constructor(public filter: Partial<CaseDataFilter>) {}
}

export class SetFilterState {
  public static readonly type = "[Case Filter] Set Filter State";
  constructor(public filter: Partial<CaseDataFilter>) {}
}

export class ResetFilterState {
  public static readonly type = "[Case Filter] Reset Filter State";
}

export class ResetPageState {
  public static readonly type = "[Case Filter] Reset Filter State";
}

export class GetFiltered {
  public static readonly type = "[Case Filter] Get Filtered";
}

export class RefreshCase {
  public static readonly type = "[Case Filter] Get Filtered Case";
  constructor(public caseId: string) {}
}

export class SetPageState {
  public static readonly type = "[Case Filter] Set Page State";
  constructor(public pageState: Partial<PageState>) {}
}

export class UpdateState {
  public static readonly type = "[Case Filter] Set Initial State";
  constructor(public state: CaseFilterStateModel) {}
}

export class AssignUserToCase {
  public static readonly type = "[Case] Assign User";
  constructor(public caseId: string, public caseUser: Partial<CaseUser>) {}
}

export class UpdateStatus {
  public static readonly type = "[Case] Update Status";
  constructor(
    public caseId: string,
    public statusId: string,
    public updatedByUserId: string,
    public tagId?: string
  ) {}
}

export class PatchCase {
  public static readonly type = "[Case] Patch Case Data";
  constructor(public caseData: CaseData) {}
}

export class UpdateCaseState {
  public static readonly type = "[Case] Patch Case in State";
  constructor(public caseId: string, public caseData: Partial<CaseData>) {}
}

export class RemoveCaseState {
  public static readonly type = "[Case] Remove Case in State";
  constructor(public caseId: string) {}
}

export class UnassignUserFromCase {
  public static readonly type = "[Case] Unassign User";
  constructor(
    public caseId: string,
    public userId: string,
    public unassignedByUserId: string | "all"
  ) {}
}

export class AcceptOrRejectCase {
  public static readonly type = "[Case] Accept Case";
  constructor(public caseId: string, public caseUser: CaseUser, public accept: boolean) {}
}
