import { DeviceNetworkInterfaces } from "@vp/models";
import { EventBase } from "@vp/shared/event-aggregator";

export interface DeviceNetworkInterfacesNotificationEventArgs {
  deviceUserId: string;
  deviceNetworkInterfaces: DeviceNetworkInterfaces;
}

export class DeviceNetworkInterfacesUpdatedEvent extends EventBase<DeviceNetworkInterfacesNotificationEventArgs> {
  constructor(args: DeviceNetworkInterfacesNotificationEventArgs) {
    super();
    this.args = args;
  }
}
