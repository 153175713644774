<div class="actions" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="title">Templates</div>
  <button
    type="button"
    mat-raised-button
    color="primary"
    (click)="addOrEditSnippet('Add')"
    fxFlexAlign="center"
    color="primary"
  >
    <mat-icon>add_circle</mat-icon> Add New
  </button>
</div>
<table mat-table multiTemplateDataRows [dataSource]="snippets">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Title</th>
    <td mat-cell *matCellDef="let row">{{ row.title }}</td>
  </ng-container>

  <ng-container matColumnDef="text">
    <th mat-header-cell *matHeaderCellDef>Text</th>
    <td mat-cell *matCellDef="let row" class="truncate-cell">
      <div class="line-clamp" [innerHtml]="row.text | safe: 'html'"></div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="3">
      <div
        class="element-detail"
        [@detailExpand]="row === expandedRow ? 'expanded' : 'collapsed'"
        fxLayout="column"
        fxLayoutAlign="center"
      >
      <div [innerHtml]="row.text | safe: 'html'"></div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" stickyEnd="true">
    <th mat-header-cell *matHeaderCellDef class="action">Actions</th>
    <td mat-cell *matCellDef="let row; let i = dataIndex" class="action">
      <button
        type="button"
        mat-icon-button
        matTooltip="Edit Snippet"
        aria-hidden="false"
        aria-label="Edit Snippet"
        (click)="addOrEditSnippet('Edit', row, i)"
      >
        <mat-icon class="action-icon mat-icon-action"> edit </mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        matTooltip="Delete snippet"
        aria-hidden="false"
        aria-label="Delete forever"
        (click)="deleteSnippet(i)"
      >
        <mat-icon class="mat-icon-action warn">delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['title', 'text', 'actions']"></tr>
  <tr
    mat-row
    class="element-row"
    *matRowDef="let row; columns: ['title', 'text', 'actions']"
    [class.expanded-row]="expandedRow === row"
    (click)="expandedRow = expandedRow === row ? null : row"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
