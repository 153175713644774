export interface UserFilter {
  take?: number;
  skip?: number;
  search?: string | null | undefined;
  tags?: string[] | null | undefined;
  sort?: string | null | undefined;
  sortDirection?: string | null | undefined;
  filters?: string[] | null | undefined;
  userId?: string | null | undefined;
  roleId?: string | null | undefined;
  userType?: string | null | undefined;
  deptId?: string | null | undefined;
  connected?: boolean | null | undefined;
}

export const defaultUserFilter = (): Partial<UserFilter> => {
  return {
    take: 25,
    skip: 0,
    tags: [],
    sort: "email",
    sortDirection: "asc"
  };
};
