import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import * as CaseFilterStateActions from "@vp/data-access-case-filter";
import { CaseApiService } from "@vp/data-access/case";
import * as UserStateActions from "@vp/data-access/users";
import { UserState } from "@vp/data-access/users";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  CallLightDeactivatedEvent,
  CaseData,
  CaseDataChangedEvent,
  CaseStatusChangedEvent,
  DeviceCamerasUpdatedEvent,
  DeviceConnectionChangedEvent,
  DeviceMicrophonesUpdatedEvent,
  DeviceNetworkInterfacesUpdatedEvent,
  DevicePowerStatusUpdatedEvent,
  DeviceSpeakersUpdatedEvent,
  DeviceStethoscopeUpdatedEvent,
  MessageToPatientEvent,
  MovementInRoomDetectedEvent,
  OrganizationFeatures,
  User
} from "@vp/models";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { FeatureService } from "@vp/shared/features";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { Observable, Subject } from "rxjs";
import { filter, switchMap, takeUntil, withLatestFrom } from "rxjs/operators";

@Injectable()
export class SystemEventsService implements OnDestroy {
  @Select(UserState.getUserFn) user$!: Observable<(userId: string) => User>;

  private readonly _destroyed$ = new Subject();

  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly caseApiService: CaseApiService,
    private readonly store: Store,
    private readonly featureService: FeatureService,
    private readonly notificationService: NotificationService
  ) {}

  broadcastCaseStatusChangeEnabled$ = this.featureService.featureFlagEnabled(
    OrganizationFeatures.signalR,
    "broadcastCaseStatusChanged"
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();

    this.store.dispatch(
      new UserStateActions.PatchState({
        users: []
      })
    );
  }

  subscribeToEvents() {
    this.eventAggregator
      .on<CaseDataChangedEvent>(CaseDataChangedEvent)
      .pipe(
        switchMap(event => {
          return this.caseApiService.getCase(event.args.caseId).pipe(filterNullMap());
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe(caseData => {
        this.store.dispatch(new CaseFilterStateActions.UpdateCaseState(caseData.caseId, caseData));
      });

    this.eventAggregator
      .on<DeviceConnectionChangedEvent>(DeviceConnectionChangedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceConnectionChangedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              signalRConnection: {
                isOnline: event.args.isOnline
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DevicePowerStatusUpdatedEvent>(DevicePowerStatusUpdatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DevicePowerStatusUpdatedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              devicePowerStatus: {
                batteryPercentageRemaining: event.args.devicePowerStatus.batteryPercentageRemaining,
                chargingStatus: event.args.devicePowerStatus.chargingStatus
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceNetworkInterfacesUpdatedEvent>(DeviceNetworkInterfacesUpdatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceNetworkInterfacesUpdatedEvent) => {
        const networkInterfaces = event.args.deviceNetworkInterfaces;
        const ethernet = networkInterfaces.ethernetInterfaces?.filter(
          i => i.operationalStatus === "Up"
        );
        const wifi = networkInterfaces.wirelessInterfaces?.filter(
          i => i.operationalStatus === "Up"
        );

        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              deviceNetworkInterfaces: {
                ethernetInterfaces: ethernet,
                wirelessInterfaces: wifi
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceStethoscopeUpdatedEvent>(DeviceStethoscopeUpdatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceStethoscopeUpdatedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              deviceStethoscopes: event.args.deviceStethoscopes
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceCamerasUpdatedEvent>(DeviceCamerasUpdatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceCamerasUpdatedEvent) => {
        const cameras = event.args.deviceCameras.filter(c => c.isSelectedDevice);
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              deviceCameras: cameras
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceMicrophonesUpdatedEvent>(DeviceMicrophonesUpdatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceMicrophonesUpdatedEvent) => {
        const deviceMicrophones = event.args.deviceMicrophones.filter(c => c.isSelectedDevice);
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              deviceMicrophones: deviceMicrophones
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceSpeakersUpdatedEvent>(DeviceSpeakersUpdatedEvent)

      .subscribe((event: DeviceSpeakersUpdatedEvent) => {
        const deviceSpeakers = event.args.deviceSpeakers.filter(c => c.isSelectedDevice);
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              deviceSpeakers: deviceSpeakers
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<CallLightActivatedEvent>(CallLightActivatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: CallLightActivatedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              callLightData: {
                callLightStatus: true,
                lastUpdatedBy: event.args.activatedBy,
                lastUpdatedDateTime: event.args.activationDateTime
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<CallLightDeactivatedEvent>(CallLightDeactivatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: CallLightDeactivatedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              callLightData: {
                callLightStatus: false,
                lastUpdatedBy: event.args.deactivatedBy,
                lastUpdatedDateTime: event.args.deactivationDateTime
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<DeviceConnectionChangedEvent>(DeviceConnectionChangedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: DeviceConnectionChangedEvent) => {
        this.store.dispatch(
          new UserStateActions.UpdateUser(event.args.deviceUserId, {
            patientConsoleDeviceData: {
              signalRConnection: {
                isOnline: event.args.isOnline,
                lastConnectedDateTime: event.args.lastConnectedDateTime,
                lastDisconnectedDateTime: event.args.lastDisconnectedDateTime
              }
            }
          } as Partial<User>)
        );
      });

    this.eventAggregator
      .on<MessageToPatientEvent>(MessageToPatientEvent)
      .pipe()
      .subscribe((event: MessageToPatientEvent) => {
        this.store.dispatch(
          new CaseFilterStateActions.UpdateCaseState(event.args.caseId, {
            caseId: event.args.caseId,
            virtualCareCaseData: {
              patientMessage: {
                message: event.args.message,
                messageSentBy: event.args.messageSentBy,
                messageDateTime: event.args.messageDateTime
              }
            }
          } as Partial<CaseData>)
        );
      });

    this.eventAggregator
      .on<CaseStatusChangedEvent>(CaseStatusChangedEvent)
      .pipe(
        withLatestFrom(
          this.broadcastCaseStatusChangeEnabled$.pipe(
            filter((enabled: boolean) => enabled === true)
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe({
        next: ([event]) => {
          this.store.dispatch(new CaseFilterStateActions.RefreshCase(event.args.caseId));
        }
      });

    this.eventAggregator
      .on<AssignUserEvent>(AssignUserEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((message: AssignUserEvent) => {
        this.notificationService.successMessage(message.args, "New User Assignment", {
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false,
          enableHtml: true
        });
      });

    this.eventAggregator
      .on<CallLightActivatedEvent>(CallLightActivatedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((event: CallLightActivatedEvent) => {
        this.notificationService.successMessage(
          `The call light is activated for device: ${event.args.deviceName}`,
          "Call Light Activated",
          {
            disableTimeOut: true,
            closeButton: true,
            tapToDismiss: false,
            enableHtml: true
          }
        );
      });

    //If moving forward with Angel Eyes, we should move the logic to determine the toast's CSS somewhere else and include icons
    this.eventAggregator
      .on<MovementInRoomDetectedEvent>(MovementInRoomDetectedEvent)
      .pipe(takeUntil(this._destroyed$))
      .subscribe((message: MovementInRoomDetectedEvent) => {
        let toastColor = "ngx-toastr toast-success default";
        if (message.args.status.notificationColor === 0) {
          toastColor = "ngx-toastr toast-success green";
        } else if (message.args.status.notificationColor === 1) {
          toastColor = "ngx-toastr toast-success yellow";
        } else if (message.args.status.notificationColor === 2) {
          toastColor = "ngx-toastr toast-success red";
        }
        this.notificationService.successMessage(message.args.message, "Movement Detected", {
          disableTimeOut: true,
          closeButton: true,
          tapToDismiss: false,
          enableHtml: true,
          toastClass: toastColor
        });
      });
  }
}
