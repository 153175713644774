import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  isDevMode,
  OnDestroy,
  OnInit
} from "@angular/core";
import { Router } from "@angular/router";
import { User } from "@vp/models";
import { IS_IVY_API } from "@vp/shared/guards";
import { Logger } from "@vp/shared/logging-service";
import { AppStoreService } from "@vp/shared/store/app";
import { Subject } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  isProd = !isDevMode();
  isIframe = false;
  isLoggedIn = false;
  isAdmin = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly appStoreService: AppStoreService,
    private readonly logger: Logger,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.logger.logEvent("Breakout app initalized");
    this.isIframe = window !== window.parent && !window.opener;

    if (this.isIvyApi) {
      this.router.navigate(["/wizard"]);
    }

    this.appStoreService.loggedInUser$
      .pipe(
        tap((user: User | null) => {
          this.isLoggedIn = !!user;
          this.isAdmin = false;
          // TODO put into app store service
          if (user && user.roles) {
            for (const role of user.roles) {
              if (role.roleId === user.selectedRoleId && role.friendlyId === "admin") {
                this.isAdmin = true;
              }
            }
          }
        })
      )
      .subscribe({
        next: () => this.logger.logTrace("AppComponent.ngOnInit.appStoreService.stateChanged"),
        error: error =>
          this.logger.logException(error, "AppComponent.ngOnInit.appStoreService.stateChanged")
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
