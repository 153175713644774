import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { append, compose, patch, removeItem } from "@ngxs/store/operators";
import { OrganizationFeatures, RealTimeNotification } from "@vp/models";
import { FeatureService } from "@vp/shared/features";
import { filterNullMap } from "@vp/shared/operators";
import { EMPTY } from "rxjs";
import { concatMap, filter, mergeMap, take } from "rxjs/operators";
import { SignalRApiService } from "../signal-r-api.service";
import * as SignalRStateActions from "./signal-r-state.actions";

export interface SignalRStateModel {
  hubConnection: Partial<SignalRConnectionState>;
  notifications: RealTimeNotification[];
}

export interface SignalREvent {
  method: string;
  data: any;
  eventTime: Date;
}

export interface SignalRConnectionState {
  state: string | null;
  lastUpdated: Date | null;
  connectionId: string | null;
  receivedEvents: SignalREvent[];
}

@State<SignalRStateModel>({
  name: "signalR",
  defaults: {
    hubConnection: {
      state: null,
      lastUpdated: null,
      connectionId: null,
      receivedEvents: []
    },
    notifications: []
  }
})
@Injectable()
export class SignalRState implements NgxsAfterBootstrap {
  constructor(
    private readonly featureService: FeatureService,
    private readonly signalRApiService: SignalRApiService
  ) {}

  @Selector()
  public static getState(state: SignalRStateModel) {
    return state;
  }

  @Selector()
  public static hubConnection(state: SignalRStateModel) {
    return state.hubConnection;
  }

  @Selector()
  public static notifications(state: SignalRStateModel) {
    return state.notifications;
  }

  ngxsAfterBootstrap(): void {
    this.featureService
      .feature$(OrganizationFeatures.signalR)
      .pipe(
        filterNullMap(),
        filter(f => f.enabled),
        take(1),
        mergeMap(() => {
          return this.signalRApiService.initalize();
        }),
        concatMap(() => {
          return this.signalRApiService.start();
        })
      )
      .subscribe();
  }

  @Action(SignalRStateActions.SetState)
  setState(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.SetState) {
    ctx.patchState(action.state);
  }

  @Action(SignalRStateActions.AddUserToGroup)
  addUserToGroup(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.AddUserToGroup) {
    const notifications = ctx.getState().notifications;
    if (!notifications.find(n => n.userId == action.userId && n.groupName == action.groupName)) {
      ctx.setState(
        patch({
          notifications: append([
            {
              userId: action.userId,
              groupName: action.groupName
            } as RealTimeNotification
          ])
        })
      );
    }
  }

  @Action(SignalRStateActions.RemoveFromGroup)
  removeFromGroup(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.RemoveFromGroup
  ) {
    ctx.setState(
      patch({
        notifications: removeItem<RealTimeNotification>(
          n => n?.userId == action.userId && n?.groupName == action.groupName
        )
      })
    );
  }

  @Action(SignalRStateActions.AddManyToGroup)
  addManyToGroup(ctx: StateContext<SignalRStateModel>, action: SignalRStateActions.AddManyToGroup) {
    return ctx.setState(
      patch({
        notifications: append(
          action.notifications.reduce((acc, notification) => {
            if (
              !acc.find(
                n => n.userId == notification.userId && n.groupName == notification.groupName
              )
            ) {
              acc.push(notification);
            }
            return acc;
          }, ctx.getState().notifications ?? [])
        )
      })
    );
  }

  @Action(SignalRStateActions.RemoveManyFromGroup)
  removeManyFromGroup(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.RemoveManyFromGroup
  ) {
    return ctx.setState(
      patch({
        notifications: removeItem<RealTimeNotification>(notification =>
          notification
            ? action.notifications.findIndex(
                n => n.groupName === notification.groupName && n.userId === notification.userId
              ) > -1
            : false
        )
      })
    );
  }

  @Action(SignalRStateActions.ReconcileGroups)
  reconcileGroups(
    ctx: StateContext<SignalRStateModel>,
    action: SignalRStateActions.ReconcileGroups
  ) {
    const notifications = ctx.getState().notifications;
    const toadd = action.toAdd.reduce(
      (acc: RealTimeNotification[], notification: RealTimeNotification) => {
        const existing = notifications.find(
          n => n.userId == notification.userId && n.groupName == notification.groupName
        );
        if (!existing) {
          acc.push(notification);
        }
        return acc;
      },
      []
    );

    const toRemove = action.toRemove.filter(n => {
      toadd.findIndex(a => a.userId == n.userId && a.groupName == n.groupName) > -1;
    });

    if (toadd.length > 1 || toRemove.length > 1) {
      return ctx.setState(
        patch({
          notifications: compose(
            removeItem<RealTimeNotification>(
              notification =>
                toRemove.findIndex(
                  n => n.groupName === notification?.groupName && n.userId === notification?.userId
                ) > -1
            ),
            append(toadd)
          )
        })
      );
    }
    return EMPTY;
  }
}
