<ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{
    quickSearchTag: quickSearchTag$ | async
  }">
</ng-container>

<ng-template #template let-quickSearchTag="quickSearchTag">
  <div class="search-container" fxLayout="row" *ngIf="authenticationService.isLoggedIn$() | async"
    fxLayoutAlign="space-between center">
    <input title="Search Input" id="searchInput" #searchinput matInput class="search-input" [placeholder]="
        quickSearchTag
          ? uiDisplayTagService.displayTags[quickSearchTag]
          : quickSearchTag || 'Search'
      " (keyup.enter)="enterPress()" [(ngModel)]="input" focused="focused" />
    <div matSuffix class="suffix">
      <button type="button" *ngIf="input.length > 0" mat-icon-button (click)="clear()">
        <mat-icon color="accent" aria-hidden="false" class="icon" inline="true">close</mat-icon>
      </button>
      <button id="submitButton" type="submit" mat-icon-button disableRipple matTooltip="Perform search or [ENTER]"
        (click)="searchClicked()">
        <mat-icon aria-hidden="false" class="icon" inline="true" [color]="focused ? 'accent' : 'primary'">{{ focused ?
          "keyboard_return" : "search" }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>