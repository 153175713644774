import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { FormlyModule } from "@ngx-formly/core";
import { UserAssignRolesModule } from "@vp/administration/ui/user-assign-roles";
import { UserAssignedGroupsModule } from "@vp/administration/ui/user-assigned-groups";
import { UserAssignedRolesModule } from "@vp/administration/ui/user-assigned-roles";
import { FormlyFlexLayoutTypeComponent } from "@vp/formly/json-schema";
import { PhoneInputModule } from "@vp/shared/components/phone-input";
import { FeaturesModule } from "@vp/shared/features";
import { UserSnippetsFeatureModule } from "@vp/user-snippets/feature";
import { UserSnippetsModule } from "@vp/user-snippets/ui/user-snippets";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsModule } from "ngx-permissions";
import { UserProfileShellComponent } from "./user-profile-shell.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    FormsModule,
    FormlyModule.forChild({
      types: [{ name: "flex-layout", component: FormlyFlexLayoutTypeComponent }]
    }),
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxMaskModule.forRoot({ validation: true }),

    NgxPermissionsModule.forChild(),

    FeaturesModule,

    PhoneInputModule,
    UserAssignedGroupsModule,
    UserAssignedRolesModule,
    UserAssignRolesModule,
    UserSnippetsFeatureModule,
    UserSnippetsModule
  ],
  exports: [UserProfileShellComponent],
  declarations: [UserProfileShellComponent]
})
export class UserProfileShellModule {}
