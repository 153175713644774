import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { EmailVerificationModule } from "@vp/user-profile/ui/email-verification";
import { LegalNoticeComponent, LegalNoticeModule } from "@vp/user-profile/ui/legal-notice";
import {
  UserProfileShellComponent,
  UserProfileShellModule
} from "@vp/user-profile/ui/user-profile-shell";

export const userProfileFeatureRoutes: Route[] = [];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: "", pathMatch: "full", component: UserProfileShellComponent },
      { path: "legalnotice", pathMatch: "full", component: LegalNoticeComponent }
    ]),
    NgxsModule.forFeature([UserAdministrationState, ApplicationState]),

    UserProfileShellModule,
    EmailVerificationModule,
    LegalNoticeModule
  ]
})
export class UserProfileFeatureModule {}
