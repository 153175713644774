import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { ContentData, Organization, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { camelize, deepCopy, mergeDeep } from "@vp/shared/utilities";
import { nanoid } from "nanoid";
import { createPatch } from "rfc6902";
import { combineLatest, EMPTY, Observable, of, Subject, throwError, zip } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { ContentApiService } from "../api/content-api-service";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import * as ContentFilterStateActions from "../state+/content-filter-state.actions";
import { ContentFilterState } from "../state+/content-filter.state";

export const CONTENT_MANAGEMENT_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export const DEFAULT_PAGER_LIST = [5, 10, 25, 100];
export const DEFAULT_PAGER_SKIP = 0;
export const DEFAULT_PAGER_TAKE = 10;
export const DEFAULT_MOBILE_PAGER_TAKE = 50;

@Injectable({
  providedIn: "root"
})
export class ContentManagementService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData | null>;
  @Select(ContentFilterState.contentData) contentData$!: Observable<ContentData | null>;

  @Select(ContentFilterState.pendingOperations)
  pendingOperations$!: Observable<ContentOperations | null>;
  private readonly _destroyed$ = new Subject();

  contentTypes$ = this.organization$.pipe(
    filterNullMap(),
    map(org => {
      return org.contentTypes ?? [];
    }),
    takeUntil(this._destroyed$)
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly contentApiService: ContentApiService,
    private readonly appStoreService: AppStoreService
  ) {}

  get pageParams() {
    return this.getQueryParams().pipe(
      map((paramMap: ParamMap) => {
        return this.getStateFromParams(paramMap);
      })
    );
  }

  Context: Observable<ContentData> = this.contentData$.pipe(
    filterNullMap(),
    map(data => deepCopy(data))
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setWorkingCopy(workingCopy: ContentData) {
    return this.workingCopy$?.pipe(
      filterNullMap(),
      switchMap((original: ContentData) => {
        return combineLatest([
          of(original),
          of(workingCopy).pipe(
            tap(workingCopy =>
              this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy))
            )
          )
        ]);
      })
    );
  }

  loadExistingContent(partialContentData: Partial<ContentData>): Observable<ContentData> {
    this.store.dispatch(new ContentFilterStateActions.LoadContentData(partialContentData, true));
    return this.pageParams.pipe(
      map((paramMap: PageParams) => {
        return {
          contentId: paramMap.contentId || null
        } as Partial<ContentDataFilter>;
      }),
      concatMap((params: Partial<ContentDataFilter>) => {
        if (params?.contentId) {
          return this.getContent(params.contentId);
        }
        return EMPTY;
      }),
      tap((workingCopy: ContentData) => {
        if (workingCopy) {
          this.store.dispatch(new ContentFilterStateActions.SetContentData(workingCopy));
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy));
        }
      })
    );
  }

  updateWorkingCopy = (formData: Record<string, unknown>) => {
    return this.appStoreService.user$.pipe(
      take(1),
      withLatestFrom(
        this.workingCopy$.pipe(filterNullMap()),
        this.contentData$.pipe(filterNullMap())
      ),
      switchMap(([user, workingCopy, original]: [User, ContentData, ContentData]) => {
        const updated = {
          ...workingCopy,
          createdBy: workingCopy.createdBy == "" ? user.userId : workingCopy.createdBy,
          lastUpdatedBy: user.userId,
          contentId: workingCopy.contentId == "" ? nanoid() : workingCopy.contentId,
          tags: workingCopy.tags
        };
        const merged: ContentData = mergeDeep(updated, formData, "merge", true);
        merged.friendlyId = camelize(merged.displayName);
        if (workingCopy.contentTypeId != merged.contentTypeId) {
          merged.tags = [];
        }
        return zip(
          of(original),
          of(merged),
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(merged))
        );
      }),
      map(([original, merged]) => {
        return this.getOperations(original, merged);
      }),
      tap(contentOperations => {
        this.store.dispatch(new ContentFilterStateActions.SetPendingOperations(contentOperations));
      })
    );
  };

  updateTags = (contentData: ContentData) => {
    return this.appStoreService.user$
      .pipe(
        take(1),
        withLatestFrom(this.contentData$.pipe(filterNullMap())),
        switchMap(([user, original]: [User, ContentData]) => {
          const updated = {
            ...contentData,
            createdBy: contentData.createdBy == "" ? user.userId : contentData.createdBy,
            lastUpdatedBy: user.userId,
            contentId: contentData.contentId == "" ? nanoid() : contentData.contentId,
            friendlyId: camelize(contentData.displayName),
            tags: contentData.tags
          };
          return zip(
            of(original),
            of(updated),
            this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(updated))
          );
        }),
        map(([original, updated]) => {
          return this.getOperations(original, updated);
        }),
        tap(contentOperations => {
          this.store.dispatch(
            new ContentFilterStateActions.SetPendingOperations(contentOperations)
          );
        })
      )
      .subscribe();
  };

  getQueryParams = (): Observable<ParamMap> => {
    return this.activatedRoute.queryParamMap;
  };

  getContent = (contentId: string) => {
    return this.contentApiService.getContent(contentId);
  };

  private getOperations(original: ContentData, updated: ContentData) {
    return {
      contentId: updated.contentId,
      operations: createPatch(original, updated)
    } as ContentOperations;
  }

  private getStateFromParams(paramMap: ParamMap): PageParams {
    const search = paramMap.get("search") || null;
    const take = Number(paramMap.get("take"));
    const skip = Number(paramMap.get("skip"));
    const contentId = paramMap.get("contentId");
    return {
      pageSizeOptions: DEFAULT_PAGER_LIST,
      search: search,
      take: take,
      skip: skip,
      pageIndex: skip / take,
      contentTypeId: paramMap.get("contentTypeId") || "all",
      contentId: contentId
    } as PageParams;
  }

  createOrEditContent() {
    return this.workingCopy$.pipe(
      filterNullMap(),
      take(1),
      switchMap((content: ContentData) => {
        if (!content) {
          return throwError("Content working copy was not set.");
        }
        if (!content.tags) {
          return throwError("Please assign one or more tags to Content");
        }
        return of(content);
      }),
      withLatestFrom(this.pageParams),
      switchMap(([content, params]: [ContentData, PageParams]) => {
        if (!params.contentId) {
          return this.store.dispatch(new ContentFilterStateActions.AddContent(content));
        }
        return this.store.dispatch(new ContentFilterStateActions.CommitOperations());
      })
    );
  }

  getContentsByCaseId(caseId: string): Observable<ContentData[]> {
    return this.contentApiService.getContentsByCaseId(caseId);
  }
}

export interface PageParams {
  pageSizeOptions: number[];
  search: string;
  take: number;
  skip: number;
  pageIndex: number;
  deptId: string;
  contentTypeId: string;
  contentId: string;
}
