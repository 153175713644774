import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { DialogComponent } from "./generic-dialog.component";
import { DialogData, DialogOptions } from "./generic-dialog.module";
import { GenericDialog } from "./generic-dialog.service";

@Injectable({
  providedIn: "root"
})
export class DialogFactoryService<T = undefined> {
  constructor(private dialog: MatDialog, private readonly breakpointObserver: BreakpointObserver) {}

  isSmall: Observable<BreakpointState> = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
    Breakpoints.TabletPortrait,
    Breakpoints.WebPortrait
  ]);

  open(
    dialogData: DialogData<T>,
    options: DialogOptions = { width: "40vw", disableClose: true }
  ): GenericDialog<T> {
    const dialogRef = this.dialog.open<DialogComponent<T>, DialogData<T>>(DialogComponent, {
      ...this.fetchOptions(options),
      maxWidth: "100vw",
      data: dialogData
    });

    const breakpointSubsription = this.isSmall.subscribe(size => {
      if (size.matches) {
        dialogRef.updateSize("100vw", "100vh");
      } else {
        dialogRef.updateSize(options.width, "");
      }
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        breakpointSubsription.unsubscribe();
      });

    return new GenericDialog(dialogRef);
  }

  private fetchOptions({
    width,
    disableClose
  }: DialogOptions): Pick<MatDialogConfig<DialogData<T>>, "width" | "disableClose"> {
    return {
      width: `${width}`,
      disableClose
    };
  }
}
