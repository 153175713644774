import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Select } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { CaseData, CaseType, CaseUser, Organization, User } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { CaseContextService } from "@vp/shared/case-context";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { NgxPermissionsService } from "ngx-permissions";
import { combineLatest, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

export const IS_IVY_API = new InjectionToken<boolean>("IS_IVY_API");

@Injectable({ providedIn: "root" })
export class AuthenticationGuard implements CanActivate {
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  constructor(
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly appStoreService: AppStoreService,
    private readonly authenticationService: AuthenticationService,
    private readonly caseContextService: CaseContextService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    const theRoute = this.router.url;
    return this.authenticationService.isLoggedIn$().pipe(
      switchMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return combineLatest([
            this.appStoreService.loggedInUser$,
            this.organization$.pipe(filterNullMap()),
            this.caseContextService.caseData$,
            this.caseTypes$.pipe(filterNullMap())
          ]).pipe(
            tap(([user, organization, caseData, caseTypes]) => {
              this.ngxPermissionsService.flushPermissions();
              const currentPermissions: string[] = [];
              if (user && organization) {
                // Permissions are initialized on access-control.service.ts file
                addGlobalPermissions(organization, user, currentPermissions);
                if (caseData) {
                  const found = caseTypes.find(
                    caseType => caseType.caseTypeId === caseData.caseType.caseTypeId
                  );
                  if (found) {
                    getCaseTypePermissions(found, user, caseData, currentPermissions);
                  }
                }
              }
              // Load permissions into NgxPermissionsService (duplicates removed)
              this.ngxPermissionsService.loadPermissions(currentPermissions);
            }),
            map(() => {
              return true;
            })
          );
        }
        return of(isAuthenticated);
      }),
      map((isAuthenticated: boolean) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!isAuthenticated) {
          return this.router.createUrlTree(["/home"]);
        }
        return true;
      })
    );
  }

  canLoad(): Observable<boolean | UrlTree> {
    const theRoute = this.router.url;
    return this.authenticationService.isLoggedIn$().pipe(
      map((isAuthenticated: boolean) => {
        if (this.isIvyApi) {
          if (theRoute === "/wizard" || theRoute === "/") {
            return true;
          } else {
            return this.router.createUrlTree(["/wizard"]);
          }
        } else if (!isAuthenticated) {
          return this.router.createUrlTree(["/home"]);
        }

        return true;
      })
    );
  }
}

/**
 * Get user's global (non-case type) permissions
 * @param organization The current organization object
 * @param user The current user object
 * @param currentPermissions Updated permissions by ref
 */
const addGlobalPermissions = (
  organization: Organization,
  user: User,
  currentPermissions: string[]
) => {
  const selectedOrganizationRole = organization.roles.find(
    role => role.roleId === user.selectedRoleId
  );
  if (selectedOrganizationRole) {
    currentPermissions.push(...selectedOrganizationRole.permissions);
  }
};

/**
 * Get user's non-case type permissions
 * @param caseType The current case type object
 * @param user The current user object
 * @param currentPermissions Updated permissions by ref
 */
const getCaseTypePermissions = (
  caseType: CaseType,
  user: User,
  caseData: CaseData,
  currentPermissions: string[]
) => {
  // From User Object get the selectedRoleFriendlyId and the UserId for the current loggued user
  const loggedInUserCurrentRole = user.roles.find(
    u => u.roleId === user.selectedRoleId
  )?.friendlyId;
  const userId = user.userId;
  // From Case Check if currentUser is added on CaseUsers
  const currentUserPermisionsOnCase = caseData.users.filter(
    x => x.userId == userId && x.roleId == user.selectedRoleId
  );

  // Get the roleResponsibilities assigned to the current user in the current case

  let roleResponsibilities: (string | undefined)[] = [];

  roleResponsibilities =
    currentUserPermisionsOnCase.length === 0
      ? [loggedInUserCurrentRole]
      : (roleResponsibilities = currentUserPermisionsOnCase.reduce(
          (roleResponsibilities: (string | undefined)[], caseUser: CaseUser) => {
            if (caseUser.requireAcceptance === true && caseUser.acceptanceStatus !== "accepted") {
              roleResponsibilities.push(`${loggedInUserCurrentRole}.pending-acceptance`);
            } else {
              if (
                caseUser.responsibilityFriendlyId === null ||
                caseUser.responsibilityFriendlyId === undefined
              ) {
                roleResponsibilities.push(loggedInUserCurrentRole);
              } else {
                roleResponsibilities.push(
                  `${loggedInUserCurrentRole}.${caseUser.responsibilityFriendlyId}`
                );
              }
            }
            // doing a distinct before returning it.
            return [...new Set(roleResponsibilities.map(item => item))];
          },
          []
        ));

  // from CaseType let's evaluate if any roleResponsibility matches with any of the current user role.responsability
  caseType.rolePermissions.forEach(rolePermissionGroup => {
    const hasRoleResponsibility = rolePermissionGroup.roles.find(role =>
      roleResponsibilities.find(x => x === role.friendlyId)
    );
    if (hasRoleResponsibility) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentPermissions.push(...new Set(rolePermissionGroup.permissions.map(item => item!)));
    }
  });
};
