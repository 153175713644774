import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { FormlyModule } from "@ngx-formly/core";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { CUSTOM_BREAKPOINTS_PROVIDER } from "@vp/models";
import { FilterToolbarModule } from "@vp/shared/components/filter-toolbar";
import { FocusStateModule } from "@vp/shared/directives/focus-state";
import { LocaleService } from "@vp/shared/locale-service";
import { MaterialModule } from "@vp/shared/material";
import { SpinnerModule } from "@vp/shared/spinner";
import { NgxLinkifyjsModule } from "ngx-linkifyjs";
import { NgxPermissionsModule } from "ngx-permissions";
import { CustomShowHideDirective } from "./directives/fx-breakpoints-show-hide.directive";

/**
 * @deprecated declare modules individually
 */
@NgModule({
  declarations: [CustomShowHideDirective],
  imports: [
    FilterToolbarModule,
    FlexLayoutModule,
    FocusStateModule,
    FormlyModule,
    FormsModule,
    MaterialModule,
    NgxLinkifyjsModule.forRoot(),
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SpinnerModule
  ],
  exports: [
    CommonModule,
    CustomShowHideDirective,
    FilterToolbarModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    NgxLinkifyjsModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SpinnerModule
  ],
  providers: [
    CUSTOM_BREAKPOINTS_PROVIDER,
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (localeService: LocaleService) => {
        return localeService.getLocale();
      },
      deps: [LocaleService]
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (localeService: LocaleService) => {
        return localeService.getDateFormat();
      },
      deps: [LocaleService]
    }
  ]
})
export class SharedModule {}
