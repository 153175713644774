import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";

import {
  CaseType,
  defaultDisplayTags,
  Department,
  Group,
  Organization,
  Role,
  User
} from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";

import * as ApplicationStateActions from "./application-state.actions";

export type ApplicationStateModel = {
  caseTypes: CaseType[];
  groups: Group[] | null;
  user: User | null;
  userDepartments: Department[];
  userRoles: Role[];
  organization: Organization | null;
  displayTags: Record<string, string>;
  schedules: any;
  redirectRoute: string | undefined | null;
  errors: string[];
};

@State<ApplicationStateModel>({
  name: "applicationState",
  defaults: {
    caseTypes: [],
    groups: [],
    user: null,
    userDepartments: [],
    userRoles: [],
    organization: null,
    displayTags: defaultDisplayTags,
    schedules: null,
    redirectRoute: null,
    errors: []
  }
})
@Injectable()
export class ApplicationState implements NgxsAfterBootstrap {
  @Selector()
  public static getLoggedInUser(state: ApplicationStateModel) {
    return state.user;
  }

  @Selector([ApplicationState.getLoggedInUser])
  public static loggedInUser(user: User | null): User | null {
    return user ? deeperCopy(user) : null;
  }

  @Selector()
  public static redirectRoute(state: ApplicationStateModel) {
    return state.redirectRoute;
  }

  @Selector()
  public static userRoles(state: ApplicationStateModel) {
    return state.userRoles;
  }

  @Selector()
  public static selectedRole(state: ApplicationStateModel) {
    return state.userRoles.find(role => role.roleId == state.user?.selectedRoleId) ?? null;
  }

  @Action(ApplicationStateActions.PatchState)
  patchState(ctx: StateContext<ApplicationStateModel>, action: ApplicationStateActions.PatchState) {
    ctx.patchState(action.partialState);
  }

  ngxsAfterBootstrap(_: StateContext<ApplicationStateModel> | undefined): void {
    console.log("Application State Loaded!");
  }
}
