import { Injectable, InjectionToken } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { patch, updateItem } from "@ngxs/store/operators";
import { Tag, TagFilter, TagType } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { deeperCopy } from "@vp/shared/utilities";
import { take, tap } from "rxjs/operators";
import { TagsApiService } from "../api/tags-api.service";
import * as TagsActions from "./tags-actions";

export const TAG_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export interface TagsStateModel {
  tags: Tag[];
  selectedId: string | null;
  defaultFilters: TagFilter[];
  selectedTags: Tag[];
  tagState: Tag | null;
}

@State<TagsStateModel>({
  name: "tags",
  defaults: {
    tags: [],
    selectedId: null,
    defaultFilters: [],
    selectedTags: [],
    tagState: null
  }
})
@Injectable()
export class TagsState implements NgxsAfterBootstrap {
  constructor(
    private readonly _tagsApiService: TagsApiService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  public static getTags(state: TagsStateModel) {
    return state.tags;
  }

  @Selector([TagsState.getTags])
  public static tags(tags: Tag[]) {
    return [...tags];
  }

  @Selector()
  public static selectedTagId(state: TagsStateModel) {
    return state.selectedId;
  }

  @Selector()
  public static defaultFilters(state: TagsStateModel) {
    return [...state.defaultFilters];
  }

  @Selector()
  public static selectedTags(state: TagsStateModel) {
    return [...state.selectedTags];
  }

  @Selector([TagsState.selectedTags])
  public static selectedTagsDisplay(selectedTags: Tag[] | null) {
    return (assignableTagType: TagType[]) => {
      return selectedTags?.reduce((groupByTagType: Record<string, string[]>, tag: Tag) => {
        const tagTypeDisplayName = assignableTagType.find(
          t => t.tagTypeId === tag.tagTypeId
        )?.displayName;
        if (tagTypeDisplayName) {
          groupByTagType[tagTypeDisplayName] = groupByTagType[tagTypeDisplayName] ?? [];
          groupByTagType[tagTypeDisplayName].push(tag.displayName);
        }
        return groupByTagType;
      }, {});
    };
  }

  @Selector()
  public static tagState(state: TagsStateModel) {
    return state.tagState;
  }

  ngxsAfterBootstrap(ctx: StateContext<TagsStateModel>) {
    this.authenticationService
      .isLoggedIn$()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          ctx.dispatch(new TagsActions.LoadTags(true));
        }
      });
  }

  @Action(TagsActions.CreateTag)
  create(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.CreateTag) {
    return this._tagsApiService.addTag(tag).pipe(
      tap((tag: Tag) => {
        ctx.patchState({ tags: [...ctx.getState().tags, tag] });
      })
    );
  }

  @Action(TagsActions.LoadTags)
  load(ctx: StateContext<TagsStateModel>, { useCache }: TagsActions.LoadTags) {
    return this._tagsApiService.getTags(useCache).pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ tags: tags });
      })
    );
  }

  @Action(TagsActions.SetSelectedTagId)
  setSelectedId(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.SetSelectedTagId) {
    ctx.patchState({ selectedId: tagId });
  }

  @Action(TagsActions.UpdateTag)
  update(ctx: StateContext<TagsStateModel>, { tag }: TagsActions.UpdateTag) {
    ctx.setState(
      patch<TagsStateModel>({
        tags: updateItem<Tag>(t => t?.tagId === tag.tagId, patch<Tag>(tag))
      })
    );
  }

  @Action(TagsActions.DeleteTag)
  delete(ctx: StateContext<TagsStateModel>, { tagId }: TagsActions.DeleteTag) {
    return this._tagsApiService.deleteTag(tagId).pipe(
      tap(() => {
        ctx.patchState({ tags: [...ctx.getState().tags.filter(t => t.tagId !== tagId)] });
      })
    );
  }

  @Action(TagsActions.SetDefaultFilters)
  setDefaultFilters(
    ctx: StateContext<TagsStateModel>,
    { tagFilters }: TagsActions.SetDefaultFilters
  ) {
    ctx.patchState({ defaultFilters: tagFilters });
  }

  @Action(TagsActions.SetSelectedTags)
  setSelectedTags(
    ctx: StateContext<TagsStateModel>,
    { selectedTags }: TagsActions.SetSelectedTags
  ) {
    ctx.patchState({ selectedTags: selectedTags });
  }

  @Action(TagsActions.SetTagState)
  setTagState(ctx: StateContext<TagsStateModel>, { tagState }: TagsActions.SetTagState) {
    const currentState = ctx.getState();
    const updatedState = {
      ...currentState,
      TagsState: tagState
    };
    return ctx.patchState(updatedState);
  }

  @Action(TagsActions.LoadTagState)
  loadContent(ctx: StateContext<TagsStateModel>, action: TagsActions.LoadTagState) {
    return this.updateState$(action.tagData, ctx);
  }

  private updateState$ = (partialTag: Partial<Tag>, ctx: StateContext<TagsStateModel>) => {
    const state = ctx.getState();
    const tagData: Tag = deeperCopy(partialTag);
    const updatedState: TagsStateModel = {
      ...state,
      tagState: tagData
    };
    return ctx.patchState(updatedState);
  };
}
