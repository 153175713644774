import { DeviceStethoscope } from "@vp/models";
import { EventBase } from "@vp/shared/event-aggregator";

export interface DeviceStethoscopesUpdatedEventArgs {
  deviceUserId: string;
  deviceStethoscopes: DeviceStethoscope[];
}

export class DeviceStethoscopeUpdatedEvent extends EventBase<DeviceStethoscopesUpdatedEventArgs> {
  constructor(args: DeviceStethoscopesUpdatedEventArgs) {
    super();
    this.args = args;
  }
}
