import { Injectable } from "@angular/core";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { countryList } from "@vp/models";
@Injectable()
export class CountryDropdownFormStateProviderService implements FormStateProvider {
  provide(state: Record<string, unknown>): Record<string, unknown> {
    if (Object.keys(state).length === 0 || !state.countryList) return {};
    const countryCodes = {
      countryList: countryList.map(({ name, code }) => ({ name, code }))
    };

    return countryCodes;
  }
}
