import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { Router } from "@angular/router";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { defaultJsonschemaOptions } from "@vp/formly/json-schema";
import {
  UiSchemaConfigService,
  UiSchemaLayoutProvider,
  UiSchemaStateProvider
} from "@vp/formly/ui-schema-config";
import { Organization, OrganizationFeatures, Role, User, countryList } from "@vp/models";
import { AccessControlService } from "@vp/shared/access-control";
import { FeatureService } from "@vp/shared/features";
import { LocaleService } from "@vp/shared/locale-service";
import { NotificationService } from "@vp/shared/notification";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { RouterUtilities, deeperCopy, mergeDeep } from "@vp/shared/utilities";
import {
  RolesAssignmentService,
  UserAdministrationService
} from "@vp/user-administration/data-access/user-administration-state";
import moment from "moment";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest, from, of, zip } from "rxjs";
import {
  concatMap,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";

export interface IWarningReason {
  title: string;
  reason: string;
}

@Component({
  selector: "vp-user-profile-shell",
  templateUrl: "./user-profile-shell.component.html",
  styleUrls: ["./user-profile-shell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RolesAssignmentService,
    UserAdministrationService,
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (localeService: LocaleService) => {
        return localeService.getLocale();
      },
      deps: [LocaleService]
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: (localeService: LocaleService) => {
        return localeService.getDateFormat();
      },
      deps: [LocaleService]
    }
  ]
})
export class UserProfileShellComponent implements OnInit, OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;

  countryList = countryList;
  dateFormat = "";
  emailVerificationForm = new FormGroup({});
  optIn!: boolean;

  //  TODO: messages needs to be consolidated into configurationlists
  optInVerbiage = this.featureService.messages$(OrganizationFeatures.smsOptIn).pipe(
    map(messages => {
      const defaultOptInMsg = "Opt-in SMS notification";
      if (messages) {
        return messages["optInMessage"] ?? defaultOptInMsg;
      }
      return defaultOptInMsg;
    })
  );

  private _destroyed$ = new Subject();
  private _optIn$ = new BehaviorSubject<boolean>(false);

  hasPending$ = new BehaviorSubject<boolean>(false);

  form!: FormGroup;
  fields: FormlyFieldConfig[] = [];
  model = {};
  options: FormlyFormOptions = {};

  constructor(
    public permConst: PermissionsConstService,
    private localeService: LocaleService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly appStoreService: AppStoreService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly accessControlService: AccessControlService,
    private readonly uiSchemaConfigService: UiSchemaConfigService,
    private readonly uiSchemaLayoutProvider: UiSchemaLayoutProvider,
    private readonly uiSchemaStateProvider: UiSchemaStateProvider,
    private readonly router: Router,
    private readonly userAdministrationService: UserAdministrationService,
    private readonly routerUtilitiesService: RouterUtilities,
    private readonly notificationService: NotificationService,
    private readonly featureService: FeatureService
  ) {}

  user$ = this.appStoreService.user$.pipe(take(1));
  optIn$ = this._optIn$.asObservable();

  featureSchema$ = this.featureService.featureSchema$("profile", "editProfileComponent");
  featureLayout$ = this.featureService
    .featureLayouts$("profile")
    .pipe(filterNullMap())
    .subscribe(layout => this.uiSchemaConfigService.addScopedConfig(layout, `edit-user-profile`));

  schema$ = this.featureSchema$.pipe(
    switchMap((schema: Record<string, unknown>) => {
      return this.uiSchemaLayoutProvider.applyScopes(
        "editProfileComponent",
        schema,
        `edit-user-profile`
      );
    })
  );

  imported$ = this.user$.pipe(
    map(user => user.tags),
    map(tags => tags.includes("import")),
    takeUntil(this._destroyed$)
  );

  verified$ = this.user$.pipe(
    map(user => user.tags),
    withLatestFrom(this.imported$),
    map(([tags, imported]) => {
      if (imported) {
        return tags.includes("email.verified");
      }
      return true;
    }),
    takeUntil(this._destroyed$)
  );

  firstLogin$ = this.user$.pipe(
    shareReplay(1),
    map(user => user.tags),
    map(tags => {
      return !tags.includes("profile.complete");
    }),
    takeUntil(this._destroyed$)
  );

  showRolesAndGroups$ = this.ngxPermissionsService.permissions$.pipe(
    filterNullMap(),
    mergeMap(() => {
      return from(
        this.ngxPermissionsService.hasPermission([this.permConst.Profile.DeptmentAndGroup.Read])
      );
    })
  );

  reason$ = combineLatest([this.firstLogin$, this.imported$]).pipe(
    map(([firstLogin, _imported]) => {
      if (firstLogin) {
        return {
          type: "accent",
          title: "Thank you for registering!",
          reason:
            "Some details are missing from your profile. Please add the missing information to continue."
        } as IWarningReason;
      }
      return null;
    }),
    takeUntil(this._destroyed$)
  );

  ngOnInit(): void {
    this.dateFormat = this.localeService.getLocaleDateFormat();

    // TODO: This is temporary until we can consolidate the user state it is just
    // required for the roles and groups components right now
    this.user$
      .pipe(
        take(1),
        mergeMap(user => {
          return this.userAdministrationService.loadUser(user);
        })
      )
      .subscribe();

    this.user$
      .pipe(
        concatMap(user =>
          zip(
            of(user),
            this.firstLogin$,
            this.featureService.featureEnabled$(OrganizationFeatures.smsOptIn),
            this.featureService
              .configurationLists$(OrganizationFeatures.smsOptIn)
              .pipe(map(configs => (configs ? configs["defaultOptInDepartments"] : null)))
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe(([user, firstLogin, smsFeatureEnabled, defaultOptInDepartments]) => {
        this._optIn$.next(user.tags.includes("sms-opt-in"));

        if (smsFeatureEnabled && defaultOptInDepartments) {
          if (firstLogin) {
            // on first login, default sms opt in to true if user belongs to one of the default opt in departments
            this._optIn$.next(this.checkForAutoOptIn(user, defaultOptInDepartments));
          }
        }
      });

    this.schema$
      .pipe(
        filterNullMap(),
        concatMap(schema => {
          return combineLatest([
            this.uiSchemaLayoutProvider
              .applyScopes("editProfileComponent", schema, "edit-user-profile")
              .pipe(
                map(schema => {
                  return this.uiSchemaLayoutProvider.getFieldConfig(
                    schema,
                    defaultJsonschemaOptions
                  );
                })
              ),
            this.uiSchemaStateProvider.applyScopes(
              "editProfileComponent",
              this.options,
              "edit-user-profile"
            )
          ]);
        }),
        take(1)
      )
      .subscribe(([fieldConfigs, formlyFormOptions]: [FormlyFieldConfig[], FormlyFormOptions]) => {
        this.fields = fieldConfigs;
        this.options = formlyFormOptions;
      });

    this.user$
      .pipe(
        tap((user: User) => {
          this.buildForm(user);
          this.changeDetectorRef.detectChanges();
          this.form.patchValue(user, { emitEvent: true });
        }),
        take(1),
        mergeMap(() => this.form.valueChanges),
        concatMap((formData: Record<string, unknown>) => {
          return this.userAdministrationService.updateWorkingCopy(formData);
        })
      )
      .subscribe();

    this.user$
      .pipe(
        map(user => deeperCopy(user)),
        takeUntil(this._destroyed$)
      )
      .subscribe((user: User) => {
        if (user.profile.dateOfBirth) {
          /* I hate this but I cant think of another way around this, the moment
           * date adapter is turning this into a moment object which throws an
           * error so this just converts it back to a js date.
           */
          user.profile.dateOfBirth = moment(user.profile.dateOfBirth).toDate();
        }
        this.form.patchValue(user, { emitEvent: false });
        if (this.form.invalid) {
          this.form.markAllAsTouched();
        }
      });

    this.form.valueChanges
      .pipe(
        withLatestFrom(this.hasPending$),
        tap(([, hasPending]: [unknown, boolean]) => {
          if (!hasPending) {
            this.hasPending$.next(true);
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  private buildForm(user: User) {
    const profile = new FormGroup({});
    this.form = this.formBuilder.group({
      email: [{ value: user.email, disabled: true }],
      profile
    });
  }

  onSave = () => {
    of(this.form)
      .pipe(
        withLatestFrom(this.user$, this.optIn$),
        switchMap(([form, user, smsOptIn]: [FormGroup, User, boolean]) => {
          if (form.valid) {
            const modified = mergeDeep(user, form.value);
            if (!modified.tags.includes("profile.complete")) {
              modified.tags.push("profile.complete");
            }
            if (smsOptIn) {
              if (!modified.tags.includes("sms-opt-in")) {
                modified.tags.push("sms-opt-in");
              }
            } else {
              modified.tags = modified.tags.filter((t: string) => t !== "sms-opt-in");
            }
            return this.appStoreService.patchUser(modified);
          }
          return EMPTY;
        }),
        mergeMap(() => this.gotoCreateCaseIfNeeded()),
        first()
      )
      .subscribe({
        next: (success: boolean) => {
          if (success) {
            this.notificationService.successMessage("Profile saved");
          } else {
            this.notificationService.errorMessage(
              "There was a problem saving your profile information."
            );
          }
        }
      });
  };

  gotoCreateCaseIfNeeded = () => {
    return this.firstLogin$.pipe(
      withLatestFrom(
        this.organization$.pipe(map(org => org.autoCreateInitialCaseRoles ?? [])),
        this.appStoreService.selectedRole
      ),
      switchMap(([firstLogin, autoCreateRoles, selectedRole]: [boolean, string[], Role]) => {
        if (firstLogin) {
          return this.accessControlService.userSelectedRoleIncludes(autoCreateRoles).pipe(
            switchMap(hasRoles => {
              if (hasRoles) {
                return this.router.navigate(["wizard"]);
              }
              const { path, queryParams } =
                this.routerUtilitiesService.getRouteDefaultFromRole(selectedRole);
              return this.router.navigate([path], { queryParams });
            })
          );
        }
        return of(true);
      }),
      first()
    );
  };

  toggleOptIn(event: boolean) {
    this._optIn$.next(event);
  }

  checkForAutoOptIn(user: User, defaultOptInDepartments: string[]) {
    return user.roles.some(r => {
      for (const department of r.departments) {
        if (department.friendlyId) {
          return defaultOptInDepartments?.includes(department.friendlyId);
        }
      }
      return false;
    });
  }
}
