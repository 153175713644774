import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { RequestMetaDataState } from "@vp/data-access/request-meta-data";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class FeatureInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestMetaData: Record<string, string> = this.store.selectSnapshot(
      RequestMetaDataState.metaData
    );

    let clone = request.clone();
    for (const key in requestMetaData) {
      clone = request.clone({
        headers: request.headers.set("x-" + key, requestMetaData[key])
      });
    }

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clone);
  }
}
