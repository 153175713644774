import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Select } from "@ngxs/store";
import { UserAssignGroupsComponent } from "@vp/administration/ui/user-assign-groups";
import { GroupsState } from "@vp/data-access/groups";
import { Group, GroupRef, User } from "@vp/models";
import { ASSIGNMENT_MODAL_OPTIONS } from "@vp/shared/assignments/models";
import {
  DialogData,
  DialogFactoryService,
  GenericDialog
} from "@vp/shared/components/generic-dialog";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { GroupTypePipe } from "@vp/shared/pipes/context-display";
import { UserAdministrationService } from "@vp/user-administration/data-access/user-administration-state";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-user-assigned-groups",
  templateUrl: "./user-assigned-groups.component.html",
  styleUrls: ["./user-assigned-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ASSIGNMENT_MODAL_OPTIONS,
      useValue: {
        columns: [
          {
            field: "displayName",
            header: "Name"
          },
          {
            field: "groupTypeDisplayName",
            header: "Group Type"
          },
          {
            field: "description",
            header: "Description"
          }
        ],
        title: "Assign Groups",
        config: {
          disableClose: true,
          autoFocus: false,
          closeOnNavigation: true,
          width: "60vw"
        }
      }
    }
  ]
})
export class UserAssignedGroupsComponent implements OnDestroy {
  @Select(GroupsState.allGroups) allGroups$!: Observable<Group[]>;

  assignedGroups$ = this.userAdministrationService.workingCopy$.pipe(
    filterNullMap(),
    map((user: User) => user.groups),
    withLatestFrom(this.allGroups$),
    map(([assigned, all]: [GroupRef[], Group[]]) =>
      all.filter(e => assigned.findIndex(r => r.groupId === e.groupId) > -1)
    )
  );

  @ViewChild("modalTemplate", { static: false })
  modalTemplate!: TemplateRef<UserAssignGroupsComponent>;

  dialog!: GenericDialog;
  columns = [
    {
      field: "groupTypeId",
      header: "Group Type",
      pipe: GroupTypePipe
    },
    {
      field: "displayName",
      header: "Name"
    },
    {
      field: "actions",
      header: "Delete"
    }
  ];

  private readonly destroyed$ = new Subject();
  private readonly sortSubject$ = new BehaviorSubject<Sort>({
    direction: "asc",
    active: "groupDisplayName"
  });

  constructor(
    private readonly dialogFactoryService: DialogFactoryService,
    private readonly userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {}

  assignHandler = () => {
    this.openDialog({
      title: "Assign Groups",
      template: this.modalTemplate
    });
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  unassignHandler = (e: Group) => {
    this.userAdministrationService.deleteGroups$([e.groupId]).subscribe();
  };

  sortHandler = (sort: Sort): void => {
    this.sortSubject$.next(sort);
  };

  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(dialogData, {
      width: "70vw",
      disableClose: false
    });
    this.dialog.closed$.subscribe();
  }
}
