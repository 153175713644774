import { Injectable } from "@angular/core";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { append, patch, updateItem } from "@ngxs/store/operators";
import { ContentData, ContentSearch, PageResult, PageState } from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { deeperCopy } from "@vp/shared/utilities";
import { EMPTY, Observable } from "rxjs";
import { concatMap, map, take, tap } from "rxjs/operators";
import { ContentApiService } from "../api/content-api-service";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import * as ContentFilterStateActions from "./content-filter-state.actions";

export type ContentFilterStateModel = {
  allContents: ContentData[];
  contentData: ContentData | null;
  workingCopy: ContentData | null;
  filter: Partial<ContentDataFilter>;
  pendingOperations: ContentOperations | null;
  pageState: PageState;
  results: ContentSearch[];
  errors: string[];
};

export const defaultContentState = (): ContentFilterStateModel => {
  return {
    filter: {
      take: 10,
      skip: 0,
      contentTypeId: null,
      contentId: null
    },
    pageState: {
      totalRecords: 0,
      pageIndex: 0,
      pageCount: 0,
      pageSize: 10,
      lastPage: 1
    },
    allContents: [],
    results: [],
    errors: [],
    workingCopy: null,
    contentData: null,
    pendingOperations: null
  };
};

@State<ContentFilterStateModel>({
  name: "contentFilter",
  defaults: defaultContentState()
})
@Injectable()
export class ContentFilterState implements NgxsAfterBootstrap {
  constructor(
    private contentApiService: ContentApiService,
    private authenticationService: AuthenticationService
  ) {}

  @Selector()
  static getAllContents(state: ContentFilterStateModel): ContentData[] {
    return state.allContents;
  }

  @Selector([ContentFilterState.getAllContents])
  static allContents(allContents: ContentData[]): ContentData[] {
    return allContents;
  }

  @Selector()
  static workingCopy(state: ContentFilterStateModel): ContentData | null {
    return state.workingCopy;
  }

  @Selector()
  static contentData(state: ContentFilterStateModel): ContentData | null {
    return state.contentData;
  }

  @Selector()
  static pendingOperations(state: ContentFilterStateModel): ContentOperations | null {
    return state.pendingOperations;
  }

  @Selector()
  public static results(state: ContentFilterStateModel): ContentSearch[] {
    return state.results;
  }

  @Selector()
  static currentFilter(state: ContentFilterStateModel): Partial<ContentDataFilter> {
    return state.filter;
  }

  @Selector()
  static pageState(state: ContentFilterStateModel): PageState {
    return state.pageState;
  }

  ngxsAfterBootstrap(ctx: StateContext<ContentFilterStateModel>): void {
    this.authenticationService
      .isLoggedIn$()
      .pipe(take(1))
      .subscribe(isAuthenticated => {
        if (isAuthenticated) {
          ctx.dispatch(new ContentFilterStateActions.LoadContents());
        }
      });
  }

  @Action(ContentFilterStateActions.LoadContents)
  loadContents(ctx: StateContext<ContentFilterStateModel>) {
    return this.contentApiService.getAllContents().pipe(
      tap((contents: ContentData[]) => {
        ctx.setState(
          patch<ContentFilterStateModel>({
            allContents: contents
          })
        );
      })
    );
  }

  @Action(ContentFilterStateActions.SetFilter)
  setFilter(
    ctx: StateContext<ContentFilterStateModel>,
    { filter: contentDataFilter }: ContentFilterStateActions.SetFilter
  ) {
    ctx.setState(
      patch<ContentFilterStateModel>({
        filter: contentDataFilter
      })
    );
    return this.contentApiService.filteredContents(contentDataFilter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.setState(
          patch<ContentFilterStateModel>({
            results: pageResults.results,
            pageState: patch<PageState>({
              pageSize: pageResults.pageSize,
              totalRecords: pageResults.totalRecords,
              pageCount: pageResults.pageCount,
              lastPage: pageResults.lastPage
            })
          })
        );
      })
    );
  }

  @Action(ContentFilterStateActions.UpdateState)
  updateState(
    ctx: StateContext<ContentFilterStateModel>,
    { state: state }: ContentFilterStateActions.UpdateState
  ) {
    return this.contentApiService.filteredContents(state.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.setState({
          ...state,
          results: pageResults.results,
          pageState: {
            pageIndex: state.pageState.pageIndex,
            pageSize: pageResults.pageSize,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.LoadContentData)
  loadContent(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.LoadContentData
  ) {
    return this.updateState$(action.contentData, ctx);
  }

  private updateState$ = (
    partialContentData: Partial<ContentData>,
    ctx: StateContext<ContentFilterStateModel>
  ) => {
    const contentData: ContentData = deeperCopy(partialContentData);
    ctx.setState(
      patch<ContentFilterStateModel>({
        workingCopy: contentData,
        contentData: contentData
      })
    );
  };

  @Action(ContentFilterStateActions.SetWorkingCopy)
  setWorkingCopy(
    ctx: StateContext<ContentFilterStateModel>,
    workingCopy: ContentFilterStateActions.SetWorkingCopy
  ) {
    const state = ctx.getState();
    const updatedState: ContentFilterStateModel = {
      ...state,
      workingCopy: workingCopy.contentData
    };
    return ctx.patchState(updatedState);
  }

  @Action(ContentFilterStateActions.SetContentData)
  setContentData(
    ctx: StateContext<ContentFilterStateModel>,
    contentData: ContentFilterStateActions.SetContentData
  ) {
    const state = ctx.getState();
    const updatedState: ContentFilterStateModel = {
      ...state,
      contentData: contentData.contentData
    };
    return ctx.patchState(updatedState);
  }

  @Action(ContentFilterStateActions.SetPageState)
  setPageState(
    ctx: StateContext<ContentFilterStateModel>,
    { pageState: pageState }: ContentFilterStateActions.SetPageState
  ) {
    const currentState = ctx.getState();
    const pageSize = pageState?.pageSize ?? 0;
    const pageIndex = pageState?.pageIndex ?? 0;
    const updatedState = {
      ...currentState,
      pageState: pageState,
      filter: {
        ...currentState.filter,
        take: pageSize,
        skip: pageSize * pageIndex
      }
    };
    return this.contentApiService.filteredContents(updatedState.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.patchState({
          ...updatedState,
          results: pageResults.results,
          pageState: {
            pageIndex: pageIndex,
            pageSize: pageSize,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.GetFiltered)
  getFiltered(ctx: StateContext<ContentFilterStateModel>): Observable<PageResult<ContentSearch>> {
    const state: ContentFilterStateModel = ctx.getState();
    return this.contentApiService.filteredContents(state.filter).pipe(
      tap((pageResults: PageResult<ContentSearch>) => {
        ctx.patchState({
          results: pageResults.results,
          pageState: {
            pageIndex: state.pageState.pageIndex,
            totalRecords: pageResults.totalRecords,
            pageCount: pageResults.pageCount,
            lastPage: pageResults.lastPage,
            pageSize: pageResults.pageSize
          }
        });
      })
    );
  }

  @Action(ContentFilterStateActions.AddContent)
  addContent(
    ctx: StateContext<ContentFilterStateModel>,
    { content }: ContentFilterStateActions.AddContent
  ) {
    return this.contentApiService.createContent(content).pipe(
      tap(contentData =>
        ctx.setState(
          patch<ContentFilterStateModel>({
            allContents: append([contentData])
          })
        )
      )
    );
  }

  @Action(ContentFilterStateActions.CommitOperations)
  commitOperations(ctx: StateContext<ContentFilterStateModel>) {
    const currentContentData = ctx.getState().contentData;
    if (!currentContentData) return;

    const pendingOperations = ctx.getState().pendingOperations;
    if (pendingOperations?.operations.length) {
      return this.contentApiService
        .patch(pendingOperations?.contentId, pendingOperations?.operations)
        .pipe(
          map(() => pendingOperations.contentId),
          concatMap(contentId => this.contentApiService.getContent(contentId)),
          tap((contentData: ContentData) => {
            ctx.setState(
              patch<ContentFilterStateModel>({
                contentData: contentData,
                allContents: updateItem<ContentData>(
                  c => c?.contentId === contentData.contentId,
                  contentData
                )
              })
            );
          })
        );
    }
    return EMPTY;
  }

  @Action(ContentFilterStateActions.SetPendingOperations)
  setPendingOperations(
    ctx: StateContext<ContentFilterStateModel>,
    action: ContentFilterStateActions.SetPendingOperations
  ) {
    ctx.setState(
      patch<ContentFilterStateModel>({
        pendingOperations: action.contentOperations
      })
    );
  }
}
