/* eslint-disable no-restricted-syntax */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import {
  AssignUserEvent,
  CallLightActivatedEvent,
  CallLightDeactivatedEvent,
  CaseChatEvent,
  CaseDataChangedEvent,
  CaseStatusChangedEvent,
  CaseUpdatedEvent,
  DeviceCamerasUpdatedEvent,
  DeviceConnectionChangedEvent,
  DeviceMicrophonesUpdatedEvent,
  DeviceNetworkInterfacesUpdatedEvent,
  DevicePowerStatusUpdatedEvent,
  DeviceSpeakersUpdatedEvent,
  DeviceStethoscopeUpdatedEvent,
  MessageToPatientEvent,
  MovementInRoomDetectedEvent,
  RealTimeNotification,
  SignalRConnected,
  SignalrMethods,
  User,
  ZoomWebhookEvent
} from "@vp/models";
import { AuthenticationService } from "@vp/shared/authentication";
import { EventAggregator, EventBase } from "@vp/shared/event-aggregator";
import { IS_IVY_API } from "@vp/shared/guards";
import { Logger } from "@vp/shared/logging-service";
import { filterNullMap } from "@vp/shared/operators";
import { API_BASE_URL } from "@vp/shared/tokens";
import { ExtendsClass } from "@vp/shared/utilities";
import { from, Observable, zip } from "rxjs";
import { filter, mergeMap, take, tap } from "rxjs/operators";
import * as SignalRStateActions from "./state/signal-r-state.actions";
import { SignalRState } from "./state/signal-r.state";

export interface SignalRConnectionInfo {
  url: string;
  accessToken: string;
}

export interface EventHandlerMap {
  method: string;
  event: any;
}

@Injectable({
  providedIn: "root"
})
export class SignalRApiService {
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  private hubConnection!: HubConnection;

  // TODO: This stuff should be provided as an injection token via module configuiration
  // i.e. forChild, forRoot, etc
  private clientMethodsToSubscribe: EventHandlerMap[] = [
    {
      method: SignalrMethods.newCaseChat,
      event: CaseChatEvent
    },
    {
      method: SignalrMethods.newAssignUser,
      event: AssignUserEvent
    },
    {
      method: SignalrMethods.updatedCase,
      event: CaseUpdatedEvent
    },
    {
      method: SignalrMethods.callLightActivated,
      event: CallLightActivatedEvent
    },
    {
      method: SignalrMethods.callLightDeactivated,
      event: CallLightDeactivatedEvent
    },
    {
      method: SignalrMethods.movementInRoomDetected,
      event: MovementInRoomDetectedEvent
    },
    {
      method: SignalrMethods.deviceConnectionChanged,
      event: DeviceConnectionChangedEvent
    },
    {
      method: SignalrMethods.deviceCamerasUpdated,
      event: DeviceCamerasUpdatedEvent
    },
    {
      method: SignalrMethods.deviceMicrophonesUpdated,
      event: DeviceMicrophonesUpdatedEvent
    },
    {
      method: SignalrMethods.deviceSpeakersUpdated,
      event: DeviceSpeakersUpdatedEvent
    },
    {
      method: SignalrMethods.deviceNetworkInterfacesUpdated,
      event: DeviceNetworkInterfacesUpdatedEvent
    },
    {
      method: SignalrMethods.deviceStethoscopesUpdated,
      event: DeviceStethoscopeUpdatedEvent
    },
    {
      method: SignalrMethods.devicePowerStatusUpdated,
      event: DevicePowerStatusUpdatedEvent
    },
    {
      method: SignalrMethods.caseDataChanged,
      event: CaseDataChangedEvent
    },
    {
      method: SignalrMethods.interactiveSessionStarted,
      event: ZoomWebhookEvent
    },
    {
      method: SignalrMethods.interactiveSessionEnded,
      event: ZoomWebhookEvent
    },
    {
      method: SignalrMethods.messageToPatient,
      event: MessageToPatientEvent
    },
    {
      method: SignalrMethods.caseStatusChanged,
      event: CaseStatusChangedEvent
    }
  ];

  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    @Inject(IS_IVY_API) private readonly isIvyApi: boolean,
    private readonly authenticationService: AuthenticationService,
    private readonly eventAggregrator: EventAggregator,
    private readonly store: Store,
    private readonly _http: HttpClient,
    private readonly logger: Logger
  ) {}

  initalize = () => {
    return zip(
      this.authenticationService
        .isLoggedIn$()
        .pipe(filter(isAuthenticated => isAuthenticated === true)),
      this.loggedInUser$.pipe(filterNullMap())
    ).pipe(
      take(1),
      mergeMap(([, user]) => this.getSignalRConnection(user.userId)),
      tap(signalrConnection => {
        //signalRConnection contains the URL to the Azure SignalR instance
        //and the accessToken granting the user access
        const options = {
          accessTokenFactory: () => signalrConnection.accessToken
        };

        this.hubConnection = new HubConnectionBuilder()
          .withUrl(signalrConnection.url, options)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: retryContext => {
              const expired = tokenExpired(signalrConnection.accessToken);
              // stop automatic reconnect if token already expired
              if (!expired) {
                if (retryContext.previousRetryCount < 50) {
                  return 1000;
                } else if (retryContext.previousRetryCount < 250) {
                  return 10000;
                }
                return null;
              }
              return null;
            }
          })
          .configureLogging(LogLevel.Warning)
          .build();

        this.registerConnectionEvents();
        this.registerClientMethods();
      })
    );
  };

  start(): Observable<void> {
    return new Observable(observer => {
      this.hubConnection
        .start()
        .then(() => {
          this.logger.logEvent("Connected to SignalR");
          this.eventAggregrator.emit(new SignalRConnected(true), "signalRConnected");

          this.store.dispatch(
            new SignalRStateActions.SetState({
              hubConnection: {
                state: this.hubConnection.state,
                lastUpdated: new Date(),
                connectionId: this.hubConnection.connectionId,
                receivedEvents: []
              }
            })
          );

          observer.next();
          observer.complete();
        })
        .catch(err => {
          this.logger.logEvent(`Error while connecting to SignalR: ${err}`);
          observer.error(err);

          setTimeout(() => {
            this.start().subscribe();
          }, 5000);
        });
    });
  }

  public addToGroup = (userId: string | undefined, groupName: string) => {
    return this.addManyToGroup([
      {
        userId: userId,
        groupName: groupName
      } as RealTimeNotification
    ]);
  };

  public addManyToGroup = (notifications: RealTimeNotification[]) => {
    const apiUrl = `${this.apiBaseUrl}/realtime/addUserToGroup`;
    console.time("addManyToGroup");
    return this._http.post<boolean>(apiUrl, notifications);
  };

  public removeFromGroup = (userId: string | undefined, groupName: string) => {
    return this.removeManyFromGroup([
      {
        userId: userId,
        groupName: groupName
      } as RealTimeNotification
    ]);
  };

  public removeManyFromGroup = (notifications: RealTimeNotification[]) => {
    const apiUrl = `${this.apiBaseUrl}/realtime/removeUserFromGroup`;
    console.time("removeManyFromGroup");
    return this._http.post<boolean>(apiUrl, notifications);
  };

  private logSignalrEvent(method: string, data: any) {
    const parsedData = JSON.stringify(data, null, 4);
    const hubConnection = this.store.selectSnapshot(SignalRState.getState).hubConnection;

    //rolling list of last 20 events.
    const receivedEvents = hubConnection.receivedEvents?.slice(
      //Get the last 19
      Math.max(hubConnection.receivedEvents.length - 19, 0)
    );

    //add the new one to the beginning of the list
    receivedEvents?.unshift({
      method: method,
      data: parsedData,
      eventTime: new Date()
    });

    this.store.dispatch(
      new SignalRStateActions.SetState({
        hubConnection: {
          receivedEvents: receivedEvents
        }
      })
    );
  }

  private updateConnectionState = () => {
    this.store.dispatch(
      new SignalRStateActions.SetState({
        hubConnection: {
          state: this.hubConnection.state,
          connectionId: this.hubConnection.connectionId,
          lastUpdated: new Date()
        }
      })
    );
  };

  private registerConnectionEvents = (): void => {
    this.hubConnection.onclose(connection => {
      //the signalR connection has been closed. We need to start a new connection in order to reconnect.
      this.logger.logEvent(
        `SignalR Connection Closed: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();
      //ensure the previous connection has been closed before starting a new one.
      if (this.hubConnection) {
        this.hubConnection.stop();
      }
      this.initalize();
    });

    this.hubConnection.onreconnecting(connection => {
      this.logger.logEvent(
        `SignalR Reconnecting: ${connection?.message}. Stack: ${connection?.stack}`
      );
      this.updateConnectionState();
    });

    this.hubConnection.onreconnected(connection => {
      this.logger.logEvent(`SignalR Reconnected: ${connection}.`);
      this.updateConnectionState();
    });
  };

  private registerClientMethods = (): void => {
    if (this.isIvyApi) return;

    from(
      this.clientMethodsToSubscribe.map((handler: EventHandlerMap) => {
        return new Observable<{
          handler: EventHandlerMap;
          data: string;
        }>(subscriber => {
          this.hubConnection.on(handler.method, data => {
            subscriber.next({
              handler: handler,
              data: data
            });
          });

          return () => {
            this.hubConnection.off(handler.event);
          };
        });
      })
    )
      .pipe(
        mergeMap(observable => observable),
        filterNullMap()
      )
      .subscribe({
        next: args => {
          this.logSignalrEvent(args.handler.method, args.data);
          const instance = Reflect.construct(args.handler.event, [args.data]);
          if (ExtendsClass(instance, EventBase)) {
            this.eventAggregrator.emit(instance, args.handler.method);
          }
        }
      });

    //   this.hubConnection.on(
    //     SignalrMethods.caseStatusChanged,
    //     (caseStatusChanged: CaseStatusChangedEventArgs) => {
    //       this.logSignalrEvent(SignalrMethods.caseStatusChanged, caseStatusChanged);
    //       this.store.dispatch(new CaseActions.SetState(caseStatusChanged.caseId));
    //       this.eventAggregrator.emit(
    //         new CaseStatusChangedEvent(caseStatusChanged),
    //         SignalrMethods.caseStatusChanged
    //       );
    //     }
    //   );
    // }
  };

  private getSignalRConnection = (userId: string): Observable<SignalRConnectionInfo> => {
    const apiUrl = `${this.apiBaseUrl}/realtime/negotiate`;
    return this._http.get<SignalRConnectionInfo>(apiUrl, {
      //we must pass this header so signalR can assign a UserId per connection
      //this is needed in order to add users to groups, as that UserId identifies which connection to place in a group
      headers: new HttpHeaders().set("x-ms-signalr-userid", userId)
    });
  };
}

const tokenExpired = (token: string) => {
  const expiry = JSON.parse(atob(token.split(".")[1])).exp;
  return Math.floor(new Date().getTime() / 1000) >= expiry;
};
