import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { UserApiService } from "@vp/data-access/users";
import {
  AssignedRolePerDepartment,
  Group,
  GroupRef,
  GroupType,
  Organization,
  RoleAccessTagsItem,
  Snippet,
  Tag,
  TagsArray,
  User,
  UserRole
} from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { JSONSchema7 } from "json-schema";
import _ from "lodash";
import { createPatch } from "rfc6902";
import { combineLatest, Observable, of, Subject, throwError, zip } from "rxjs";
import { concatMap, first, map, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { UserOperations } from "../models/user-operations.model";
import * as UserAdministrationActions from "../state+/user-administration.actions";
import { UserAdministrationState } from "../state+/user-administration.state";

@Injectable()
export class UserAdministrationService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(OrganizationState.groupTypes) groupTypes$!: Observable<GroupType[]>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @Select(UserAdministrationState.user) user$!: Observable<User | null>;
  @Select(UserAdministrationState.workingCopy) workingCopy$!: Observable<User | null>;
  @Select(UserAdministrationState.assignableTags) userAssignableTags$!: Observable<Tag[]>;
  @Select(UserAdministrationState.layoutSchema) layoutSchema$!: Observable<JSONSchema7 | null>;
  @Select(UserAdministrationState.pendingOperations)
  pendingOperations$!: Observable<UserOperations | null>;

  private readonly _destroyed$ = new Subject();

  constructor(private readonly store: Store, private readonly userApiService: UserApiService) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  resetUser() {
    this.store.dispatch(new UserAdministrationActions.ResetState());
  }

  setUser(id: string) {
    return this.store.dispatch(new UserAdministrationActions.SetUser(id));
  }

  setWorkingCopy(workingCopy: User) {
    return this.user$.pipe(
      take(1),
      filterNullMap(),
      switchMap((original: User) => {
        return combineLatest([
          of(original),
          of(workingCopy).pipe(
            tap(workingCopy =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(workingCopy))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      }),
      take(1)
    );
  }

  loadUser(user: Partial<User>, active: boolean = true) {
    return this.store.dispatch(new UserAdministrationActions.LoadUser(user, active));
  }

  updateWorkingCopy = (formData: Record<string, unknown>) => {
    return this.user$.pipe(
      filterNullMap(),
      take(1),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      switchMap(([original, workingCopy]: [User, User]) => {
        const merged = mergeDeep({ ...workingCopy }, formData, "replace", true);
        return combineLatest([
          of(original),
          of(merged).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  };

  addSingleDepartmentRole$(role: AssignedRolePerDepartment) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified: User = { ...workingCopy, roles: [] };
        this.addDepartmentRole(modified, role.roleId, role.departmentId);
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  addDepartmentRoles$(roles: AssignedRolePerDepartment[]) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = this.addDepartmentRoles(workingCopy, roles);
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  private addDepartmentRoles = (user: User, roles: AssignedRolePerDepartment[]): User => {
    const copy: User = deeperCopy(user);
    roles.forEach(d => {
      this.addDepartmentRole(copy, d.roleId, d.departmentId);
    });
    return copy;
  };

  private addDepartmentRole = (userRef: User, roleId: string, departmentId: string) => {
    this.organization$
      .pipe(
        map(org => {
          return {
            roles: org.roles,
            departments: org.departments
          };
        }),
        first()
      )
      .subscribe(refs => {
        const role = userRef.roles.find(r => r.roleId === roleId);
        const deptFriendlyId = refs.departments.find(
          d => d.departmentId === departmentId
        )?.friendlyId;
        if (role) {
          role.departments.push({
            departmentId: departmentId,
            friendlyId: deptFriendlyId
          });
        } else {
          const roleFriendlyId = refs.roles.find(r => r.roleId === roleId)?.friendlyId;
          if (!roleFriendlyId) return;
          userRef.roles.push({
            roleId: roleId,
            friendlyId: roleFriendlyId,
            departments: [
              {
                departmentId: departmentId,
                friendlyId: deptFriendlyId
              }
            ]
          });
        }
      });
  };

  deleteDepartmentRole$(roleId: string, departmentId: string) {
    return this.user$.pipe(
      filterNullMap(),
      take(1),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = deleteDepartmentByRole(workingCopy, roleId, departmentId);
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  addGroups$(groups: Group[]) {
    return this.user$.pipe(
      filterNullMap(),
      take(1),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = addGroups(workingCopy, groups);
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  deleteGroups$(groupIds: string[]) {
    if (groupIds.length < 1) return of({} as UserOperations);
    return this.user$.pipe(
      filterNullMap(),
      take(1),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      switchMap(([original, workingCopy]: [User, User]) => {
        let modified: User = workingCopy;
        groupIds.forEach(groupId => {
          modified = deleteGroup(modified, groupId);
        });
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  addAccessTags$(roleId: string, tagIds: string[], compoundAssignmentEnabled: boolean) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = this.addAccessTags(workingCopy, roleId, tagIds, compoundAssignmentEnabled);

        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  deleteAccessTags(item: RoleAccessTagsItem) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = deleteUserRoleAccessTags(workingCopy, item);
        return combineLatest([
          of(original),
          of(modified).pipe(
            tap(modified =>
              this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
            )
          )
        ]);
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  addOrEditSnippet$(action: "Add" | "Edit", snippet: Snippet, index?: number) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = addOrEditSnippet(workingCopy, action, snippet, index);
        return zip(
          of(original),
          of(modified),
          this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
        );
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  deleteSnippet$(index: number) {
    return this.user$.pipe(
      filterNullMap(),
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      take(1),
      switchMap(([original, workingCopy]: [User, User]) => {
        const modified = deleteSnippet(workingCopy, index);
        return zip(
          of(original),
          of(modified),
          this.store.dispatch(new UserAdministrationActions.SetWorkingCopy(modified))
        );
      }),
      map(([original, changed]) => {
        return this.getOperations(original, changed);
      }),
      tap(userOperations => {
        this.store.dispatch(new UserAdministrationActions.SetPendingOperations(userOperations));
      })
    );
  }

  invite(resendInvite?: boolean) {
    return this.workingCopy$.pipe(
      filterNullMap(),
      take(1),
      switchMap(user => {
        if (!user) {
          return throwError("User working copy was not set.");
        }
        if (!user.roles || user.roles.length === 0) {
          return throwError("Please assign one or more department & role(s) to User");
        }
        return of(user);
      }),
      switchMap(user => this.userApiService.inviteUser(user, resendInvite))
    );
  }

  create(sendInvite?: boolean) {
    return this.workingCopy$.pipe(
      filterNullMap(),
      take(1),
      switchMap(user => {
        if (!user) {
          return throwError("User working copy was not set.");
        }
        if (!user.roles || user.roles.length === 0) {
          return throwError("Please assign one or more department & role(s) to User");
        }
        return of(user);
      }),
      switchMap(user => this.userApiService.createUser(user, sendInvite))
    );
  }

  /** this is not being used currently for some reason */
  patch(): Observable<User | null> {
    return this.pendingOperations$.pipe(
      filterNullMap(),
      take(1),
      switchMap((userOperations: UserOperations) =>
        this.userApiService
          .patch(userOperations.userId, userOperations.operations)
          .pipe(
            concatMap(() =>
              this.store.dispatch(new UserAdministrationActions.SetUser(userOperations.userId))
            )
          )
      )
    );
  }

  updateUser() {
    return this.workingCopy$.pipe(
      filterNullMap(),
      take(1),
      concatMap((workingCopy: User) => this.userApiService.updateUser(workingCopy)),
      concatMap((user: User) => this.store.dispatch(new UserAdministrationActions.LoadUser(user)))
    );
  }

  updateDevice() {
    return this.workingCopy$.pipe(
      filterNullMap(),
      concatMap((workingCopy: User) => this.userApiService.updateDeviceUser(workingCopy)),
      concatMap((user: User) => this.store.dispatch(new UserAdministrationActions.LoadUser(user))),
      take(1)
    );
  }

  private getOperations(original: User, updated: User) {
    return {
      userId: updated.userId,
      operations: createPatch(original, updated)
    } as UserOperations;
  }

  private addAccessTags = (
    user: User,
    roleId: string,
    tagIds: string[],
    compoundAssignmentEnabled: boolean
  ): User => {
    const copy = deeperCopy(user);
    const userRole: UserRole = copy.roles.find((r: UserRole) => r.roleId === roleId);
    const tagsArray: TagsArray = { tags: tagIds };
    if (!userRole) throw Error("User has no roles.");
    if (!Array.isArray(userRole.accessTags)) {
      userRole["accessTags"] = [];
    }
    if (compoundAssignmentEnabled) {
      userRole.accessTags.push(tagsArray);
    } else {
      tagIds.forEach(tagId => {
        userRole.accessTags?.push({ tags: [tagId] });
      });
    }

    return copy;
  };
}

const deleteDepartmentByRole = (user: User, roleId: string, departmentId: string) => {
  const copy: User = deeperCopy(user);
  const role = copy.roles.find(r => r.roleId === roleId);
  if (role) {
    role.departments = role?.departments.filter(d => d.departmentId !== departmentId);
    if (role.departments.length === 0) {
      copy.roles = copy.roles.filter(r => r.roleId !== roleId);
    }
  }
  return copy;
};

const deleteUserRoleAccessTags = (user: User, item: RoleAccessTagsItem) => {
  const copy: User = deeperCopy(user);
  const role: UserRole | undefined = copy.roles.find(r => r.roleId === item.roleId);
  if (role) {
    const accessTags: TagsArray | undefined = role.accessTags?.find(accessTags =>
      _.isEqual(accessTags.tags, item.tagIds)
    );
    if (accessTags) {
      role.accessTags = role.accessTags?.filter(
        accessTags => _.isEqual(accessTags.tags, item.tagIds) === false
      );
    }
  }
  return copy;
};

const addGroups = (user: User, groups: Group[]) => {
  const copy: User = deeperCopy(user);
  copy.groups = copy.groups.concat(
    groups.map(group => {
      return {
        groupId: group.groupId,
        groupTypeId: group.groupTypeId
      } as GroupRef;
    })
  );
  return copy;
};

const deleteGroup = (user: User, groupId: string) => {
  const copy: User = deeperCopy(user);
  copy.groups = copy.groups.filter(g => g.groupId !== groupId);
  return copy;
};

const addOrEditSnippet = (user: User, action: "Add" | "Edit", snippet: Snippet, index?: number) => {
  const copy: User = deeperCopy(user);
  switch (action) {
    case "Add":
      copy.userData = {
        ...copy.userData,
        snippets: copy.userData?.snippets ? copy.userData?.snippets?.concat(snippet) : [snippet]
      };
      break;

    case "Edit":
      if (index !== undefined && copy.userData.snippets) {
        copy.userData.snippets[index] = snippet;
      }
      break;
  }
  return copy;
};

const deleteSnippet = (user: User, index: number) => {
  const copy: User = deeperCopy(user);
  if (copy.userData.snippets) {
    copy.userData.snippets.splice(index, 1);
  }
  return copy;
};
