import { DevicePowerStatus } from "@vp/models";
import { EventBase } from "@vp/shared/event-aggregator";

export interface DevicePowerStatusNotification {
  deviceUserId: string;
  devicePowerStatus: DevicePowerStatus;
}

export class DevicePowerStatusUpdatedEvent extends EventBase<DevicePowerStatusNotification> {
  constructor(notification: DevicePowerStatusNotification) {
    super();
    this.args = notification;
  }
}
