import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap, QueryParamsHandling, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { CaseData, User } from "@vp/models";
import { cleanUnused, objectsEqual, stringToNumber, TwoWayMap } from "@vp/shared/utilities";
import { Observable, Subject } from "rxjs";
import { concatMap, map, pairwise, takeUntil } from "rxjs/operators";
import { UserFilter } from "../models/user-filter";
import * as UserStateActions from "../state+/user-state.actions";
import { UserState } from "../state+/user.state";

// TODO: Not sure what this is, its weird.
export const vmToDbColumnMap = new TwoWayMap({
  userName: "profile.firstName",
  userEmail: "email"
});

@Injectable()
export class UserStateNavigationService implements OnDestroy {
  @Select(UserState.users) users$!: Observable<CaseData[]>;
  @Select(UserState.currentFilter) currentFilter$!: Observable<UserFilter>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  private _destroyed$ = new Subject();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  listen(staticPresets: Partial<UserFilter>) {
    const staticFilters = staticPresets.filters ?? [];
    this.activatedRoute.queryParamMap
      .pipe(
        map(queryParamMap => this.getParamsForApi(queryParamMap)),
        concatMap(({ take, skip, sortByField, sortDirection, search, filters, tags }) =>
          this.store.dispatch(
            new UserStateActions.SetFilter({
              take: take,
              skip: skip,
              sort: sortByField,
              sortDirection: sortDirection,
              search: search,
              filters: [...staticFilters, ...filters],
              tags: tags
            })
          )
        ),
        takeUntil(this._destroyed$)
      )
      .subscribe({
        error: err => {
          this.store.dispatch(
            new UserStateActions.PatchState({
              errors: [err]
            })
          );
        }
      });

    this.currentFilter$.pipe(takeUntil(this._destroyed$)).subscribe((filter: UserFilter) => {
      const cleanParams = cleanUnused(filter);
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: cleanParams,
        skipLocationChange: false,
        replaceUrl: true
      });
    });

    this.currentFilter$.pipe(pairwise(), takeUntil(this._destroyed$)).subscribe({
      next: ([previous, current]: [UserFilter, UserFilter]) => {
        if (!objectsEqual(previous, current)) {
          this.store.dispatch(new UserStateActions.GetFiltered());
        }
      }
    });
  }

  navigate(
    searchParams: Partial<UserFilter>,
    queryParamsHandling: QueryParamsHandling | null = "merge",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }

  private getParamsForApi(paramMap: ParamMap) {
    const take = stringToNumber(paramMap.get("take")) ?? 25;
    const skip = stringToNumber(paramMap.get("skip")) ?? 0;
    const sortByField = vmToDbColumnMap.get(paramMap.get("sort") ?? "") ?? null;
    const sortDirection = paramMap.get("sort_direction") ?? "asc";
    const search = paramMap.get("search") ?? "";
    const filters = [];
    if (paramMap.get("role") !== null && paramMap.get("role") !== "all") {
      filters.push(`roles.roleId=${paramMap.get("role")}`);
    }
    if (paramMap.get("active") !== null && paramMap.get("active") !== "all") {
      filters.push(`active=${paramMap.get("active") == "Active" ?? false}`);
    }
    if (paramMap.get("group") !== null && paramMap.get("group") !== "all") {
      filters.push(`groups.groupId=${paramMap.get("group")}`);
    }
    const tags: string[] = paramMap.getAll("tags").filter((name: string) => name !== "all");
    return { take, skip, sortByField, sortDirection, search, filters, tags };
  }
}
