import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { AddOrEditSnippetDialogComponent } from "./add-or-edit-snippet-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    AngularEditorModule
  ],
  exports: [AddOrEditSnippetDialogComponent],
  declarations: [AddOrEditSnippetDialogComponent]
})
export class AddOrEditSnippetDialogModule {}
