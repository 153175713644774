import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { ApplicationState } from "@vp/data-access/application";
import * as CaseTypesActions from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import * as TagsActions from "@vp/data-access/tags";
import { UserApiService } from "@vp/data-access/users";
import { Department, Organization, Role, User, UserRole } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { createPatch, Operation } from "rfc6902";
import { combineLatest, Observable, of } from "rxjs";
import { map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
export const APP_STORE_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export const TAG_PROFILE_COMPLETE = "profile.complete";

@Injectable({
  providedIn: "root"
})
export class AppStoreService {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;
  @Select(ApplicationState.redirectRoute) redirectRoute$!: Observable<string | undefined | null>;
  @Select(ApplicationState.userRoles) userRoles$!: Observable<Role[]>;

  constructor(
    @Inject(APP_STORE_API_BASE_URL) private _apiBaseUrl: string,
    private http: HttpClient,
    private userApiService: UserApiService,
    private readonly store: Store
  ) {}

  user$ = this.loggedInUser$.pipe(filterNullMap());

  get user() {
    return this.store.selectSnapshot(ApplicationState.loggedInUser);
  }

  get isUserProfileComplete() {
    const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
    return user?.tags?.includes(TAG_PROFILE_COMPLETE);
  }

  // TODO: this doesn't make any sense.
  selectedRole = this.user$.pipe(
    map((user: User) => user.roles?.find(r => r.roleId === user.selectedRoleId)),
    filterNullMap(),
    mergeMap((userRole: UserRole) =>
      this.userRoles$.pipe(map(userRoles => userRoles?.find(r => r.roleId === userRole?.roleId)))
    ),
    filterNullMap()
  );

  selectedRoleDisplayName$ = this.selectedRole.pipe(map((userRole: Role) => userRole.displayName));

  userFullName$ = this.user$.pipe(
    map(user => {
      if (user?.profile?.salutation)
        return `${user?.profile?.salutation} ${user?.profile?.firstName} ${user?.profile?.lastName}`;
      return `${user?.profile?.firstName} ${user?.profile?.lastName}`;
    })
  );

  userDepartments$ = this.user$.pipe(
    map(user => user.roles.find(role => role.roleId === user.selectedRoleId)),
    map(userRole => userRole?.departments ?? []),
    withLatestFrom(this.organization$),
    map(([userDept, org]) => {
      const userDepartmentIds = userDept.map(d => d.departmentId);
      return org.departments.filter(d => userDepartmentIds.includes(d.departmentId));
    })
  );

  /**
   * @deprecated Use `selectedRole` observable for all selected role operations for improved role switcher
   */
  getSelectedRole(): UserRole | undefined {
    const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
    return user?.roles?.find(r => r.roleId === user?.selectedRoleId);
  }

  //TODO: This should not be returning the user
  loadUser(userId: string) {
    return this.userApiService.getUser(userId).pipe(
      filterNullMap(),
      tap((user: User) => {
        this.store.dispatch(
          new ApplicationStateActions.PatchState({
            user: user
          })
        );
      })
    );
  }

  loadLoginUser() {
    return this.userApiService.getUserLogin().pipe(
      tap((user: User) => {
        this.store.dispatch(
          new ApplicationStateActions.PatchState({
            user: user
          })
        );
      })
    );
  }

  removeUser() {
    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        user: null
      })
    );
  }

  setUser(user: User) {
    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        user: user
      })
    );
  }

  setRedirectRoute(route: string | undefined | null) {
    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        redirectRoute: route
      })
    );
  }

  /**
   * This set up call depends on both the user and organization to be loaded already
   */
  setUserDepartments(user: User) {
    const selectedRole = user?.roles?.find(r => r.roleId === user.selectedRoleId);
    const organization = this.store.selectSnapshot(OrganizationState.organization);
    const departments: Department[] = [];

    selectedRole?.departments.forEach(rd => {
      const department = organization?.departments.find(od => od.departmentId === rd.departmentId);
      if (department) {
        departments.push(...[department]);
      }
    });

    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        userDepartments: departments
      })
    );
  }

  setOrganization(organization: Organization) {
    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        organization: organization
      })
    );
  }

  /**
   * This set up call depends on both the user and organization to be loaded already
   */
  setUserRoles() {
    const organization = this.store.selectSnapshot(OrganizationState.organization);
    const user = this.store.selectSnapshot(ApplicationState.loggedInUser);

    if (!user || !organization) {
      throw new Error("No user or organization found");
    }
    const roles: Role[] = [];
    findRolesForOrganization(user, organization, roles);

    this.store.dispatch(
      new ApplicationStateActions.PatchState({
        userRoles: roles
      })
    );
  }

  updateOrInsertTag(userId: string, tag: string): Observable<boolean> {
    const apiURL = `${this._apiBaseUrl}/user/${userId}/tags/${tag}`;

    return this.http
      .put(apiURL, null, {
        observe: "response",
        responseType: "text"
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }

  patchUser(updated: User): Observable<User> {
    return combineLatest([this.user$.pipe(filterNullMap()), of(updated)]).pipe(
      take(1),
      map(([original, changed]: [User, User]) => {
        return {
          userId: changed.userId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((userOperations: { userId: string; operations: Operation[] }) =>
        this.userApiService
          .patch(userOperations.userId, userOperations.operations)
          .pipe(switchMap(() => this.loadUser(userOperations.userId)))
      )
    );
  }

  updateUserRole(roleId: string) {
    const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
    if (!user) {
      throw new Error("No user or organization found");
    }

    const replaceOperation: Operation = { op: "replace", path: "/selectedRoleId", value: roleId };
    return this.userApiService.patch(user.userId, [replaceOperation]).pipe(
      switchMap(() => this.loadUser(user.userId)),
      withLatestFrom(this.organization$),
      take(1),
      tap(([user, organization]: [User, Organization]) => {
        const roles: Role[] = [];
        findRolesForOrganization(user, organization, roles);
        this.store.dispatch(
          new ApplicationStateActions.PatchState({
            userRoles: roles
          })
        );
        // reset user departments for the updated role
        this.setUserDepartments(user);
        //Make this an action handler that subscribes to the state action
        this.store.dispatch(new CaseTypesActions.LoadCaseTypes());
        this.store.dispatch(new TagsActions.LoadTags());
      })
    );
  }

  usersViewObject(page: string, viewType: "list" | "grid" | "table" = "list") {
    return this.selectedRole.pipe(
      map(role => {
        const selectedRoleId = role?.roleId;
        const orgRoleForUser =
          this.store
            .selectSnapshot(ApplicationState.userRoles)
            .find(role => role.roleId === selectedRoleId) ?? ({} as Role);

        const views = orgRoleForUser?.views as Record<string, unknown> | undefined;
        const viewTypes = views ? (views[page] as Record<string, unknown> | undefined) : undefined;
        const firstViewType = viewTypes
          ? (viewTypes[Object.keys(viewTypes)[0]] as string)
          : undefined;
        const viewName = viewTypes ? (viewTypes[viewType] as string) : undefined;
        return {
          viewName: viewName ? viewName : firstViewType,
          viewTypesAvailable: viewTypes ? Object.keys(viewTypes) : undefined
        };
      })
    );
  }
}

const findRolesForOrganization = (user: User, organization: Organization, roles: Role[]) => {
  user.roles?.forEach(r => {
    const role = organization?.roles.find(organizationRole => organizationRole.roleId === r.roleId);
    if (role) {
      roles.push(role);
    }
  });
};
