<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    tagType: (tagType$ | async),
    availableTags: availableTags$ | async
  }"
>
</ng-container>

<ng-template #template let-tagType="tagType" let-availableTags="availableTags">
  <mat-form-field fxFlex>
    <mat-label>{{ tagType?.displayName }}</mat-label>
    <mat-select
      [formControl]="formControl"
      [(value)]="selectedTag"
      (selectionChange)="selectionChanged($event.value)"
      [disabled]="isFixed()"
    >
      <mat-option [value]="null">{{ noneSelectedText }}</mat-option>
      <mat-option *ngFor="let tag of availableTags" [value]="tag.tagId">{{
        tag.displayName
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
