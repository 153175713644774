import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavigationCancel, Router, RouterModule } from "@angular/router";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { MtxPopoverModule } from "@ng-matero/extensions";
import { FormlyModule } from "@ngx-formly/core";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";

import {
  CASE_SUMMARY_API_BASE_URL,
  CaseSummaryService
} from "@vp/case-search/data-access/case-summary";
import { ApplicationState } from "@vp/data-access/application";
import { CaseApiService, CaseState } from "@vp/data-access/case";
import { CASE_TYPES_API_BASE_URL, CaseTypesState } from "@vp/data-access/case-types";
import {
  CONTENT_MANAGEMENT_API_BASE_URL,
  ContentApiService,
  ContentFilterState
} from "@vp/data-access/content-management";
import { GROUPS_API_BASE_URL, GroupsState } from "@vp/data-access/groups";
import { OrganizationState } from "@vp/data-access/organization";
import { TAG_API_BASE_URL, TagsApiService, TagsState } from "@vp/data-access/tags";
import { USER_API_BASE_URL, UserApiService, UserState } from "@vp/data-access/users";
import { FormlyJsonSchemaModule, JsonSchemaValueProviderService } from "@vp/formly/json-schema";
import { FORM_STATE_PROVIDERS } from "@vp/formly/ui-schema-config";
import { AJV_CLASS, AJV_INSTANCE, JsonSchemaValidationService } from "@vp/json-schema-validation";
import { AccessControlService } from "@vp/shared/access-control";
import { AuthenticationService } from "@vp/shared/authentication";
import { BreadcrumbModule } from "@vp/shared/components/breadcrumb";
import { PageMenuModule } from "@vp/shared/components/page-menu";
import { SecurityPopupModule } from "@vp/shared/components/security-popup";
import { TagSelectorComponent } from "@vp/shared/components/tag-selector";
import { CybersourceModule } from "@vp/shared/cybersouce";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { FeatureService, FeaturesModule } from "@vp/shared/features";
import {
  CountryDropdownFormStateProviderService,
  CountryTagFormStateProviderService,
  TagsFormStateProviderService
} from "@vp/shared/form-state-providers";
import { AuthenticationGuard, IS_IVY_API } from "@vp/shared/guards";
import {
  LOCAL_STORAGE_OPTIONS,
  LocalStorageModule,
  LocalStorageService
} from "@vp/shared/local-storage";
import { Logger } from "@vp/shared/logging-service";
import { MaterialModule } from "@vp/shared/material";
import {
  PERMISSIONS_CONST_ENVIRONMENT_IS_PROD,
  PermissionsConstService
} from "@vp/shared/permissions-const";
import { ContextDisplayModule } from "@vp/shared/pipes/context-display";
import { SignalRApiService, SignalRState } from "@vp/shared/signal-r-service";
import { HttpStateService, SpinnerInterceptorService, SpinnerModule } from "@vp/shared/spinner";
import { APP_STORE_API_BASE_URL, AppStoreService } from "@vp/shared/store/app";
import { UiDisplayTagService } from "@vp/shared/store/ui";
import { ANGULAR_EDITOR_CONFIG, API_BASE_URL } from "@vp/shared/tokens";
import { TopNavFeatureModule } from "@vp/shared/top-nav/feature";
import { TopNavShellModule } from "@vp/shared/top-nav/ui/top-nav-shell";
import { UTILITY_OPTIONS } from "@vp/shared/utilities";
import { ThemeManagerModule } from "@vp/theme-manager";
import { UserProfileGuard } from "@vp/user-profile/feature";
import {
  ADVANCED_COMMANDS_API_BASE_URL,
  AdvancedCommandsService
} from "@vp/virtual-care/feature-advanced-commands";
import ajv from "ajv";
import {
  AuthModule,
  EventTypes,
  OidcConfigService,
  PublicEventsService
} from "angular-auth-oidc-client";
import {
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module
} from "ng-recaptcha";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { MomentModule } from "ngx-moment";
import { NgxPermissionsModule, NgxPermissionsService } from "ngx-permissions";
import { ToastContainerModule, ToastrModule } from "ngx-toastr";
import { filter } from "rxjs/operators";
import { environment } from "../environments/environment";
import { config } from "../environments/environment.config";
import { accessControlFactory } from "./access-control.factory";
import { angularEditorConfigDefault, configureAuth } from "./app-config";
import { CONFIG_DEPENDENCIES, appInitializerFactory } from "./app-initializer.factory";
import { AppRoutingModule } from "./app-routing.module";
import { appThemeManagerConfig } from "./app-theme-manager.config";
import { AppComponent } from "./app.component";
import { GlobalErrorHandler } from "./global-error-handler";
import { LogoutComponent } from "./logout/logout.component";
import { AuthHeaderInterceptor } from "./shared/interceptors/auth-header-interceptor";
import { FeatureInterceptor } from "./shared/interceptors/feature-interceptor";
import { ServerErrorInterceptor } from "./shared/interceptors/server-error.interceptor";
import { SharedModule } from "./shared/shared.module";

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin
]);

export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null; // for use with ngx-mask

@NgModule({
  declarations: [AppComponent, LogoutComponent],
  imports: [
    CommonModule,
    NgxsModule.forRoot(
      [
        ApplicationState,
        SignalRState,
        CaseState,
        ContentFilterState,
        CaseTypesState,
        GroupsState,
        OrganizationState,
        TagsState,
        UserState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          injectContainerState: false
        }
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AppRoutingModule,
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    BreadcrumbModule,
    FeaturesModule,
    FormlyModule,
    MatBadgeModule,
    MaterialModule,
    MtxPopoverModule,
    PageMenuModule,
    RouterModule,
    SharedModule,
    ContextDisplayModule,
    FlexLayoutModule,
    FullCalendarModule,
    HttpClientModule,
    LocalStorageModule,
    MatDatepickerModule,
    MaterialModule,
    MomentModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    SpinnerModule,
    ToastContainerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    }),
    FormlyJsonSchemaModule.forRoot({
      providers: [],
      uischema: {
        layouts: [
          {
            types: ["payment"],
            elements: [
              {
                scope: "#/properties/payment",
                definition: "payment-page",
                formlyConfig: {}
              }
            ]
          },
          {
            types: ["images"],
            elements: [
              {
                scope: "#/properties/images",
                definition: "dicoms"
              }
            ]
          }
        ],
        definitions: [
          {
            types: ["email-field"],
            formlyConfig: {
              templateOptions: {
                type: "email",
                maxLength: 255
              },
              validators: {
                validation: ["email"]
              }
            }
          },
          {
            types: ["facesheet-selector"],
            formlyConfig: {
              type: "flex-layout",
              key: "facesheet",
              templateOptions: {
                label: "Facesheet Upload",
                fxLayout: "column",
                fxLayoutAlign: "start stretch"
              },
              fieldGroup: [
                {
                  key: "selUpload",
                  type: "checkbox",
                  templateOptions: {
                    label: "Check for facesheet upload.",
                    fxLayoutAlign: "end"
                  }
                },
                {
                  key: "facesheet-actions",
                  type: "facesheet-selector",
                  hideExpression: (model: any) => {
                    console.log(JSON.stringify(model));
                    return !model?.selUpload;
                  }
                }
              ]
            }
          },
          {
            types: ["attachments"],
            formlyConfig: {
              name: "attachments",
              templateOptions: {
                label: "Attachments",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "document-data",
                  type: "document-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-actions",
                  type: "document-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-grid",
                  type: "document-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-actions",
                  type: "image-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-grid",
                  type: "image-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["documents"],
            formlyConfig: {
              name: "documents",
              templateOptions: {
                label: "Documents",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "document-data",
                  type: "document-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-actions",
                  type: "document-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "document-grid",
                  type: "document-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["images"],
            formlyConfig: {
              name: "images",
              templateOptions: {
                label: "Images",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "image-data",
                  type: "image-data",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-actions",
                  type: "image-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "image-grid",
                  type: "image-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["dicoms"],
            formlyConfig: {
              name: "dicoms",
              templateOptions: {
                label: "Imaging",
                order: 2,
                fxLayout: "column"
              },
              fieldGroup: [
                {
                  key: "dicoms-data",
                  type: "image-data", // Reusing image-data component
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  template: `
                <div style='margin: 16px !important'>
                  <div>
                    <span><strong>1.</strong></span>
                    Please place the CD in the CD drive of your computer, or insert the USB Flash Drive you were given after receiving radiology imaging back from the facility you had radiologic imaging done.
                  </div>
                  <div>
                    <span><strong>2.</strong></span>
                    Close any other browser windows.
                  </div>
                  <div>
                    <span><strong>3.</strong></span>
                    Click on "Upload DICOM Folder" button.
                  </div>
                  <div>
                    <span ><strong>4.</strong></span>
                    Click Select Folder from your CD or USB Flash Drive.
                  </div>
                  <div>
                    <span><strong>5.</strong></span>
                    If the facility you had imaging done provided you with a download link please ensure the files are downloaded and unzipped. Export the files to a folder on your desktop and select the folder.
                  </div>
                  <div>
                    <span><strong>6.</strong></span>
                    Click the Upload button.
                  </div>
                </div>
                `
                },
                {
                  key: "dicoms-actions",
                  type: "dicoms-actions",
                  templateOptions: {
                    fxFlex: 100
                  }
                },
                {
                  key: "dicoms-grid",
                  type: "dicoms-grid",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["assignments"],
            formlyConfig: {
              name: "assignments",
              templateOptions: {
                label: "Assignments",
                order: 3,
                fxLayout: "row wrap"
              },
              fieldGroup: [
                {
                  key: "assign-groups",
                  type: "assign-groups",
                  templateOptions: {
                    fxFlex: 100
                  }
                }
              ]
            }
          },
          {
            types: ["state-selector"],
            formlyConfig: {
              type: "select",
              templateOptions: {
                options: [
                  {
                    label: "Alabama",
                    value: "AL"
                  },
                  {
                    label: "Alaska",
                    value: "AK"
                  },
                  {
                    label: "Arizona",
                    value: "AZ"
                  },
                  {
                    label: "Arkansas",
                    value: "AR"
                  },
                  {
                    label: "California",
                    value: "CA"
                  },
                  {
                    label: "Colorado",
                    value: "CO"
                  },
                  {
                    label: "Connecticut",
                    value: "CT"
                  },
                  {
                    label: "Delaware",
                    value: "DE"
                  },
                  {
                    label: "District Of Columbia",
                    value: "DC"
                  },
                  {
                    label: "Florida",
                    value: "FL"
                  },
                  {
                    label: "Georgia",
                    value: "GA"
                  },
                  {
                    label: "Guam",
                    value: "GU"
                  },
                  {
                    label: "Hawaii",
                    value: "HI"
                  },
                  {
                    label: "Idaho",
                    value: "ID"
                  },
                  {
                    label: "Illinois",
                    value: "IL"
                  },
                  {
                    label: "Indiana",
                    value: "IN"
                  },
                  {
                    label: "Iowa",
                    value: "IA"
                  },
                  {
                    label: "Kansas",
                    value: "KS"
                  },
                  {
                    label: "Kentucky",
                    value: "KY"
                  },
                  {
                    label: "Louisiana",
                    value: "LA"
                  },
                  {
                    label: "Maine",
                    value: "ME"
                  },
                  {
                    label: "Marshall Islands",
                    value: "MH"
                  },
                  {
                    label: "Maryland",
                    value: "MD"
                  },
                  {
                    label: "Massachusetts",
                    value: "MA"
                  },
                  {
                    label: "Michigan",
                    value: "MI"
                  },
                  {
                    label: "Minnesota",
                    value: "MN"
                  },
                  {
                    label: "Mississippi",
                    value: "MS"
                  },
                  {
                    label: "Missouri",
                    value: "MO"
                  },
                  {
                    label: "Montana",
                    value: "MT"
                  },
                  {
                    label: "Nebraska",
                    value: "NE"
                  },
                  {
                    label: "Nevada",
                    value: "NV"
                  },
                  {
                    label: "New Hampshire",
                    value: "NH"
                  },
                  {
                    label: "New Jersey",
                    value: "NJ"
                  },
                  {
                    label: "New Mexico",
                    value: "NM"
                  },
                  {
                    label: "New York",
                    value: "NY"
                  },
                  {
                    label: "North Carolina",
                    value: "NC"
                  },
                  {
                    label: "North Dakota",
                    value: "ND"
                  },
                  {
                    label: "Northern Mariana Islands",
                    value: "MP"
                  },
                  {
                    label: "Ohio",
                    value: "OH"
                  },
                  {
                    label: "Oklahoma",
                    value: "OK"
                  },
                  {
                    label: "Oregon",
                    value: "OR"
                  },
                  {
                    label: "Palau",
                    value: "PW"
                  },
                  {
                    label: "Pennsylvania",
                    value: "PA"
                  },
                  {
                    label: "Puerto Rico",
                    value: "PR"
                  },
                  {
                    label: "Rhode Island",
                    value: "RI"
                  },
                  {
                    label: "South Carolina",
                    value: "SC"
                  },
                  {
                    label: "South Dakota",
                    value: "SD"
                  },
                  {
                    label: "Tennessee",
                    value: "TN"
                  },
                  {
                    label: "Texas",
                    value: "TX"
                  },
                  {
                    label: "Utah",
                    value: "UT"
                  },
                  {
                    label: "Vermont",
                    value: "VT"
                  },
                  {
                    label: "Virgin Islands",
                    value: "VI"
                  },
                  {
                    label: "Virginia",
                    value: "VA"
                  },
                  {
                    label: "Washington",
                    value: "WA"
                  },
                  {
                    label: "West Virginia",
                    value: "WV"
                  },
                  {
                    label: "Wisconsin",
                    value: "WI"
                  },
                  {
                    label: "Wyoming",
                    value: "WY"
                  }
                ]
              }
            }
          },
          {
            types: ["payment-page"],
            formlyConfig: {
              type: "flex-layout",
              key: "payment",
              name: "payment",
              templateOptions: {
                label: "Payment",
                actions: {
                  payment: {
                    submit: true
                  }
                }
              },
              fieldGroup: [
                {
                  key: "cybersource",
                  type: "cybersource-payment"
                }
              ]
            }
          }
        ]
      }
    }),
    NgxPermissionsModule.forRoot(),
    NgxMaskModule.forRoot(),
    ThemeManagerModule.forRoot(appThemeManagerConfig),
    FormlyModule.forRoot({
      extras: {
        checkExpressionOn: "modelChange"
      },
      validators: [
        { name: "requiredTrue", validation: Validators.requiredTrue },
        { name: "email", validation: Validators.email }
      ],
      validationMessages: [{ name: "email", message: "A Valid email address is required" }],
      types: [
        {
          name: "tag-selector",
          component: TagSelectorComponent
        }
      ]
    }),
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    FeaturesModule,
    CybersourceModule,
    TopNavFeatureModule,
    TopNavShellModule,
    SecurityPopupModule
  ],
  providers: [
    AppStoreService,
    SignalRApiService,
    TagsApiService,
    { provide: APP_STORE_API_BASE_URL, useValue: config.baseApi },
    {
      provide: AccessControlService,
      deps: [AppStoreService, Logger, NgxPermissionsService, Router]
    },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FeatureInterceptor, multi: true, deps: [Store] },
    EventAggregator,
    JsonSchemaValidationService,
    {
      provide: "environment",
      useValue: environment
    },
    AdvancedCommandsService,
    { provide: ADVANCED_COMMANDS_API_BASE_URL, useValue: config.baseApi },
    CaseApiService,
    { provide: API_BASE_URL, useValue: config.baseApi },
    ContentApiService,
    { provide: API_BASE_URL, useValue: config.baseApi },
    CaseSummaryService,
    { provide: CASE_SUMMARY_API_BASE_URL, useValue: config.baseApi },
    { provide: CASE_TYPES_API_BASE_URL, useValue: `${config.baseApi}/casetype` },
    { provide: GROUPS_API_BASE_URL, useValue: `${config.baseApi}/group` },
    PermissionsConstService,
    { provide: PERMISSIONS_CONST_ENVIRONMENT_IS_PROD, useValue: environment.production },
    { provide: TAG_API_BASE_URL, useValue: `${config.baseApi}/tags` },
    UserApiService,
    { provide: USER_API_BASE_URL, useValue: config.baseApi },
    {
      provide: ANGULAR_EDITOR_CONFIG,
      useValue: angularEditorConfigDefault
    },
    { provide: CONTENT_MANAGEMENT_API_BASE_URL, useValue: config.baseApi },
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      multi: true,
      // WARNING! These deps need to match that of the appIntializerFactory DI
      deps: [
        AccessControlService,
        AppStoreService,
        AuthenticationService,
        CONFIG_DEPENDENCIES,
        FeatureService,
        IS_IVY_API,
        LocalStorageService,
        PermissionsConstService,
        Router,
        Store,
        UiDisplayTagService
      ]
    },
    {
      provide: CONFIG_DEPENDENCIES,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (accessControlService: AccessControlService) => {
        // Easy to add or remove dependencies
        return [accessControlFactory(accessControlService)];
      },
      deps: [AccessControlService]
    },
    {
      provide: IS_IVY_API,
      useFactory: () => {
        const fromHost = window.location.href;
        return environment.ivySiteUrls.some(x => fromHost.includes(x));
      }
    },
    {
      provide: LOCAL_STORAGE_OPTIONS,
      useValue: {
        storageName: "app",
        maxAgeSeconds: 600
      }
    },
    {
      provide: UTILITY_OPTIONS,
      useValue: {
        routeDefault: "/home"
      }
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptorService,
      multi: true,
      deps: [HttpStateService]
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: UserProfileGuard, useClass: UserProfileGuard },
    {
      provide: AuthenticationGuard,
      useClass: AuthenticationGuard
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaSecretKey
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.reCaptchaSiteKey,
        badge: "inline"
      } as RecaptchaSettings
    },
    { provide: AJV_CLASS, useValue: ajv },
    {
      provide: AJV_INSTANCE,
      useFactory: (ajvClass: any) => {
        // TODO - remove schema property in database before turning on strict mode
        return new ajvClass({ strict: false });
      },
      deps: [AJV_CLASS]
    },
    JsonSchemaValueProviderService,
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: TagsFormStateProviderService,
      multi: true,
      deps: [Store, TagsState]
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: CountryTagFormStateProviderService,
      multi: true,
      deps: [Store, TagsState, ApplicationState]
    },
    {
      provide: FORM_STATE_PROVIDERS,
      useClass: CountryDropdownFormStateProviderService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private eventService: PublicEventsService, private route: Router) {
    this.route.events.pipe(filter(event => event instanceof NavigationCancel)).subscribe();

    this.eventService
      .registerForEvents()
      .pipe(
        filter(notification => {
          return notification.type === EventTypes.CheckSessionReceived;
        })
      )
      .subscribe();
  }
}
