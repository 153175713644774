import { EventBase } from "@vp/shared/event-aggregator";
export interface CaseStatusChangedEventArgs {
  caseId: string;
  statusId: string;
  friendlyId: string;
  displayName: string;
  previousDisplayName: string;
}

export class CaseStatusChangedEvent extends EventBase<CaseStatusChangedEventArgs> {
  constructor(notification: CaseStatusChangedEventArgs) {
    super();
    this.args = notification;
  }
}
