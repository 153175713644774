import { EventBase } from "@vp/shared/event-aggregator";

export interface DeviceConnectionChangedEventArgs {
  deviceUserId: string;
  isOnline: boolean;
  lastConnectedDateTime: string;
  lastDisconnectedDateTime: string;
}

export class DeviceConnectionChangedEvent extends EventBase<DeviceConnectionChangedEventArgs> {
  constructor(deviceArgs: DeviceConnectionChangedEventArgs) {
    super();
    this.args = deviceArgs;
  }
}
