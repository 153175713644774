import { Inject, Injectable, isDevMode } from "@angular/core";
import { IvyUser } from "@vp/models";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AuthOptions } from "angular-auth-oidc-client/lib/auth-options";
import { KJUR } from "jsrsasign";
import moment from "moment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private _isLoggingOut = false;

  constructor(
    @Inject("environment") private environment: Record<string, unknown>,
    private oidcSecurityService: OidcSecurityService
  ) {}

  get isLoggingOut(): boolean {
    return this._isLoggingOut;
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth();
  }

  isLoggedIn$(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  checkSessionChanged(): Observable<boolean> {
    return this.oidcSecurityService.checkSessionChanged$;
  }

  getAccount(): Observable<Record<string, unknown>> {
    return this.oidcSecurityService.userData$;
  }

  login(register: boolean = false): void {
    const authOptions: AuthOptions = {
      customParams: {
        brand: this.environment.oktaLoginBrand as string,
        display_env: this.environment.consumerOktaEnv as string
      }
    };

    if (authOptions.customParams) {
      if (register) authOptions.customParams.ui_hint = "register";
      if (isDevMode()) authOptions.customParams.display_env = "stg";
    }

    this.oidcSecurityService.authorize(authOptions);
  }

  logout(): void {
    this._isLoggingOut = true;
    this.oidcSecurityService.logoff();
    this._isLoggingOut = false;
  }

  getAccessToken(): string {
    return this.oidcSecurityService.getToken();
  }

  getIdToken(): string {
    return this.oidcSecurityService.getIdToken();
  }

  //TODO should this be removed? Ivy not being used anymore
  //#region IVY API
  //The Ivy API is not protected via Okta, we validate the custom jwt claims for a
  //specific userid (i.e. the Ivy user) and verify the signature against our ivyAppSecret

  public generateIvyToken() {
    //token expires in 60 secs, enough time for API call to return.
    const tNow = moment.now() / 1000 - 5;
    const tEnd = tNow + 60;

    // Header
    const oHeader = { alg: "HS256", typ: "JWT" };
    const sHeader = JSON.stringify(oHeader);

    // Payload
    const ivyUser: IvyUser = this.environment.ivyUser as IvyUser;
    const oPayload = {
      iss: window.location.hostname,
      nbf: tNow,
      iat: tNow,
      exp: tEnd,
      userid: ivyUser.userId,
      preferred_username: ivyUser.email,
      sub: ivyUser.email,
      given_name: ivyUser.firstName,
      family_name: ivyUser.lastName
    };
    const sPayload = JSON.stringify(oPayload);

    // Sign JWT
    return KJUR.jws.JWS.sign(
      "HS256",
      sHeader,
      sPayload,
      this.environment.ivyAppSecret as string | undefined
    );
  }

  //#endregion
}
