<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    hasPending: (hasPending$ | async),
    showRolesAndGroups: (showRolesAndGroups$ | async) || false,
    reason: (reason$ | async) || null,
    verified: (verified$ | async) || false,
    user: (user$ | async),
    optIn: (optIn$ | async)
  }"
>
</ng-container>

<ng-template
  #template
  let-hasPending="hasPending"
  let-showRolesAndGroups="showRolesAndGroups"
  let-reason="reason"
  let-verified="verified"
  let-user="user"
  let-optIn="optIn"
>
  <div fxLayout="row" fxLayoutAlign="center stretch">
    <div
      fxLayout="column"
      fxLayoutAlign="start center"
      [fxFlex.gt-md]="showRolesAndGroups ? 90 : 60"
      fxFlex.lt-md="100"
    >
      <mat-card *ngIf="reason" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-icon [color]="reason.type">info</mat-icon>
        <div fxLayout="column" fxlayoutAlign="start stretch">
          <span>{{ reason.title }}</span>
          <span>{{ reason.reason }}</span>
        </div>
      </mat-card>

      <mat-card *ngIf="form">
        <form fxFill [formGroup]="form">
          <div fxFill fxLayout="column" fxLayoutAlign="space-between stretch">
            <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start">
              <div [fxFlex]="showRolesAndGroups ? 60 : 100" fxFlex.lt-md="100">
                <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start"></div>
              </div>
              <mat-form-field fxFlex="100">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Email Address" formControlName="email" />
                <mat-icon [color]="verified ? 'primary' : 'warn'" matSuffix>{{
                  verified ? "done" : "report_problem"
                }}</mat-icon>
              </mat-form-field>
              <formly-form
                [form]="form"
                [fields]="fields"
                [model]="model"
                [options]="options"
              ></formly-form>


              <div
                *ngxPermissionsOnly="[this.permConst.Profile.DeptmentAndGroup.Read]"
                fxLayout="column"
                fxFlex="100"
                fxFlex.lt-md="100"
                fxLayoutAlign="start stretch"
                fxLayoutGap="16px"
              >
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.RoleAssignment.Read]"
                >
                  <vp-user-assigned-roles></vp-user-assigned-roles>
                </div>
                <div
                  class="wrapper"
                  *ngxPermissionsOnly="[permConst.Admin.User.GroupAssignment.Read]"
                >
                  <vp-user-assigned-groups></vp-user-assigned-groups>
                </div>
                <div class="wrapper" *ngxPermissionsOnly="[permConst.Admin.User.Snippet.Read]">
                  <vp-user-snippets></vp-user-snippets>
                </div>
              </div>
              <ng-template [featureEnabled]="'smsOptIn'">
                <mat-checkbox [checked]="optIn" (change)="toggleOptIn($event.checked)">
                  {{ optInVerbiage | async }}
                </mat-checkbox>
              </ng-template>
            </div>
            <div
              class="action-row"
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="16px"
            >
              <div fxLayout="row" fxLayoutAlign="start center">
                <span *ngIf="hasPending === true && form.invalid === false"
                  >This form has pending changes.</span
                >
                <span *ngIf="form.invalid === true">Please fix invalid fields.</span>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-flat-button type="button" [routerLink]="['/default']">
                  {{ form.dirty ? "Cancel" : "Close" }}
                </button>
                <button
                  mat-flat-button
                  color="primary"
                  type="submit"
                  [disabled]="form.invalid"
                  (click)="onSave()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</ng-template>
