import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GroupsDataAccessModule } from "@vp/data-access/groups";
import { TagsDataAccessModule } from "@vp/data-access/tags";
import { CaseRecordDataPipe } from "./case-record-data.pipe";
import { CaseStatusPipe } from "./case-status.pipe";
import { CaseTypeDisplayPipe } from "./case-type-display.pipe";
import { DaysBetweenTwoDatesPipe } from "./days-between-two-dates.pipe";
import { DepartmentPipe } from "./department.pipe";
import { GroupDisplayPipe } from "./group-display.pipe";
import { GroupTypePipe } from "./group-type.pipe";
import { RolePipe } from "./role.pipe";
import { TagDisplayPipe } from "./tag-display.pipe";
import { TagSortPipe } from "./tag-sort.pipe";
import { TagTypePipe } from "./tag-type.pipe";
import { TagsArrayPipe } from "./tags-array.pipe";
import { UiDisplayTagsPipe } from "./ui-display-tags.pipe";
import { UserGroupsPipe } from "./user-groups.pipe";
import { UserNamePipe } from "./user-name.pipe";

@NgModule({
  imports: [CommonModule, GroupsDataAccessModule, TagsDataAccessModule],
  exports: [
    CaseStatusPipe,
    DaysBetweenTwoDatesPipe,
    DepartmentPipe,
    GroupDisplayPipe,
    GroupTypePipe,
    RolePipe,
    TagDisplayPipe,
    TagSortPipe,
    TagTypePipe,
    UserGroupsPipe,
    UserNamePipe,
    CaseRecordDataPipe,
    TagsArrayPipe,
    UiDisplayTagsPipe,
    CaseTypeDisplayPipe
  ],
  declarations: [
    CaseStatusPipe,
    DaysBetweenTwoDatesPipe,
    DepartmentPipe,
    GroupDisplayPipe,
    GroupTypePipe,
    RolePipe,
    TagDisplayPipe,
    TagSortPipe,
    TagTypePipe,
    UserNamePipe,
    UserGroupsPipe,
    CaseRecordDataPipe,
    TagsArrayPipe,
    UiDisplayTagsPipe,
    CaseTypeDisplayPipe
  ]
})
export class ContextDisplayModule {}
