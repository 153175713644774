<h1 mat-dialog-title>
  <span>{{ action + " Snippet" }}</span>
</h1>
<mat-dialog-content>
  <form #form="ngForm" fxLayout="column" fxLayoutAlign="space-around" fxLayoutGap="10px">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input title="Title" matInput [(ngModel)]="snippet.title" required name="title" />
    </mat-form-field>
    <angular-editor [(ngModel)]="snippet.text" [config]="config" required name="text">
    </angular-editor>
  </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button type="button" mat-flat-button mat-dialog-close>Cancel</button>
  <button type="button" mat-raised-button color="primary" [mat-dialog-close]="snippet" [disabled]="!form.valid">
    Save
  </button>
</mat-dialog-actions>