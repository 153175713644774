import { Inject, Injectable } from "@angular/core";
import { FormlyFormOptions } from "@ngx-formly/core";
import {
  FormStateProvider,
  FORM_STATE_PROVIDERS,
  UiSchema,
  UiSchemaConfigService
} from "@vp/formly/ui-schema-config";
import { deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { Observable, of } from "rxjs";

/*
  This entire class is temporary this functionality will be moved into a provider to live close to the app possibly
  with the logic provided as a factory or class provider.
*/
@Injectable({
  providedIn: "root"
})
export class UiSchemaStateProvider {
  constructor(
    @Inject(FORM_STATE_PROVIDERS) private formStateProviders: FormStateProvider[],
    private configService: UiSchemaConfigService
  ) {}

  applyScopes(
    type: string,
    formlyFormOptions: FormlyFormOptions = {},
    scope: string | null = null
  ): Observable<FormlyFormOptions> {
    let clone = {};
    if (Object.keys(formlyFormOptions).length === 0) {
      clone = {
        formState: {}
      };
    } else {
      clone = deeperCopy(formlyFormOptions);
    }

    const elements = this.configService
      .getLayoutElements(type, scope)
      .filter(e => e.formlyFormOptions);

    if (elements.length > 0) {
      elements.forEach((element: UiSchema) => {
        clone = this.applyFormState(clone, element);
      });
    }
    return of(clone);
  }

  private applyFormState(
    formlyFormOptions: FormlyFormOptions,
    element: UiSchema
  ): FormlyFormOptions {
    const options = this.formStateProviders.reduce((acc, provider) => {
      const formState = provider.provide(element.formlyFormOptions?.formState);
      const merged = mergeDeep(acc, {
        formState: formState
      });
      return merged;
    }, formlyFormOptions);
    return options;
  }
}
