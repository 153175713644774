import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { TagsState } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";
import { Tag } from "@vp/models";

@Injectable()
export class CountryTagFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>): Record<string, unknown> {
    if (Object.keys(state).length === 0 || !state.clientTagTypeFriendlyId) return {};

    const loggedInUser = this.store.selectSnapshot(ApplicationState.loggedInUser);
    const allTags = this.store.selectSnapshot(TagsState.tags);
    const clientTags = allTags.filter(t => t.tagTypeFriendlyId === state.clientTagTypeFriendlyId);
    const clientTag = clientTags.find(c => loggedInUser?.assignedTags.includes(c.tagId));
    let countryTag: Tag | undefined;
    if (clientTag) {
      const countryTagId = clientTag.tagData?.serviceDelivered;
      countryTag = allTags.find(t => t.tagId === countryTagId);
    }
    return countryTag ? { country: countryTag } : {};
  }
}
