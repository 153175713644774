import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CaseType, PageResult } from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";
import { tap } from "rxjs/operators";
import { CaseTypesApiService } from "../api/case-types-api.service";
import * as CaseTypesActions from "./case-types-actions";

export interface CaseTypesStateModel {
  allCaseTypes: CaseType[];
  pagedCaseTypes: PageResult<CaseType> | null;
  currentCaseType: CaseType | null;
  caseTypeFee: number | null;
}

@State<CaseTypesStateModel>({
  name: "caseTypes",
  defaults: { allCaseTypes: [], pagedCaseTypes: null, currentCaseType: null, caseTypeFee: null }
})
@Injectable()
export class CaseTypesState {
  constructor(private readonly _caseTypesApiService: CaseTypesApiService) {}

  @Selector()
  public static allCaseTypes(state: CaseTypesStateModel) {
    return deeperCopy(state.allCaseTypes);
  }

  @Selector()
  public static pagedCaseTypes(state: CaseTypesStateModel) {
    return state.pagedCaseTypes;
  }

  @Selector()
  public static currentCaseType(state: CaseTypesStateModel): CaseType | null {
    return deeperCopy(state.currentCaseType);
  }

  @Selector()
  public static caseTypeFee(state: CaseTypesStateModel) {
    return state.caseTypeFee;
  }

  // ngxsOnInit(ctx: StateContext<CaseTypesStateModel>) {
  //   ctx.dispatch(CaseTypesActions.LoadCaseTypes);
  // }

  @Action(CaseTypesActions.CreateCaseType)
  createCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseType }: CaseTypesActions.CreateCaseType
  ) {
    return this._caseTypesApiService
      .createCaseType(caseType)
      .pipe(
        tap((caseType: CaseType) =>
          ctx.patchState({ allCaseTypes: [...ctx.getState().allCaseTypes, caseType] })
        )
      );
  }

  @Action(CaseTypesActions.LoadCaseTypes)
  loadAll(ctx: StateContext<CaseTypesStateModel>) {
    return this._caseTypesApiService.getAllCaseTypes().pipe(
      tap((caseTypes: CaseType[]) => {
        ctx.patchState({ allCaseTypes: caseTypes });
      })
    );
  }

  @Action(CaseTypesActions.LoadPagedCaseTypes)
  loadPagedCaseTypes(
    ctx: StateContext<CaseTypesStateModel>,
    { options }: CaseTypesActions.LoadPagedCaseTypes
  ) {
    return this._caseTypesApiService
      .getCaseTypesPaged(options)
      .pipe(
        tap((pageResult: PageResult<CaseType>) => ctx.patchState({ pagedCaseTypes: pageResult }))
      );
  }

  @Action(CaseTypesActions.SetCurrentCaseType)
  setCurrentCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.SetCurrentCaseType
  ) {
    return this._caseTypesApiService.getCaseTypeById(caseTypeId).pipe(
      tap(caseType => {
        ctx.patchState({ currentCaseType: caseType });
      })
    );
  }

  @Action(CaseTypesActions.UnsetCurrentCaseType)
  unsetCurrentCaseType(ctx: StateContext<CaseTypesStateModel>) {
    ctx.patchState({ currentCaseType: null, caseTypeFee: null });
  }

  @Action(CaseTypesActions.GetCaseTypeFee)
  getCaseTypeFee(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.GetCaseTypeFee
  ) {
    return this._caseTypesApiService
      .getCaseTypeFee(caseTypeId)
      .pipe(tap((fee: number) => ctx.patchState({ caseTypeFee: fee })));
  }

  @Action(CaseTypesActions.UpdateCaseType)
  updateCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseType }: CaseTypesActions.UpdateCaseType
  ) {
    return this._caseTypesApiService.updateCaseType(caseType).pipe(
      tap((caseType: CaseType) => {
        ctx.patchState({
          allCaseTypes: [
            ...ctx.getState().allCaseTypes.filter(t => t.caseTypeId !== caseType.caseTypeId),
            caseType
          ]
        });
      })
    );
  }

  @Action(CaseTypesActions.PatchCaseTypeWithOperations)
  patch(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId, operations }: CaseTypesActions.PatchCaseTypeWithOperations
  ) {
    return this._caseTypesApiService.patch(caseTypeId, operations).pipe(
      tap((caseType: CaseType) => {
        ctx.patchState({
          allCaseTypes: [
            ...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== caseTypeId),
            caseType
          ]
        });
      })
    );
  }

  @Action(CaseTypesActions.PatchCaseType)
  patchCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { original, changed }: CaseTypesActions.PatchCaseType
  ) {
    return this._caseTypesApiService.patchCaseType(original, changed).pipe(
      tap((caseType: CaseType) =>
        ctx.patchState({
          allCaseTypes: [
            ...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== original.caseTypeId),
            caseType
          ]
        })
      )
    );
  }

  @Action(CaseTypesActions.DeleteCaseType)
  deleteCaseType(
    ctx: StateContext<CaseTypesStateModel>,
    { caseTypeId }: CaseTypesActions.DeleteCaseType
  ) {
    return this._caseTypesApiService.deleteCaseType(caseTypeId).pipe(
      tap((result: CaseType) => {
        if (result) {
          ctx.patchState({
            allCaseTypes: [...ctx.getState().allCaseTypes.filter(g => g.caseTypeId !== caseTypeId)]
          });
        }
      })
    );
  }
}
