import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Select } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Organization, Tag, TagFilter } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, mergeMap, takeUntil } from "rxjs/operators";
import { TagSelectorContext } from "./tag-selector-context.service";

export interface TagSelection {
  tagTypeFriendlyId: string;
  tagId: string;
}

@Component({
  selector: "vp-tag-selector",
  templateUrl: "./tag-selector.component.html",
  styleUrls: ["./tag-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  formControl = new FormControl();

  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  @Select(TagsState.defaultFilters) defaultFilters!: Observable<TagFilter[] | null>;

  @Input() tagTypeFriendlyId!: string;
  @Input() selectedTag: string | null = null;
  @Input() noneSelectedText = "All";
  @Input() disableFixedInput = false;
  @Input() isDisabled = false;

  @Output() changedEvent = new EventEmitter<TagSelection>();

  private readonly tagTypeFriendlyId$ = new BehaviorSubject<string | null>(null);
  private readonly _selectedTag$ = new Subject<TagSelection>();
  private readonly _destroyed$ = new Subject<void>();

  constructor(private tagSelectorContext: TagSelectorContext) {}

  availableTags$ = this.tagTypeFriendlyId$.pipe(
    filterNullMap(),
    mergeMap(tagTypeFriendlyId => this.tagSelectorContext.availableTagsFor$(tagTypeFriendlyId))
  );

  hasTags$ = this.availableTags$.pipe(map(tags => tags.length > 0));
  tagType$ = this.organization$.pipe(
    map((org: Organization) => org.tagTypes.find(t => t.friendlyId === this.tagTypeFriendlyId)),
    filterNullMap()
  );

  ngOnInit(): void {
    this.formControl.setValue(this.selectedTag);
    this.tagTypeFriendlyId$.next(this.tagTypeFriendlyId);
    this.tagSelectorContext.addSelector(this.tagTypeFriendlyId, this.selectedTag);
  }

  ngAfterViewInit(): void {
    this._selectedTag$.pipe(takeUntil(this._destroyed$)).subscribe(selected => {
      this.changedEvent.emit(selected);
    });
  }

  isFixed() {
    return (
      this.isDisabled ||
      (this.tagSelectorContext.isFixed(this.tagTypeFriendlyId) && !this.disableFixedInput)
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  selectionChanged(tagId: string) {
    this._selectedTag$.next({
      tagTypeFriendlyId: this.tagTypeFriendlyId,
      tagId: tagId
    });
    this.tagSelectorContext.selectTagFor(this.tagTypeFriendlyId, tagId);
  }
}
