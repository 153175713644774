import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { CaseState } from "@vp/data-access/case";
import { TagsState } from "@vp/data-access/tags";
import { FormStateProvider } from "@vp/formly/ui-schema-config";

@Injectable()
export class TagsFormStateProviderService implements FormStateProvider {
  constructor(private store: Store) {}

  provide(state: Record<string, unknown>): Record<string, unknown> {
    if (Object.keys(state).length === 0) return {};

    const stateTags = state.tags as Record<string, unknown>;
    let formState = {};
    if (stateTags) {
      const caseData = this.store.selectSnapshot(CaseState.current);
      const allTags = caseData
        ? this.store.selectSnapshot(CaseState.assignableTags)
        : this.store.selectSnapshot(TagsState.tags);
      formState = Object.entries(stateTags).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accumulator: Record<string, unknown>, [key, value]: [string, any]) => {
          accumulator[key] = [
            ...allTags
              .filter(tag => tag.tagTypeFriendlyId === value?.tagTypeFriendlyId)
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
          ];
          return accumulator;
        },
        { ...stateTags }
      );
    }
    return formState;
  }
}
