import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { filterNullMap } from "@vp/shared/operators";
import { Observable, Subject } from "rxjs";
import { shareReplay, tap } from "rxjs/operators";
import { CacheService } from "./cache.service";

const pendingRequests = new Map<string, Observable<any>>();

@Injectable({
  providedIn: "root"
})
export class CachingDataService<T> {
  constructor(private http: HttpClient, private cacheService: CacheService<T>) {}

  private getFromApiOrCache(cacheKey: string, ageMs: number, url: string): Observable<T> {
    if (this.cacheService.has(cacheKey)) {
      return this.cacheService.get(cacheKey, this.http.get<T>(url));
    }

    if (pendingRequests.has(cacheKey)) {
      const pending = pendingRequests.get(cacheKey);
      if (pending) return pending;
    }

    const pendingRequest$ = new Subject<T>();
    pendingRequests.set(cacheKey, pendingRequest$);

    this.http
      .get<T>(url)
      .pipe(filterNullMap())
      .subscribe(
        (data: T) => {
          this.cacheService.set(cacheKey, data, ageMs);
          pendingRequest$.next(data);
          pendingRequest$.complete();
          pendingRequests.delete(cacheKey);
        },
        err => {
          pendingRequest$.error(err);
          pendingRequests.delete(cacheKey);
        }
      );

    return pendingRequest$.asObservable();
  }

  public getCachedData = (
    id: string,
    cacheKey: string,
    ageMs: number,
    url: string
  ): Observable<T> =>
    this.cacheService.has(`${cacheKey}.${id}`)
      ? this.cacheService.get(`${cacheKey}.${id}`, this.http.get<T>(url))
      : this.getFromApiOrCache(`${cacheKey}.${id}`, ageMs, url).pipe(shareReplay(1));

  public getCachedDataOnce = (
    id: string,
    cacheKey: string,
    ageMs: number,
    url: string
  ): Observable<T> =>
    this.cacheService.has(`${cacheKey}.${id}`)
      ? this.cacheService.get(`${cacheKey}.${id}`, this.http.get<T>(url))
      : this.getFromApiOrCache(`${cacheKey}.${id}`, ageMs, url).pipe(
          tap(user => {
            this.cacheService.set(`${cacheKey}.${id}`, user, ageMs);
          }),
          shareReplay(1)
        );
}
