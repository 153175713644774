import { Injectable } from "@angular/core";
import { Action, Select, Selector, State, StateContext } from "@ngxs/store";
import { CaseTypesState } from "@vp/data-access/case-types";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsApiService } from "@vp/data-access/tags";
import { CaseData, CaseType, Tag, TagType } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { deeperCopy, getUtcNow, parseError } from "@vp/shared/utilities";
import { Guid } from "guid-typescript";
import { createPatch, Operation } from "rfc6902";
import { combineLatest, EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { CaseApiService } from "../api/case-api.service";
import * as CaseActions from "./case.actions";

export type CaseDataState = {
  caseData: CaseData | null;
  // TODO: This stuff doesn't belong here
  assignableTagTypes: TagType[] | null;
  assignableTags: Tag[] | null;
  // ------------------------------------
  errors: string[];
};

@State<CaseDataState>({
  name: "case",
  defaults: {
    caseData: null,
    assignableTagTypes: [],
    assignableTags: [],
    errors: []
  }
})
@Injectable()
export class CaseState {
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;

  constructor(private api: CaseApiService, private tagsApiService: TagsApiService) {}

  @Selector()
  public static currentInternal(state: CaseDataState): CaseData | null {
    return state.caseData;
  }

  @Selector([CaseState.currentInternal])
  public static current(caseData: CaseData): CaseData | null {
    return caseData ? deeperCopy(caseData) : null;
  }

  @Selector()
  public static assignableTagTypes(state: CaseDataState) {
    return state.assignableTagTypes ?? [];
  }

  @Selector()
  public static assignableTags(state: CaseDataState) {
    return state.assignableTags ?? [];
  }

  /**
   * Retrieves a case by its caseId from the server and sets the state with the response
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.SetState)
  set(ctx: StateContext<CaseDataState>, { caseId }: CaseActions.SetState) {
    return this.api.getCase(caseId).pipe(
      withLatestFrom(this.tagTypes$, this.caseTypes$),
      tap(([caseData, tagTypes, caseTypes]: [CaseData, TagType[], CaseType[]]) => {
        ctx.patchState({
          caseData: caseData,
          assignableTagTypes: this.getAssignableTagTypes(caseData, tagTypes, caseTypes)
        });
      }),
      catchError(error => {
        ctx.patchState({
          caseData: null,
          assignableTagTypes: [],
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  /**
   * Generates a new "empty" case from the server and sets the state with the response
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.SetNewState)
  setNew(ctx: StateContext<CaseDataState>, { caseTypeId, subjectUserId }: CaseActions.SetNewState) {
    if (!caseTypeId) {
      return throwError("caseTypeId is not valid.");
    }

    if (!!subjectUserId && !Guid.isGuid(subjectUserId)) {
      return throwError("subjectUserId is not valid.");
    }

    return this.api.getCase(Guid.EMPTY, caseTypeId).pipe(
      withLatestFrom(this.tagTypes$, this.caseTypes$),
      tap(([caseData, tagTypes, caseTypes]: [CaseData, TagType[], CaseType[]]) => {
        if (subjectUserId)
          ctx.patchState({
            caseData: {
              ...caseData,
              subjectUserId: subjectUserId
            },
            assignableTagTypes: this.getAssignableTagTypes(caseData, tagTypes, caseTypes)
          });
        else
          ctx.patchState({
            caseData: caseData,
            assignableTagTypes: this.getAssignableTagTypes(caseData, tagTypes, caseTypes)
          });
      }),
      take(1)
    );
  }

  /**
   * TODO: needs to be refactored to use partial
   * Patch passed state to server, and update state with response.
   * @param ctx
   * @param { caseData }
   * @returns {Observable<CaseData>}
   */
  @Action(CaseActions.Patch)
  patch(ctx: StateContext<CaseDataState>, { caseData }: CaseActions.Patch): Observable<CaseData> {
    return of(caseData).pipe(
      filterNullMap(),
      switchMap((changed: CaseData) => combineLatest([of(ctx.getState().caseData), of(changed)])),
      map(([original, changed]: [CaseData | null, CaseData]) => {
        return {
          caseId: changed.caseId,
          operations: createPatch(original, changed)
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) =>
        this.api
          .patch(caseOperations.caseId, caseOperations.operations)
          .pipe(map(() => caseOperations.caseId))
      ),
      mergeMap(caseId => this.api.getCase(caseId)),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      catchError(error => {
        ctx.patchState({ errors: parseError(error) });
        return throwError(error);
      })
    );
  }

  /**
   * Updates state only, does not save
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.UpdateState)
  updateState(ctx: StateContext<CaseDataState>, { caseData }: CaseActions.UpdateState) {
    ctx.patchState({
      caseData: caseData
    });
  }

  @Action(CaseActions.ResetState)
  resetState(ctx: StateContext<CaseDataState>) {
    ctx.patchState({
      caseData: null
    });
  }

  @Action(CaseActions.UpdateResponse)
  updateResponse(
    ctx: StateContext<CaseDataState>,
    { caseFile, caseResponse }: CaseActions.UpdateResponse
  ) {
    if (!caseFile) {
      throw Error("UpdateResponseAction.caseFile.required");
    }
    if (!caseResponse) {
      throw Error("UpdateResponseAction.caseResponse.required");
    }
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      map(caseData => {
        const index = caseData.responses.findIndex(r => r.responseId === caseResponse.responseId);
        let operations: Operation[] = [];
        if (index > -1) {
          operations = [
            { op: "replace", path: `/responses/${index}/document`, value: caseFile.url },
            { op: "add", path: "/documents/documentList/-", value: caseFile }
          ];
        }
        return {
          caseId: caseData.caseId,
          operations: operations
        };
      }),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
        if (caseOperations.operations.length > 0) {
          return this.api
            .patch(caseOperations.caseId, caseOperations.operations)
            .pipe(map(() => caseOperations.caseId));
        }
        return EMPTY;
      }),
      mergeMap(caseId => {
        return this.api.getCase(caseId);
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      })
    );
  }

  @Action(CaseActions.AcceptOrRejectCase)
  acceptCase(
    ctx: StateContext<CaseDataState>,
    { caseUser, loggedInUser, accept }: CaseActions.AcceptOrRejectCase
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      map(caseData => {
        const index = caseData.users.findIndex(
          u =>
            u.userId === caseUser.userId &&
            u.roleId === caseUser.roleId &&
            u.responsibilityFriendlyId === caseUser.responsibilityFriendlyId
        );
        let operations: Operation[] = [];
        if (index > -1) {
          operations = [
            {
              op: "replace",
              path: `/users/${index}/acceptanceStatus`,
              value: accept ? "accepted" : "rejected"
            },
            {
              op: "replace",
              path: `/users/${index}/acceptanceStatusLastUpdatedDateTime`,
              value: getUtcNow()
            },
            {
              op: "replace",
              path: `/users/${index}/lastUpdatedBy`,
              value: loggedInUser.email
            }
          ];
          return {
            caseId: caseData.caseId,
            operations: operations
          };
        }
        return null;
      }),
      filterNullMap(),
      switchMap((caseOperations: { caseId: string; operations: Operation[] }) => {
        if (caseOperations.operations.length > 0) {
          return this.api
            .patch(caseOperations.caseId, caseOperations.operations)
            .pipe(map(() => caseOperations.caseId));
        }
        return EMPTY;
      }),
      mergeMap(caseId => {
        return this.api.getCase(caseId);
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated this will go away when the behvior pipeline/patch stuff is implemented
   * This should just change the status on the case data and let the the api handle the transition logic
   * before applying the change to the case data. Ideally, at which point the state should be updated via
   * signal-r so the second get call should not be needed either.
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.UpdateStatus)
  updateStatus(ctx: StateContext<CaseDataState>, { statusId }: CaseActions.UpdateStatus) {
    const caseData = ctx.getState().caseData;
    if (!caseData) return EMPTY;

    return this.api.updateCaseStatus(caseData.caseId, statusId).pipe(
      mergeMap((success: boolean) => {
        if (success) {
          return this.api.getCase(caseData.caseId);
        }
        return EMPTY;
      }),
      tap(caseData => {
        ctx.patchState({ caseData });
      })
    );
  }

  @Action(CaseActions.SubmitCase)
  submitCase(ctx: StateContext<CaseDataState>) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.submitCase(caseData.caseId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.AddCaseServiceFee)
  addCaseService(
    ctx: StateContext<CaseDataState>,
    { caseServiceFee }: CaseActions.AddCaseServiceFee
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.addCaseService(caseData.caseId, caseServiceFee).pipe(
          mergeMap((response: boolean) => {
            if (response) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.EditCaseServiceFee)
  editCaseService(
    ctx: StateContext<CaseDataState>,
    { caseServiceFee }: CaseActions.EditCaseServiceFee
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.editCaseService(caseData.caseId, caseServiceFee).pipe(
          mergeMap((response: boolean) => {
            if (response) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.DeleteCaseServiceFee)
  deleteCaseService(
    ctx: StateContext<CaseDataState>,
    { serviceFeeId }: CaseActions.DeleteCaseServiceFee
  ) {
    const caseData: CaseData | null = ctx.getState().caseData;
    if (caseData) {
      const caseId: string = caseData.caseId;
      return this.api.deleteCaseService(caseId, serviceFeeId).pipe(
        mergeMap((response: boolean) => {
          if (response) {
            return this.api.getCase(caseId);
          }
          return EMPTY;
        }),
        tap((caseData: CaseData) => {
          ctx.patchState({ caseData });
        })
      );
    }
    return EMPTY;
  }

  /**
   * @deprecated we shouldn't be explicitly refreshing state like this from anywhere
   * the things that mutate should instead patch a value, and those should be emitted
   * automatically.
   * @param ctx
   * @param {caseServiceFee}
   */
  @Action(CaseActions.RefreshCurrent)
  refreshCurrent(ctx: StateContext<CaseDataState>) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) => this.api.getCase(caseData.caseId)),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param param1
   */
  @Action(CaseActions.RemoveGroup)
  removeGroup(ctx: StateContext<CaseDataState>, { groupId }: CaseActions.RemoveGroup) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.removeGroupFromCase(caseData.caseId, groupId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.DeleteResult)
  deleteResult(ctx: StateContext<CaseDataState>, { resultId }: CaseActions.DeleteResult) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.deleteResult(caseData.caseId, resultId).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return EMPTY;
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.UpdateResult)
  updateResult(
    ctx: StateContext<CaseDataState>,
    { caseResultData, finishLater }: CaseActions.UpdateResult
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.updateResult(caseData.caseId, caseResultData, finishLater).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return throwError("Update Result Failed.");
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  /**
   * @deprecated use case patch
   * @param ctx
   * @param param1
   * @returns
   */
  @Action(CaseActions.CreateResult)
  createResult(
    ctx: StateContext<CaseDataState>,
    { caseResultData, finishLater }: CaseActions.CreateResult
  ) {
    return of(ctx.getState().caseData).pipe(
      filterNullMap(),
      mergeMap((caseData: CaseData) =>
        this.api.createResult(caseData.caseId, caseResultData, finishLater).pipe(
          mergeMap((success: boolean) => {
            if (success) {
              return this.api.getCase(caseData.caseId);
            }
            return throwError("Create Result Failed.");
          })
        )
      ),
      tap(caseData => {
        ctx.patchState({ caseData });
      }),
      take(1)
    );
  }

  @Action(CaseActions.GetAssignableTags)
  getAssignableTags(ctx: StateContext<CaseDataState>): CaseActions.GetAssignableTags {
    return this.tagsApiService.getTags(true).pipe(
      tap((tags: Tag[]) => {
        ctx.patchState({ assignableTags: tags });
      })
    );
  }

  private getAssignableTagTypes(caseData: CaseData, tagTypes: TagType[], caseTypes: CaseType[]) {
    const selectedCaseType = caseTypes.find(ct => ct.caseTypeId === caseData.caseType.caseTypeId);
    if (selectedCaseType) {
      let assignableTagTypes = tagTypes;
      if (selectedCaseType.assignableTagTypes?.length > 0) {
        assignableTagTypes = tagTypes.filter(type =>
          selectedCaseType.assignableTagTypes.includes(type.friendlyId)
        );
      }
      return assignableTagTypes;
    }
    return [];
  }
}
