import { User } from "@vp/models";
import { UserOperations } from "../models/user-operations.model";

export class LoadUser {
  public static readonly type = "[User Admininistration] Load User";
  constructor(public user: Partial<User>, public active: boolean = true) {}
}

export class SetUser {
  public static readonly type = "[User Admininistration] Set User";
  constructor(public id: string) {}
}

export class ResetState {
  public static readonly type = "[User Admininistration] Reset State";
}

export class SetWorkingCopy {
  public static readonly type = "[User Admininistration] Set Working Copy";
  constructor(public user: User) {}
}

export class SetPendingOperations {
  public static readonly type = "[User Admininistration] Set Pending Operations";
  constructor(public userOperations: UserOperations) {}
}
