<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap=".5rem"
  *ngIf="items && items.length > 0"
>
  <span fxHide.lt-xl>Active Filters</span>
  <mat-icon fxHide.gt-lg matTooltip="Active Filters">filter_list</mat-icon>
  <mat-chip-list [selectable]="false">
    <mat-chip
      *ngFor="let item of items; trackBy: trackByValue"
      [removable]="true"
      [selectable]="false"
      [disableRipple]="true"
      (removed)="removedHandler(item)"
    >
      <mat-icon [matTooltip]="item.label">filter_alt</mat-icon>
      <div>{{ item.label | replaceString: "-":" " | titlecase }}</div>
      <mat-icon matChipRemove matTooltip="Clear this filter">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <button mat-icon-button matTooltip="Clear all filters" (click)="clearAllHandler()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
