import { ApplicationStateModel } from "./application.state";

export class PatchState {
  public static readonly type = "[applicationState] Patch State";
  constructor(public partialState: Partial<ApplicationStateModel>) {}
}

export class ResetState {
  public static readonly type = "[applicationState] Reset State";
}
