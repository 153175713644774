import { Injectable, OnDestroy } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { TagsState } from "@vp/data-access/tags";
import { Tag, TagType } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { compare, deeperCopy } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

export type TagSelectorStateModel = {
  selectors: TagSelector[];
};

export interface TagSelector {
  tagType: TagType;
  selectedTag?: Tag | undefined | null;
  tags: Tag[];
  fixed: boolean;
}

/**
 * I would have liked to have used an ngxs state for this but the store is currently
 * a singleton, so would not work if there were two instances of this on the screen
 */
@Injectable()
export class TagSelectorContext implements OnDestroy {
  @Select(OrganizationState.tagTypes) tagTypes$!: Observable<TagType[]>;
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;

  private readonly tagSelectors$ = new BehaviorSubject<TagSelectorStateModel>({
    selectors: []
  });

  private readonly destroy$ = new Subject();

  constructor(private readonly store: Store) {}

  availableTagsFor$ = (tagTypeFriendlyId: string) =>
    this.tagSelectors$.pipe(
      map(state => [...state.selectors]),
      filterNullMap(),
      map((selectors: TagSelector[]) => {
        const selector = selectors.find(s => s.tagType.friendlyId === tagTypeFriendlyId);
        if (!selector) return [];
        /**
         * IF the tag type has a parent AND the tag selectors array has a selector defined
         * for that tag type then filter the tags by the selected tag. Otherwise return an empty
         * array as the selector should be blank if no parent tag is selected or found
         */
        if (selector?.tagType.tagTypeFriendlyPathId) {
          const pathParts = selector.tagType.tagTypeFriendlyPathId.split(".");
          const parentSelector = selectors.find(
            s => s.tagType.friendlyId === pathParts[pathParts.length - 1]
          );
          if (parentSelector?.selectedTag) {
            return selector.tags
              .filter(tag => {
                const pathParts = tag.tagPath.split(".");
                return pathParts[pathParts.length - 1] === parentSelector?.selectedTag?.tagId;
              })
              .sort((a, b) => compare(a.displayName, b.displayName, true));
          }
          return [];
        }
        /**
         * IF the tag type has no parent OR the tag selectors has no selector defined for
         * the parent then simply return the tags filtered by the tag type friendly id.
         */
        return selector.tags.sort((a, b) => compare(a.displayName, b.displayName, true));
      })
    );

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addSelector = (tagTypeFriendlyId: string, selectedTagId: string | null) => {
    const tagType = this.store
      .selectSnapshot(OrganizationState.tagTypes)
      .find(tagType => tagType.friendlyId === tagTypeFriendlyId);

    if (!tagType) return;

    const tagsOfType = this.store
      .selectSnapshot(TagsState.tags)
      .filter(tag => tag.tagTypeFriendlyId === tagTypeFriendlyId);
    const selected = tagsOfType.find(t => t.tagId === selectedTagId);

    const currentState = deeperCopy(this.tagSelectors$.getValue());
    currentState.selectors.push({
      tagType: tagType,
      selectedTag: selected ?? null,
      tags: tagsOfType,
      fixed: !!selected ?? false
    });
    this.tagSelectors$.next(currentState);
  };

  selectTagFor = (tagTypeFriendlyId: string, tagId: string) => {
    const currentState: TagSelectorStateModel = deeperCopy(this.tagSelectors$.getValue());
    const currentSelector = currentState.selectors.find(
      s => s.tagType.friendlyId == tagTypeFriendlyId
    );
    const tag = this.store.selectSnapshot(TagsState.tags).find(tag => tag.tagId === tagId);
    if (currentSelector) {
      currentSelector.selectedTag = tag;
      currentState.selectors.forEach(selector => {
        if (selector.tagType.friendlyId === currentSelector.tagType.friendlyId) return;
        if (
          selector.tagType.tagTypeFriendlyPathId &&
          selector.tagType.tagTypeFriendlyPathId
            .split(".")
            .includes(currentSelector.tagType.friendlyId)
        ) {
          selector.selectedTag = null;
        }
      });
      this.tagSelectors$.next(currentState);
    }
  };

  isFixed(friendlyId: string) {
    const currentState: TagSelectorStateModel = this.tagSelectors$.getValue();
    const selector = currentState.selectors.find(s => s.tagType.friendlyId === friendlyId);
    return selector?.fixed || false;
  }
}
