import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CaseApiService } from "@vp/data-access/case";
import { CaseSearchSummary, PageResult } from "@vp/models";
import { tap } from "rxjs/operators";
import { CaseSearchFilters } from "../model/case-search-filters";
import * as CaseSearchStateActions from "./case-search.actions";

export interface CaseSearchStateModel {
  filter: Partial<CaseSearchFilters>;
  pageResult: PageResult<CaseSearchSummary>;
}

@State<CaseSearchStateModel>({
  name: "caseSearch",
  defaults: {
    filter: {
      take: 10,
      skip: 0
    },
    pageResult: {
      totalRecords: 0,
      results: [],
      pageCount: 0,
      pageSize: 0,
      lastPage: 0
    }
  }
})
@Injectable()
export class CaseSearchState {
  constructor(private caseApiService: CaseApiService) {}

  @Selector()
  public static currentState(state: CaseSearchStateModel) {
    return state;
  }

  @Selector()
  static pageResult(state: CaseSearchStateModel): PageResult<CaseSearchSummary> {
    return state.pageResult;
  }

  @Selector()
  static currentFilter(state: CaseSearchStateModel): Partial<CaseSearchFilters> {
    return state.filter;
  }

  @Action(CaseSearchStateActions.SetFilter)
  setFilter(ctx: StateContext<CaseSearchStateModel>, { filter }: CaseSearchStateActions.SetFilter) {
    ctx.patchState({ filter: filter });
  }

  @Action(CaseSearchStateActions.GetFiltered, { cancelUncompleted: true })
  getFiltered(ctx: StateContext<CaseSearchStateModel>) {
    const state = ctx.getState();
    const filter = state.filter;
    return this.caseApiService
      .searchCases(
        filter.take,
        filter.skip,
        filter.status,
        filter.caseType,
        filter.department,
        filter.search,
        filter.unread,
        filter.unresolved,
        filter.tagIds ?? []
      )
      .pipe(
        tap((pageResult: PageResult<CaseSearchSummary>) => {
          ctx.patchState({ pageResult: pageResult });
        })
      );
  }
}
