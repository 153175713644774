import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { RealTimeNotification } from "@vp/models";
import { Observable } from "rxjs";
import { pairwise, tap } from "rxjs/operators";
import { SignalRApiService } from "./signal-r-api.service";
import * as SignalRStateActions from "./state/signal-r-state.actions";
import { SignalRState } from "./state/signal-r.state";

@Injectable()
export class SignalREventService {
  @Select(SignalRState.notifications) notifications$!: Observable<RealTimeNotification[]>;

  constructor(
    private readonly store: Store,
    private readonly signalRApiService: SignalRApiService
  ) {}

  listen = () => {
    this.notifications$
      .pipe(
        pairwise(),
        tap(([previous, current]) => {
          const previousMap = new Map<string, RealTimeNotification>();
          previous.forEach((n: RealTimeNotification) =>
            previousMap.set(`${n.userId}-${n.groupName}`, n)
          );
          const toAdd: RealTimeNotification[] = [];
          current.forEach((n: RealTimeNotification) => {
            const key = `${n.userId}-${n.groupName}`;
            if (!previousMap.has(key)) {
              toAdd.push(n);
            } else {
              previousMap.delete(key);
            }
          });
          const toRemove = Array.from(previousMap.values());
          if (toRemove.length > 0) {
            this.signalRApiService.removeManyFromGroup(toRemove).subscribe();
          }
          if (toAdd.length > 0) {
            this.signalRApiService.addManyToGroup(toAdd).subscribe();
          }
        })
      )
      .subscribe();
  };

  public clearSubscriptions() {
    this.store.dispatch(
      new SignalRStateActions.SetState({
        notifications: []
      })
    );
  }
}
