import { CaseSearchFilters } from "../model/case-search-filters";

export class SetFilter {
  public static readonly type = "[Case Search Summary] Set Filter";
  constructor(public filter: Partial<CaseSearchFilters>) {}
}

export class GetFiltered {
  public static readonly type = "[Case Search Summary] Get Filtered";
}
