import { AfterViewInit, ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AngularEditorComponent, AngularEditorConfig } from "@kolkov/angular-editor";
import { FieldType } from "@ngx-formly/core";

import { Snippet, User } from "@vp/models";
import { NotificationService } from "@vp/shared/notification";
import { AppStoreService } from "@vp/shared/store/app";
import { ANGULAR_EDITOR_CONFIG } from "@vp/shared/tokens";
import { AddOrEditSnippetDialogComponent } from "@vp/user-snippets/ui/add-or-edit-snippet-dialog";
import { BehaviorSubject, EMPTY, of } from "rxjs";
import { concatMap, switchMap, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "lib-formly-rich-text-type",
  templateUrl: "./formly-rich-text-type.component.html",
  styleUrls: ["./formly-rich-text-type.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRichTextTypeComponent extends FieldType implements AfterViewInit {
  formControl!: FormControl;
  config: AngularEditorConfig;
  selectedSnippet: Snippet | undefined = undefined;
  snippets: Snippet[] = [];
  cursorPosition: Range | null = null;

  private readonly _disabled = new BehaviorSubject<boolean>(false);

  public disabled = this._disabled.asObservable();

  constructor(
    @Inject(ANGULAR_EDITOR_CONFIG) public defaultEditorConfig: AngularEditorConfig,
    private readonly dialog: MatDialog,
    private appStore: AppStoreService,
    private notificationService: NotificationService
  ) {
    super();
    this.config = { ...defaultEditorConfig };
    this.config.minHeight = "100px";
    this.config.maxHeight = "200px";
    this.appStore.user$.subscribe(user => {
      this.snippets = (user.userData?.snippets as Snippet[]) || [];
    });
  }
  ngAfterViewInit(): void {
    this._disabled.next(!!this.formState.disabled);
    this.form.statusChanges.subscribe(() => {
      this._disabled.next(this.formState.disabled);
    });
  }

  saveSelection() {
    const sel = window.getSelection();
    if (sel?.getRangeAt && sel.rangeCount) {
      this.cursorPosition = sel.getRangeAt(0);
    }
  }

  showSnippet() {
    const show = this.to?.showSnippet ?? false;
    return show;
  }

  insertText(editor: AngularEditorComponent) {
    if (this.selectedSnippet && this.cursorPosition) {
      const node = document.createRange().createContextualFragment(this.selectedSnippet.text);
      this.cursorPosition.insertNode(node as Node);
      editor.onContentChange(editor.textArea.nativeElement);
    }
  }

  addNewSnippet() {
    const dialogRef = this.dialog.open(AddOrEditSnippetDialogComponent, {
      width: "500px",
      data: { action: "Add" }
    });

    dialogRef
      .afterClosed()
      .pipe(
        withLatestFrom(this.appStore.user$),
        switchMap(([dialogResult, user]: [Snippet, User]) => {
          if (dialogResult) {
            return of({
              ...user,
              userData: {
                ...user.userData,
                snippets: this.snippets.concat(dialogResult)
              }
            });
          }
          return EMPTY;
        }),
        concatMap((user: User) => {
          return this.appStore.patchUser(user);
        })
      )
      .subscribe(updatedUser => {
        this.snippets = (updatedUser.userData?.snippets as Snippet[]) || [];
        this.notificationService.successMessage("Snippet Added");
      });
  }
}
