import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CaseData, CASETYPE_STATUS_CATEGORY__ALL } from "@vp/models";
import { API_BASE_URL } from "@vp/shared/tokens";
import { Operation } from "rfc6902";
import { Observable, throwError } from "rxjs";
import { CaseDataFilter } from "../models/case-data-filter";

@Injectable({
  providedIn: "root"
})
export class CaseFilterApiService {
  constructor(
    @Inject(API_BASE_URL) private apiBaseUrl: string,
    private readonly _http: HttpClient
  ) {}

  filteredCases = (
    filter: Readonly<Partial<CaseDataFilter>>,
    feature: string | undefined
  ): Observable<HttpResponse<CaseData[]>> => {
    if (!feature) {
      return throwError("Feature is required");
    }

    let apiURL = `${this.apiBaseUrl}/cases/?take=${filter.take}&skip=${filter.skip}`;

    if (filter.status && filter.status !== CASETYPE_STATUS_CATEGORY__ALL.friendlyId) {
      apiURL = apiURL + `&filter=status.friendlyId=${filter.status}`;
    }

    if (filter.caseTypeIds) {
      for (let i = 0; i < filter.caseTypeIds.length; i++) {
        apiURL = apiURL + `&filter=caseType.caseTypeId=${filter.caseTypeIds[i]}`;
      }
    }

    if (filter.groupIds) {
      for (let i = 0; i < filter.groupIds.length; i++) {
        apiURL = apiURL + `&filter=groups.groupId=${filter.groupIds[i]}`;
      }
    }

    if (filter.departmentIds) {
      for (let i = 0; i < filter.departmentIds.length; i++) {
        apiURL = apiURL + `&filter=department.departmentId=${filter.departmentIds[i]}`;
      }
    }

    if (filter.rangeField && filter.startDate) {
      apiURL = apiURL + `&range=${filter.rangeField}>=${filter.startDate}`;
    }

    if (filter.rangeField && filter.endDate) {
      apiURL = apiURL + `&range=${filter.rangeField}<=${filter.endDate}`;
    }

    if (filter.search) {
      apiURL = apiURL + `&search=${filter.search}`;
    }
    if (filter.sort) {
      apiURL = apiURL + `&sort=${filter.sort}`;
    }
    if (filter.sortDir) {
      apiURL = apiURL + `&sortDir=${filter.sortDir}`;
    }
    if (filter.unassigned !== undefined) {
      apiURL = apiURL + `&unassigned=${filter.unassigned}`;
    }
    if (filter.completed !== undefined) {
      apiURL = apiURL + `&completed=${filter.completed}`;
    }
    if (filter.cancelled !== undefined) {
      apiURL = apiURL + `&cancelled=${filter.cancelled}`;
    }
    if (filter.archived !== undefined) {
      apiURL = apiURL + `&archived=${filter.archived}`;
    }

    if (filter.unresolved !== null && filter.unresolved !== undefined) {
      apiURL += `&unresolved=${filter.unresolved}`;
    }

    if (filter.unread !== null && filter.unread !== undefined) {
      apiURL += `&unread=${filter.unread}`;
    }

    if (filter.tagIds && filter.tagIds?.length > 0) {
      filter.tagIds.forEach((tagId: string) => (apiURL += `&tag=${tagId}`));
    }
    if (filter.recordData && filter.recordData?.length > 0) {
      filter.recordData.forEach((param: string) => (apiURL += `&recordData=${param}`));
    }

    return this._http.get<CaseData[]>(apiURL, {
      observe: "response"
    });
  };

  getCase(caseId: string, caseTypeId: string | null = null): Observable<CaseData> {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}`;
    let params = new HttpParams();
    if (caseTypeId) {
      params = params.append("caseTypeId", String(caseTypeId));
    }
    return this._http.get<CaseData>(apiURL, {
      params: params
    });
  }

  patch = (caseId: string, operations: Operation[]) => {
    const apiURL = `${this.apiBaseUrl}/case/${caseId}`;
    return this._http.patch(apiURL, operations);
  };
}
