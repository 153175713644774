import { Injectable } from "@angular/core";
import { defaultDisplayTags, DisplayTag } from "@vp/models";
import { deeperCopy } from "@vp/shared/utilities";

@Injectable({
  providedIn: "root"
})
export class UiDisplayTagService {
  displayTags!: Record<string, string>;

  constructor() {
    this.displayTags = deeperCopy(defaultDisplayTags);
  }

  customizeDisplayTagsFromOrganization(organizationDisplayTags: DisplayTag[]) {
    organizationDisplayTags.forEach(displayTag => {
      this.displayTags[displayTag.tag] = displayTag.display;
    });
  }
}
