import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { UserAssignRolesModule } from "@vp/administration/ui/user-assign-roles";
import { GenericDialogModule } from "@vp/shared/components/generic-dialog";
import { MaterialModule } from "@vp/shared/material";
import { UtilitiesModule } from "@vp/shared/utilities";
import { NgxPermissionsModule } from "ngx-permissions";

import { UserAssignedRolesComponent } from "./user-assigned-roles.component";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    GenericDialogModule,
    MaterialModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgxPermissionsModule,
    UserAssignRolesModule,
    UtilitiesModule
  ],
  exports: [UserAssignedRolesComponent],
  declarations: [UserAssignedRolesComponent]
})
export class UserAssignedRolesModule {}
