import { EventBase } from "@vp/shared/event-aggregator";

export interface ZoomWebhookEventArgs {
  applicationHostId: string;
  topic: string;
  startTime?: string;
  endTime?: string;
}

export class ZoomWebhookEvent extends EventBase<ZoomWebhookEventArgs> {
  constructor(args: ZoomWebhookEventArgs) {
    super();
    this.args = args;
  }
}
