import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "@vp/shared/material";
import { ReplaceStringModule } from "@vp/shared/pipes/replace-string";
import { FilterToolbarComponent } from "./filter-toolbar.component";

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MaterialModule, ReplaceStringModule],
  declarations: [FilterToolbarComponent],
  exports: [FilterToolbarComponent]
})
export class FilterToolbarModule {}

export interface FilterToolbarItem {
  value: string;
  label: string;
}
