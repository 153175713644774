import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { append, patch, removeItem, updateItem } from "@ngxs/store/operators";
import { PageState, User, UserRole } from "@vp/models";
import { Logger } from "@vp/shared/logging-service";
import { filterNullMap } from "@vp/shared/operators";
import { cleanUnused, mergeDeep, parseError } from "@vp/shared/utilities";
import { throwError } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";
import { UserApiService } from "../api/user-api.service";
import { defaultUserFilter, UserFilter } from "../models/user-filter";
import { UserStateModel } from "../models/user-state.model";
import * as UserStateActions from "./user-state.actions";

const defaultState = {
  filter: defaultUserFilter(),
  users: [],
  currentUser: null,
  errors: [],
  pageState: {
    totalRecords: 0,
    pageIndex: 0,
    pageCount: 0,
    pageSize: 25,
    lastPage: 1
  }
};

@State<UserStateModel>({
  name: "user",
  defaults: defaultState
})
@Injectable()
export class UserState {
  constructor(
    private readonly logger: Logger,
    private readonly api: UserApiService,
    private readonly store: Store
  ) {}

  @Selector()
  public static currentUser(state: UserStateModel) {
    return state.currentUser;
  }

  @Selector()
  public static currentUserRole(state: UserStateModel) {
    return state.currentUser?.roles.find(
      (userRole: UserRole) => userRole.roleId === state.currentUser?.selectedRoleId
    );
  }

  @Selector()
  public static getCurrentFilter(state: UserStateModel) {
    return state.filter;
  }

  @Selector([UserState.getCurrentFilter])
  public static currentFilter(filter: Partial<UserFilter>) {
    return filter;
  }

  @Selector()
  public static getUsers(state: UserStateModel) {
    return state.users;
  }

  @Selector([UserState.getUsers])
  public static users(users: User[]) {
    return users;
  }

  @Selector()
  public static getUserFn(state: UserStateModel) {
    return (userId: string) => state.users.find(user => user.userId === userId);
  }

  @Selector()
  static getPageState(state: UserStateModel): Partial<PageState> {
    return state.pageState;
  }

  @Selector([UserState.getPageState])
  static pageState(pageState: PageState): Partial<PageState> {
    return pageState;
  }

  @Selector()
  public static getErrors(state: UserStateModel) {
    return state.errors;
  }

  @Selector([UserState.getErrors])
  public static errors(errors: never[]) {
    return errors;
  }

  @Action(UserStateActions.PatchState)
  patchState(ctx: StateContext<UserStateModel>, { userStateModel }: UserStateActions.PatchState) {
    ctx.patchState(userStateModel);
  }

  @Action(UserStateActions.ResetState)
  resetState(ctx: StateContext<UserStateModel>) {
    ctx.setState(defaultState);
  }

  /**
   * Retrieves a user by its userId from the server and sets the state with the response
   */
  @Action(UserStateActions.SetCurrentUser)
  setCurrentUser(ctx: StateContext<UserStateModel>, { userId }: UserStateActions.SetCurrentUser) {
    return this.api.getUser(userId, false).pipe(
      tap(user => {
        ctx.patchState({ currentUser: user });
      }),
      catchError(error => {
        ctx.patchState({
          currentUser: null,
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(UserStateActions.SetCurrentUserRole)
  setCurrentUserRole(
    ctx: StateContext<UserStateModel>,
    { roleId }: UserStateActions.SetCurrentUserRole
  ) {
    let user: User | null = ctx.getState().currentUser;
    if (user === null) {
      const error: Error = {
        name: "CurrentUser null exception",
        message: "Current user not set in user state"
      };
      this.logger.logException(error, "UserStateActions.SetCurrentUserRole");
    } else {
      user = { ...user, selectedRoleId: roleId };
      ctx.patchState({ currentUser: user });
    }
  }

  @Action(UserStateActions.SetFilter)
  setFilter(ctx: StateContext<UserStateModel>, actions: UserStateActions.SetFilter) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        filter: mergeDeep(state.filter, actions.filter, actions.arrayAction)
      })
    );
  }

  @Action(UserStateActions.SetPageState)
  setPageState(ctx: StateContext<UserStateModel>, actions: UserStateModel) {
    const take = actions.pageState.pageSize ?? 25;
    const skip =
      actions.pageState.pageSize && actions.pageState.pageIndex
        ? actions.pageState?.pageSize * actions.pageState.pageIndex
        : 0;
    ctx.setState(
      patch<UserStateModel>({
        pageState: patch<PageState>(actions.pageState),
        filter: patch<UserFilter>({
          take: take,
          skip: skip
        })
      })
    );
    return this.store.dispatch(new UserStateActions.GetFiltered());
  }

  @Action(UserStateActions.GetFiltered)
  getFiltered(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    const clean = cleanUnused(state.filter);
    return this.api.getUsersPageResult(clean).pipe(
      tap(pageResult => {
        ctx.setState(
          patch({
            users: pageResult.results,
            pageState: patch({
              totalRecords: pageResult.totalRecords
            })
          })
        );
      }),
      catchError(error => {
        ctx.patchState({
          users: [],
          errors: parseError(error)
        });
        return throwError(error);
      })
    );
  }

  @Action(UserStateActions.AddUser)
  addUser(ctx: StateContext<UserStateModel>, action: UserStateActions.AddUser) {
    return this.api.getUser(action.userId, false).pipe(
      filterNullMap(),
      tap((user: User) => {
        ctx.setState(
          patch({
            users: append([user])
          })
        );
      }),
      catchError(error => {
        ctx.patchState({
          errors: parseError(error)
        });
        return throwError(error);
      }),
      take(1)
    );
  }

  @Action(UserStateActions.GetUser)
  getUser(ctx: StateContext<UserStateModel>, action: UserStateActions.GetUser) {
    const state = ctx.getState();
    const user = state.users.find(user => user.userId === action.userId);
    if (!user) {
      this.store.dispatch(new UserStateActions.AddUser(action.userId));
    }
  }

  @Action(UserStateActions.UpdateUser)
  updateUser(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateUser) {
    ctx.setState(
      patch({
        users: updateItem<User>(
          u => u?.userId === action.userId,
          user => mergeDeep(user, action.user, "merge")
        )
      })
    );
  }

  @Action(UserStateActions.DeleteUser)
  deleteUser(ctx: StateContext<UserStateModel>, action: UserStateActions.DeleteUser) {
    ctx.setState(
      patch({
        users: removeItem<User>(user => user?.userId === action.userId)
      })
    );
  }
}
