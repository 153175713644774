import { DeviceCamera } from "@vp/models";
import { EventBase } from "@vp/shared/event-aggregator";
export interface DeviceCamerasNotificationEventArgs {
  deviceUserId: string;
  deviceCameras: DeviceCamera[];
}

export class DeviceCamerasUpdatedEvent extends EventBase<DeviceCamerasNotificationEventArgs> {
  constructor(notification: DeviceCamerasNotificationEventArgs) {
    super();
    this.args = notification;
  }
}
