import {
  CaseData,
  CaseFile,
  CaseResponse,
  CaseResultData,
  CaseServiceFee,
  CaseUser,
  User
} from "@vp/models";

export class SetState {
  public static readonly type = "[Case] Set Case Context";
  constructor(public caseId: string) {}
}

export class SetNewState {
  public static readonly type = "[Case] Set Empty Case";
  constructor(public caseTypeId: string, public subjectUserId: string | null = null) {}
}

export class UpdateState {
  public static readonly type = "[Case] Update Current";
  constructor(public caseData: CaseData) {}
}

export class ResetState {
  public static readonly type = "[Case] Reset Current";
}

export class Patch {
  public static readonly type = "[Case] Update";
  constructor(public caseData: CaseData) {}
}

export class UpdateResponse {
  public static readonly type = "[Case] Update Response";
  constructor(public caseFile: CaseFile, public caseResponse: CaseResponse) {}
}

export class UpdateStatus {
  public static readonly type = "[Case] Update Status";
  constructor(public statusId: string) {}
}

export class SubmitCase {
  public static readonly type = "[Case] Submit Case";
}

export class AcceptOrRejectCase {
  public static readonly type = "[Case] Accept Case";
  constructor(public caseUser: CaseUser, public loggedInUser: User, public accept: boolean) {}
}

export class AddCaseServiceFee {
  public static readonly type = "[Case] Add Case Service Fee";
  constructor(public caseServiceFee: CaseServiceFee) {}
}

export class EditCaseServiceFee {
  public static readonly type = "[Case] Edit Case Service Fee";
  constructor(public caseServiceFee: CaseServiceFee) {}
}

export class DeleteCaseServiceFee {
  public static readonly type = "[Case] Delete Service Fee";
  constructor(public serviceFeeId: string) {}
}

/**
 * @deprecated
 */
export class RefreshCurrent {
  public static readonly type = "[Case] Refresh Current";
}

export class RemoveGroup {
  public static readonly type = "[Case] Remove Group";
  constructor(public groupId: string) {}
}

export class CreateResult {
  public static readonly type = "[Case] Create Result";
  constructor(
    public caseResultData: Partial<CaseResultData>,
    public finishLater: boolean = false
  ) {}
}

export class UpdateResult {
  public static readonly type = "[Case] Update Result";
  constructor(public caseResultData: CaseResultData, public finishLater: boolean = false) {}
}

export class DeleteResult {
  public static readonly type = "[Case] Delete Result";
  constructor(public resultId: string) {}
}

export class GetAssignableTags {
  public static readonly type = "[Case] Get Assignable Tags";
}
