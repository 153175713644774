import { Pipe, PipeTransform } from "@angular/core";
import { UserApiService } from "@vp/data-access/users";
import { User } from "@vp/models";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
  name: "userName"
})
export class UserNamePipe implements PipeTransform {
  constructor(private userApiService: UserApiService) {}

  transform(userId: string): Observable<string> {
    if (userId != null) {
      return this.userApiService.getUser(userId, true).pipe(
        map((user: User | null) => {
          if (user) {
            if (!user.profile.firstName && !user.profile.lastName) {
              return user.email;
            }
            return `${user.profile.firstName} ${user.profile.lastName}`;
          }
          return `[user ${userId} not found]`;
        })
      );
    }
    return of("[user not found]");
  }
}
