import { Component, Inject, TemplateRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-dialog",
  styleUrls: ["./generic-dialog.component.scss"],
  template: `
    <ng-container
      [ngTemplateOutlet]="templateRef"
      [ngTemplateOutletContext]="outletContext"
    ></ng-container>
  `
})
export class DialogComponent<T> {
  headerText!: string;
  outletContext!: Record<string, unknown> | null;
  templateRef!: TemplateRef<any> | null;
  /**
   * Initializes the component.
   *
   * @param dialogRef - A reference to the dialog opened.
   */
  constructor(
    public dialogRef: MatDialogRef<DialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      headerText: string;
      template: TemplateRef<any>;
      context: T;
    }
  ) {
    this.headerText = data.headerText;
    this.templateRef = data.template;
    this.outletContext = data.context as Record<string, unknown>;
  }
}
