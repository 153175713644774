/* eslint-disable @typescript-eslint/no-explicit-any */
import { throwError } from "rxjs";

export function formatError(error: any) {
  return throwError(error.message);
}

export class UrlBuilder {
  constructor(public baseUrl: string, public keys: string[] = []) {}

  build(routeParameters: { [key: string]: any }, queryParameters: { [key: string]: any }): string {
    return this.cleanUnused(this.buildFromParams(routeParameters, queryParameters));
  }

  buildUrlWithQueryParameters(queryParams: { [key: string]: any }) {
    return this.build({}, queryParams);
  }

  buildFromParams(
    routeParams: { [key: string]: any },
    queryParams: { [key: string]: any }
  ): string {
    const builtUrl = Object.keys(routeParams).reduce(
      (url, key) =>
        url.replace(new RegExp(`/{${key}}`), routeParams[key] ? `/${routeParams[key]}` : ""),
      this.baseUrl
    );
    const queryString = this.buildQueryString(queryParams);
    if (queryString.length > 0) {
      return `${builtUrl}?${queryString}`;
    }
    return builtUrl;
  }

  buildQueryString(query: { [key: string]: any }) {
    return Object.keys(query)
      .filter(key => query[key] !== undefined && query[key] !== null)
      .map(key => {
        const arrayParts: string[] = [];
        const value = query[key];
        if (Array.isArray(value)) {
          value.forEach(v => {
            arrayParts.push(`${key}=${v}`);
          });
          return arrayParts.join("&");
        } else {
          return `${key}=${query[key]}`;
        }
      })
      .join("&");
  }

  cleanUnused(url: string): string {
    const urlObj = new URL(url);
    for (const [key, value] of urlObj.searchParams.entries()) {
      if (!value) {
        urlObj.searchParams.delete(key);
      }
    }
    return urlObj.toString();
  }
}

export function createUrlBuilder(url: string, keys?: string[]): UrlBuilder {
  return new UrlBuilder(url, keys);
}
