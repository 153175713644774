import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { NavigationItem } from "@vp/shared/top-nav/data-access/top-nav-state";

import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "vp-top-nav-list",
  templateUrl: "./top-nav-list.component.html",
  styleUrls: ["./top-nav-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopNavListComponent implements OnInit, OnChanges {
  @Input() menuItems: NavigationItem[] = [];
  @Input() menuOption: boolean | undefined;

  private menuItemsSubject = new BehaviorSubject<NavigationItem[]>([]);

  constructor(
    public ngxPermissionsService: NgxPermissionsService,
    public breakpointObserver: BreakpointObserver
  ) {}

  screenSize$ = this.breakpointObserver
    .observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ])
    .pipe(
      map((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XLarge]) {
          return ScreenSize.Desktop;
        } else if (state.breakpoints[Breakpoints.Medium] || state.breakpoints[Breakpoints.Large]) {
          return ScreenSize.MediumDevice;
        } else if (state.breakpoints[Breakpoints.Small]) {
          return ScreenSize.Tablet;
        } else if (state.breakpoints[Breakpoints.XSmall]) {
          return ScreenSize.Mobile;
        }
        return ScreenSize.Desktop;
      })
    );

  menuItems$ = combineLatest([this.menuItemsSubject, this.screenSize$]).pipe(
    map(([items, screenSize]: [NavigationItem[], string]) => {
      switch (screenSize) {
        case ScreenSize.Desktop: {
          return items.slice(0, 7);
        }
        case ScreenSize.MediumDevice: {
          return items.slice(0, 3);
        }
        default: {
          return [];
        }
      }
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    this.menuItemsSubject.next(changes.menuItems.currentValue);
  }

  ngOnInit(): void {
    this.menuItemsSubject.next(this.menuItems);
  }
}

enum ScreenSize {
  Desktop = "desktop",
  MediumDevice = "mediumDevice",
  Tablet = "tablet",
  Mobile = "mobile"
}
