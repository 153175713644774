import { ThemeManagerConfig } from "@vp/theme-manager";

export const appThemeManagerConfig: ThemeManagerConfig = {
  debug: {
    hostname: undefined
  },
  hostnames: {
    localhost: {
      title: "Breakout",
      metas: [{ name: "author", content: "Valore Partners" }],
      bodyClass: "theme-breakout",
      styles: ["libs-theme-breakout-styles.css", "libs-theme-breakout-badge-localhost.css"],
      logoUrl: "assets/libs-theme-breakout/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-breakout/logo.png"
    },
    "default-theme": {
      title: "Mercury",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "breakout-dev.azureedge.net": {
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg"
    },
    "breakout-tst.azureedge.net": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "breakout-demo1.azureedge.net": {
      title: "Anonymous Ivy Site",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "second-opinion-dev.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "second-opinion-qa.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "second-opinion-prd.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "app.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "bcn.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "nso.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso-styles.css"],
      logoUrl: "assets/libs-theme-nso/logo.png",
      logoRedirectUrl: "/cases",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "virtualcare-dev.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-vcdp-spa-dev.azureedge.net": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-vcdp-spa-qa.azureedge.net": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "virtualcare-qa.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "virtualcare-stg.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-stg" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-vcdp-spa-stg.azureedge.net": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-stg" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "virtualcare.commonspirit.org": {
      title: "Virtual Care Delivery",
      metas: [{ name: "application-name", content: "virtual-care-delivery-prod" }],
      bodyClass: "theme-vsd",
      styles: [],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "ivy.secondopinion.barrowneuro.org": {
      title: "Second Opinion Request Portal",
      metas: [],
      bodyClass: "theme-nso",
      styles: ["libs-theme-nso/styles.css"],
      logoUrl: "assets/ivy/ivylogo.png",
      faviconUrl: "assets/libs-theme-nso/favicon.png"
    },
    "cdne-w1-2nd-op-int-spa-dev.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-2nd-op-int-spa-qa.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-2nd-op-int-prd.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-2nd-op-int-spa-prd.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remotesecondopinion.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remotesecondopinion.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remotesecondopinion-dev.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remotesecondopinion-qa.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remote-second-opinion-qa.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remote-second-opinion-dev.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-2nd-op-int-stg.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remote-second-opinion-stg.commonspirit.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-2nd-op-int-spa-stg.azureedge.net": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "remotesecondopinion-stg.dignityhealthinternational.org": {
      title: "Second Opinion International Request Portal",
      metas: [],
      bodyClass: "theme-int",
      styles: ["libs-theme-int-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/CommonSpirit/internationalLogo.png",
      faviconUrl: "assets/libs-theme-breakout/favicon.ico"
    },
    "cdne-w1-ref-mgmt-dev.azureedge.net": {
      title: "Referral Management",
      metas: [{ name: "application-name", content: "referral-management-dev" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-ref-mgmt-qa.azureedge.net": {
      title: "Referral Management",
      metas: [{ name: "application-name", content: "referral-management-qa" }],
      bodyClass: "theme-vsd",
      styles: ["libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-2nd-op-dom-spa-dev.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-dev.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "mysecondopinion-dev.commonspirit.org": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-dev.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-spa-qa.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-qa.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "mysecondopinion-qa.commonspirit.org": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-qa.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "mysecondopinion-stg.commonspirit.org": {
      title: "My Second Opinion",
      metas: [],
      bodyClass: "theme-csh",
      styles: ["libs-theme-csh-styles.css", "libs-theme-breakout-badge-demo.css"],
      logoUrl: "assets/libs-theme-com/logo.svg",
      faviconUrl: "assets/libs-theme-com/favicon.ico"
    },
    "cdne-w1-2nd-op-dom-stg.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-spa-stg.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css", "libs-theme-breakout-badge-tst.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "mysecondopinion.commonspirit.org": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-prd.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    },
    "cdne-w1-2nd-op-dom-spa-prd.azureedge.net": {
      title: "VMFH Peer Opinion",
      metas: [],
      bodyClass: "theme-dom",
      styles: ["libs-theme-dom-styles.css"],
      logoUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_RGB.png",
      faviconUrl: "assets/templates/SOD/VirginiaMason/VMFH_PrimaryLogo_hrz_fullcolor_CMYK.jpg"
    }
  }
};
