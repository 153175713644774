import { PageState, User } from "@vp/models";
import { ArrayAction } from "@vp/shared/utilities";
import { UserFilter } from "../models/user-filter";
import { UserStateModel } from "../models/user-state.model";

export class PatchState {
  public static readonly type = "[Users] User State Model";
  constructor(public userStateModel: Partial<UserStateModel>) {}
}

export class SetCurrentUser {
  public static readonly type = "[Users] Set Current User";
  constructor(public userId: string) {}
}

export class SetCurrentUserRole {
  public static readonly type = "[Users] Set Current UserRole";
  constructor(public roleId: string) {}
}

export class SetFilter {
  public static readonly type = "[Users] Set Filter";
  constructor(public filter: Partial<UserFilter>, public arrayAction: ArrayAction = "replace") {}
}

export class SetPageState {
  public static readonly type = "[Users] Set Page State";
  constructor(public pageState: Partial<PageState>) {}
}

export class GetFiltered {
  public static readonly type = "[Users] Get Filtered";
}

export class AddUser {
  public static readonly type = "[Users] Add User";
  constructor(public userId: string) {}
}

export class GetUser {
  public static readonly type = "[Users] Get User";
  constructor(public userId: string) {}
}

export class UpdateUser {
  public static readonly type = "[Users] Update User";
  constructor(public userId: string, public user: Partial<User>) {}
}

export class DeleteUser {
  public static readonly type = "[Users] Delete User";
  constructor(public userId: string) {}
}

export class ResetState {
  public static readonly type = "[Users] Reset State";
}
