import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { deepCopy, insert } from "@vp/shared/utilities";
import { UserAdministrationService } from "@vp/user-administration/data-access/user-administration-state";
import { BehaviorSubject, EMPTY, Observable, Subject } from "rxjs";
import { first, map, mergeMap, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";

interface LegalNotice {
  id: string;
  expanded?: boolean;
  accepted: boolean;
  cumulative?: boolean;
}

@Component({
  selector: "vp-legal-notice",
  templateUrl: "./legal-notice.component.html",
  styleUrls: ["./legal-notice.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserAdministrationService],
  queries: {
    templateRef: new ViewChild("template")
  }
})
export class LegalNoticeComponent implements OnDestroy, AfterViewChecked {
  destroyed = new Subject();
  form$!: Observable<FormGroup>;
  legalNoticeRequested$: Observable<boolean>;
  templateRef!: ElementRef;

  private readonly _legalNotices$ = new BehaviorSubject<LegalNotice[]>([
    { id: "tos", expanded: true, accepted: false },
    { id: "supplemental", expanded: false, accepted: false },
    { id: "legalNoticedAccepted", accepted: false, cumulative: true }
  ]);

  private _form$ = new BehaviorSubject<FormGroup | null>(null);
  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly appStoreService: AppStoreService
  ) {
    this._form$.next(
      this.formBuilder.group({
        legalNoticedAccepted: [false, Validators.requiredTrue],
        tos: [false, Validators.requiredTrue],
        supplemental: [false, Validators.requiredTrue]
      })
    );

    this.form$ = this._form$.pipe(filterNullMap());
    this.legalNoticeRequested$ = this.appStoreService.user$.pipe(
      map((user: User) => {
        return user?.userData?.flags?.legalNoticeRequested || true;
      }),
      takeUntil(this.destroyed)
    );
  }

  ngAfterViewChecked(): void {
    this.form$
      .pipe(
        mergeMap(form => form.valueChanges),
        withLatestFrom(this._legalNotices$.pipe(map(ln => [...ln]))),
        map(([valueChanges, legalNotices]) => updateEntry(valueChanges, legalNotices, "accepted"))
      )
      .subscribe((notices: LegalNotice[]) => {
        this._legalNotices$.next(notices);
      });
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  isAllNoticesAccepted(): Observable<boolean> {
    return this._legalNotices$.pipe(
      take(1),
      map(e =>
        e
          .filter(e => e?.cumulative === undefined || e?.cumulative === false)
          .every(ln => ln.accepted === true)
      )
    );
  }

  isAllAccepted(): Observable<boolean> {
    return this._legalNotices$.pipe(
      take(1),
      map(e => e.every(ln => ln.accepted === true))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isExpanded(id: string): Observable<boolean> {
    return this._legalNotices$.pipe(
      take(1),
      map(e => e.find(ln => ln.id === id)?.expanded === true)
    );
  }

  isAccepted(id: string): Observable<boolean> {
    return this._legalNotices$.pipe(
      take(1),
      map(e => e.find(ln => ln.id === id)?.accepted === true)
    );
  }

  toggleOpened(id: string) {
    this._legalNotices$
      .pipe(
        take(1),
        map((notices: LegalNotice[]) => [...notices]),
        map((notices: LegalNotice[]) => {
          notices.forEach(notice => {
            if (notice.id === id) {
              notice.expanded = true;
            } else {
              notice.expanded = false;
            }
          });
          return notices;
        })
      )
      .subscribe(notices => {
        this._legalNotices$.next(notices);
      });
  }

  accepted(id: string) {
    this._legalNotices$
      .pipe(
        take(1),
        map((notices: LegalNotice[]) => {
          let nextIndex = 0;
          notices.forEach((notice, index) => {
            if (notice.id === id) {
              notice.expanded = false;
              notice.accepted = true;
              nextIndex = index + 1;
            }
          });
          if (nextIndex > 0) {
            const next = notices[nextIndex];
            if (next && !next?.cumulative && next.expanded === false) {
              next.expanded = true;
            }
          }
          return notices;
        })
      )
      .subscribe(notices => {
        this._legalNotices$.next(notices);
      });
  }

  onAccept() {
    this.form$
      .pipe(
        withLatestFrom(this.appStoreService.user$),
        switchMap(([form, user]: [FormGroup, User]) => {
          if (form.value.legalNoticedAccepted === true) {
            const modified = deepCopy(user);
            // set legalNoticeRequested to false on the user so they are no longer prompted
            insert({
              targetObject: modified,
              props: ["userData", "flags"],
              objToInsert: {
                legalNoticeRequested: false
              }
            });
            return this.appStoreService.patchUser(modified);
          }
          form.markAllAsTouched();
          return EMPTY;
        }),
        first()
      )
      .subscribe({
        next: () => {
          this.router.navigate(["/default"]);
        }
      });
  }
}

const updateEntry = (values: Record<string, unknown>, entries: any[], field: string) => {
  return Object.entries(values).reduce((collector, [key, value]) => {
    const notice = collector.find(n => n.id === key);
    if (notice !== undefined) {
      notice[field] = value;
    }
    return collector;
  }, entries);
};
