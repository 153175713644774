<ng-container
  *ngIf="{
    disabled: disabled | async
  } as vm"
>
  <div class="mb-3">
    <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="30px">
      <mat-hint class="mat-hint" *ngIf="to.label && to.hideLabel !== true">{{ to.label }}</mat-hint>
      <div fxLayout="row" fxLayoutAlign="start end" *ngIf="showSnippet() && !vm.disabled">
        <mat-form-field>
          <mat-label>Templates</mat-label>
          <mat-select [(value)]="selectedSnippet">
            <mat-option *ngFor="let snippet of snippets" [value]="snippet">
              {{ snippet.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button
          mat-icon-button
          color="primary"
          (click)="addNewSnippet()"
          fxFlexAlign="center"
          color="primary"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
        <button
          mat-raised-button
          (click)="insertText(editor)"
          fxFlexAlign="center"
          color="primary"
          [disabled]="selectedSnippet === undefined"
        >
          Insert text
        </button>
      </div>
    </div>

    <angular-editor
      [lib-DisableEditor]="vm.disabled ?? false"
      [config]="config"
      [formlyAttributes]="field"
      [formControl]="formControl"
      #editor
      (focusout)="saveSelection()"
      (keyup)="saveSelection()"
    >
    </angular-editor>
    <span class="mat-hint" *ngIf="to.description && to.hideDescription !== true">{{ to.description }}</span>
  </div>
</ng-container>
