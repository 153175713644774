import { Inject, Injectable, InjectionToken } from "@angular/core";
import {
  adminPermissions,
  casePermissions,
  caseQueuePermissions,
  contentManagementPermissions,
  dashboardPermissions,
  directConnectPermissions,
  globalPermissions,
  patientPermissions,
  profilePermissions
} from "@vp/models";
import * as changeCase from "change-case";

export interface SelectItem {
  label: string;
  value: string;
}
export interface SelectGroup {
  name: string;
  label: string;
  items: SelectItem[];
}

export const ACTION_SEPARATOR = "can";

export const isSelectGroup = (selectGroup: any): selectGroup is SelectGroup => {
  return (selectGroup as SelectGroup)?.label !== undefined;
};

export const isSelectItem = (selectItem: any): selectItem is SelectItem => {
  return (selectItem as SelectItem)?.label !== undefined;
};

/**
 * Permissions are made of two parts: <object>Can<action>
 */
export const getPermissionParts = (
  permission: string
): { permissionObject: string; permissionAction: string } => {
  const parts = changeCase.dotCase(permission).split(".");
  const sepIndex = parts.indexOf(ACTION_SEPARATOR);
  if (sepIndex === -1) {
    return {
      permissionObject: "Incompatible permissions",
      permissionAction: permission
    };
  }
  const permissionObject = parts.slice(0, sepIndex).join(".");
  const permissionAction = parts
    .slice(sepIndex + 1) // action after separator
    .concat(parts.slice(0, sepIndex)) // object before separator
    .join(".");
  return {
    permissionObject,
    permissionAction
  };
};

export const arrayToSelectGroups = (flatArray: string[]): SelectGroup[] => {
  const groups: SelectGroup[] = [];
  flatArray.sort().forEach(arrayItem => {
    const { permissionObject, permissionAction } = getPermissionParts(arrayItem);
    const groupName = changeCase.camelCase(permissionObject);
    const groupLabel = changeCase.capitalCase(permissionObject);
    const itemLabel = changeCase.sentenceCase(permissionAction);
    const found = groups.find(group => group.name === groupName);
    if (found) {
      found.items.push({
        label: itemLabel,
        value: arrayItem
      });
    } else {
      groups.push({
        name: groupName,
        label: groupLabel,
        items: [
          {
            label: itemLabel,
            value: arrayItem
          }
        ]
      });
    }
  });
  // Custom sort to pre-defined order based on permission actions
  const predefinedOrder = ["read", "write", "delete"];
  groups.forEach(group => {
    group.items.sort((a: SelectItem, b: SelectItem) => {
      const partsA = changeCase.dotCase(a.value).split(".");
      const partsB = changeCase.dotCase(b.value).split(".");
      const lastA = partsA.slice(partsA.length - 1).join();
      const lastB = partsB.slice(partsB.length - 1).join();
      if (predefinedOrder.indexOf(lastA) === -1) {
        return 1;
      }
      if (predefinedOrder.indexOf(lastB) === -1) {
        return -1;
      }
      return predefinedOrder.indexOf(lastA) - predefinedOrder.indexOf(lastB);
    });
  });

  return groups;
};

/**
 * Flatten select groups helper
 */
export const selectGroupToSelectItems = (groups: SelectGroup[]): SelectItem[] => {
  return groups.reduce((flatArray: SelectItem[], groupsArray) => {
    return flatArray.concat(groupsArray.items);
  }, []);
};

/**
 * Flatten the permissions object constant
 */
export const flattenPermissionsConst = (nestedObjects: { [x: string]: any }): string[] => {
  return Object.getOwnPropertyNames(nestedObjects).reduce(
    (all: string[], _object: any): string[] => {
      if (typeof nestedObjects[_object] === "object") {
        // If we have a object, let's add their values too
        all.push(...flattenPermissionsConst(nestedObjects[_object]));
      } else if (typeof nestedObjects[_object] === "string") {
        // Otherwise, add the current object's string value to all
        all.push(nestedObjects[_object]);
      }
      // Return all of them
      return all;
    },
    [] // init all
  );
};

export const PERMISSIONS_CONST_ENVIRONMENT_IS_PROD = new InjectionToken<boolean>(
  "ENVIRONMENT_IS_PROD"
);
@Injectable({
  providedIn: "root"
})
export class PermissionsConstService {
  constructor(@Inject(PERMISSIONS_CONST_ENVIRONMENT_IS_PROD) private _isProd: boolean) {}

  /**
   * These are the permissions.
   */
  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  readonly Global = globalPermissions;
  readonly Case = casePermissions;
  readonly Patient = patientPermissions;
  readonly Dashboard = dashboardPermissions; // Maybe move to Global
  readonly Admin = adminPermissions;
  readonly Profile = profilePermissions;
  readonly DirectConnect = directConnectPermissions;
  readonly Content = contentManagementPermissions;
  readonly CaseQueue = caseQueuePermissions;

  toList = (): string[] => flattenPermissionsConst(this);

  toSelectGroups = (): SelectGroup[] => arrayToSelectGroups(flattenPermissionsConst(this));

  /**
   * Once the user logs in, a call to `PermissionsConstService.check(permissionTags)`
   * will make sure the expected tags are represented here.
   */
  check(permissionTags: string[]) {
    if (this._isProd) {
      return;
    }

    const missing: { [x: string]: { error: string } } = {};
    const master = permissionTags;
    const target = this.toList();
    master.forEach(tag => {
      if (!target.includes(tag)) {
        missing[tag] = { error: "Missing in code (PermissionsConstService)" };
      }
    });
    target.forEach(tag => {
      if (!master.includes(tag)) {
        missing[tag] = { error: "Missing in database (Organization.PermissionTags)" };
      }
    });

    // Test for non-empty object and show warning if needed
    if (Object.keys(missing).length !== 0) {
      /* eslint-disable no-console */
      console.warn(
        "Permission check failed. Expand values shown in 'permission.check' console table."
      );
      console.groupCollapsed("permission.check");
      console.table(missing);
      console.groupEnd();
    }
  }
}
