import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from "@angular/core";
import { PaymentPayload, PaymentTransactionResult, TransactionStatusEvent } from "@vp/models";
import { CaseContextService } from "@vp/shared/case-context";
import { CybersourceService } from "@vp/shared/cybersource-service";
import { EventAggregator } from "@vp/shared/event-aggregator";
import { FeatureService } from "@vp/shared/features";
import { FeeService } from "@vp/shared/fee-service";
import { NotificationService } from "@vp/shared/notification";
import { BehaviorSubject, EMPTY, Observable, Subject, timer } from "rxjs";
import { finalize, switchMap, takeUntil } from "rxjs/operators";

declare var Flex: any;

@Component({
  selector: "vp-cybersource-payment",
  templateUrl: "./cybersource-payment.component.html",
  styleUrls: ["./cybersource-payment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CybersourcePaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  microform: any;

  fee$ = new BehaviorSubject<number>(0);
  yearList: number[] = [];
  isPaid$ = new BehaviorSubject<boolean>(false);
  isLoading$ = new BehaviorSubject<boolean>(true);
  cybersourceAdditionalMsg$ = new Observable<string>();
  private readonly destroyed$ = new Subject();
  private caseId = "";

  constructor(
    private feeService: FeeService,
    private featureService: FeatureService,
    private caseContextService: CaseContextService,
    private cybersouceService: CybersourceService,
    private notificationService: NotificationService,
    private eventAggregator: EventAggregator
  ) {
    this.populateYearList();
    this.cybersourceAdditionalMsg$ = this.featureService.configurationValue$(
      "caseDashboard",
      "cybersourceAdditionalMsg"
    );
  }

  ngOnInit(): void {
    this.isLoading$.next(true);
    this.caseContextService.caseData$.pipe(takeUntil(this.destroyed$)).subscribe(caseData => {
      this.caseId = caseData.caseId;
    });
    this.feeService.totalFees$.subscribe(fee => this.fee$.next(fee));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.fee$
      .pipe(
        switchMap(fee => {
          if (fee <= 0) {
            this.isPaid$.next(true);
            return EMPTY;
          } else {
            this.isPaid$.next(false);
            // token expires after 15 min, refresh token every 10 min
            return timer(1, 600000).pipe(
              switchMap(_ => this.cybersouceService.getTransientToken())
            );
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe(token => this.loadMicroform(token));
  }

  private loadMicroform(token: any) {
    const style = {
      input: {
        "font-size": "16px",
        color: "#3A3A3A"
      },
      ":disabled": {
        cursor: "not-allowed"
      },
      valid: {
        color: "green"
      },
      invalid: {
        color: "red"
      }
    };
    const flex = new Flex(token.keyId);
    this.microform = flex.microform({ styles: style });
    const cardNumber = this.microform.createField("number", {
      placeholder: "Enter card number"
    });
    const securityCode = this.microform.createField("securityCode", {
      placeholder: "***"
    });

    cardNumber.load("#number-container");
    securityCode.load("#securityCode-container");
    this.isLoading$.next(false);
  }

  onSubmit(form: any) {
    this.isLoading$.next(true);
    const options = {
      expirationMonth: form.value.expMonth,
      expirationYear: form.value.expYear.toString()
    };

    this.microform.createToken(options, (err: any, token: any) => {
      if (err) {
        switch (err.reason) {
          case "CREATE_TOKEN_VALIDATION_FIELDS":
            this.notificationService.errorMessage("Invalid Card");
            break;
          default:
            this.notificationService.errorMessage("Payment error, please try again");
            break;
        }
        this.isLoading$.next(false);
      } else {
        const payload = {
          totalAmount: this.fee$.getValue().toFixed(2),
          currency: "USD",
          clientReferenceInformation: `caseId:${this.caseId}`,
          transientToken: token
        } as PaymentPayload;
        this.cybersouceService
          .submitPayment(this.caseId, payload)
          .pipe(
            finalize(() => {
              this.isLoading$.next(false);
            })
          )
          .subscribe((transactionResult: PaymentTransactionResult) => {
            this.eventAggregator.emit(
              new TransactionStatusEvent(transactionResult),
              "cybersource.submit"
            );
            if (transactionResult.status === "success") {
              this.isPaid$.next(true);
              this.notificationService.successMessage("Payment success");
            } else {
              this.notificationService.errorMessage("Payment failed");
            }
            return EMPTY;
          });
      }
    });
  }

  private populateYearList() {
    const year = new Date().getFullYear();
    for (let index = 0; index < 15; index++) {
      this.yearList.push(year + index);
    }
  }
}
