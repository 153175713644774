import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Tag } from "@vp/models";
import { CachingDataService } from "@vp/shared/services/cache";
import { Operation } from "rfc6902";
import { Observable } from "rxjs";
import { TAG_API_BASE_URL } from "../state+/tags-state";

export type TagModel = Omit<
  Partial<Tag>,
  "lastUpdatedBy" | "lastUpdatedDateTime" | "createdBy" | "createdDateTime"
>;

@Injectable({
  providedIn: "root"
})
export class TagsApiService {
  constructor(
    @Inject(TAG_API_BASE_URL) private apiBaseUrl: string,
    private http: HttpClient,
    private cachingDataService: CachingDataService<Tag[]>
  ) {}

  getTags = (useCache: boolean = true): Observable<Tag[]> => {
    if (useCache) {
      return this.cachingDataService.getCachedData("allTags", "user", 10000, this.apiBaseUrl);
    }
    return this.http.get<Tag[]>(this.apiBaseUrl);
  };

  addTag = (tag: TagModel): Observable<Tag> => {
    return this.http.post<Tag>(`${this.apiBaseUrl}/${tag.tagId}`, tag);
  };

  updateTag = (tag: TagModel): Observable<Tag> => {
    return this.http.put<Tag>(`${this.apiBaseUrl}/${tag.tagId}`, tag);
  };

  deleteTag = (tagId: string): Observable<Tag> => {
    return this.http.delete<Tag>(`${this.apiBaseUrl}/${tagId}`);
  };

  patch = (tagId: string, operations: Operation[]) => {
    const apiURL = `${this.apiBaseUrl}/${tagId}`;
    return this.http.patch(apiURL, operations);
  };
}
