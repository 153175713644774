/*
  The component allows the selection of multiple groups depending on the
  groupType configuration singleAssignment. Unieue assignment is processed
  on the backend (groups that have already been assigned are not passed to the
  front end).
*/

import { ChangeDetectionStrategy, Component, OnDestroy, TrackByFunction } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectionListChange } from "@angular/material/list";
import { Select } from "@ngxs/store";

import { Column, Group, GroupRef, GroupType, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { compareBy } from "@vp/shared/utilities";
import {
  UserAdministrationService,
  UserAdministrationState,
  UserOperations
} from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, withLatestFrom } from "rxjs/operators";

@Component({
  selector: "vp-user-assign-groups",
  templateUrl: "./user-assign-groups.component.html",
  styleUrls: ["./user-assign-groups.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAssignGroupsComponent implements OnDestroy {
  @Select(UserAdministrationState.assignableGroupTypes)
  assignableGroupTypes$!: Observable<GroupType[]>;
  @Select(UserAdministrationState.assignableGroups)
  assignableGroups$!: Observable<Group[]>;

  filters: Record<string, unknown> | undefined = undefined;
  excludeProperties: string[] = [];
  groupTypeSelector = new FormControl();

  columns$!: Observable<Column[]>;
  items$!: Observable<Group[]>;
  search$!: Observable<string | null>;
  selected$!: Observable<Group[]>;
  hasSelected$: Observable<boolean>;
  selectedCount$: Observable<number>;

  private readonly _destroyed$ = new Subject();
  private readonly _columns$ = new BehaviorSubject<Column[]>([]);
  private readonly _search$ = new BehaviorSubject<string | null>(null);
  private readonly _selected$ = new BehaviorSubject<Group[]>([]);
  private readonly _filters$ = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {
    this.search$ = this._search$.asObservable();
    this.selected$ = this._selected$.asObservable();
    this.hasSelected$ = this._selected$.pipe(map(items => items.length > 0));
    this.selectedCount$ = this._selected$.pipe(map(items => items.length));

    this.columns$ = this._columns$.pipe(
      switchMap((columns: Column[]) => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([
            this.permConst.Admin.User.GroupAssignment.Write
          ])
        ]);
      }),
      map(([columns, hasWritePermissions]) => {
        if (hasWritePermissions) {
          const cols: Column[] = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );

    this.items$ = combineLatest([
      this.assignableGroups$,
      this.userAdministrationService.workingCopy$.pipe(filterNullMap()),
      this._filters$
    ]).pipe(
      map(([assignable, workingCopy, filters]: [Group[], User, string[]]) => {
        const assignedGroupIds: string[] = workingCopy.groups.map(g => g.groupId);
        assignable = assignable.filter(item => assignedGroupIds.includes(item.groupId) === false);
        if (filters.length > 0) {
          return assignable.filter(item => filters.includes(item.groupTypeId));
        }
        return assignable.sort(compareBy(g => g.displayName));
      }),
      takeUntil(this._destroyed$)
    );
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  searched(event: string) {
    this._search$.next(event);
  }

  assignSelected() {
    this.selected$
      .pipe(
        withLatestFrom(
          this.assignableGroupTypes$,
          this.userAdministrationService.workingCopy$.pipe(filterNullMap())
        ),
        concatMap(([selected, groupTypes, workingCopy]: [Group[], GroupType[], User]) => {
          const groupsToRemove: string[] = [];
          handleSelectedGroups(selected, groupTypes, workingCopy, groupsToRemove);
          return combineLatest([
            of(selected),
            this.userAdministrationService.deleteGroups$(groupsToRemove)
          ]);
        }),
        concatMap(([selected]: [Group[], UserOperations]) => {
          return this.userAdministrationService.addGroups$(selected);
        }),
        take(1)
      )
      .subscribe();
  }

  selectionChanged(event: MatSelectionListChange) {
    this.selected$
      .pipe(
        withLatestFrom(this.assignableGroupTypes$),
        concatMap(([selected, groupTypes]: [Group[], GroupType[]]) => {
          const group: Group = event.options[0].value;
          handleSelectedEvent(event, groupTypes, group, selected);
          return of(selected);
        }),
        take(1)
      )
      .subscribe(selected => {
        this._selected$.next(selected);
      });
  }

  groupTypeFilterChanged = (selected: string[]) => {
    this._filters$.next(selected);
  };

  isSelected(item: Group): Observable<boolean> {
    return this.selected$.pipe(
      map((selected: Group[]) => selected.findIndex(i => i.groupId === item.groupId) > -1)
    );
  }

  trackById: TrackByFunction<Group> = (_: number, item: Group) => item.groupId;
}
const handleSelectedEvent = (
  event: MatSelectionListChange,
  groupTypes: GroupType[],
  group: Group,
  selected: Group[]
) => {
  if (event.options[0].selected) {
    const groupType: GroupType | undefined = groupTypes.find(
      t => t.groupTypeId === group.groupTypeId
    );
    if (groupType?.singleAssignment && selected.length >= 1) {
      for (let index = selected.length - 1; index >= 0; index--) {
        const group: Group = selected[index];
        if (group.groupTypeId === groupType.groupTypeId) selected.splice(index, 1);
      }
    }
    selected.push(group);
  } else {
    const index = selected.indexOf(group);
    selected.splice(index, 1);
  }
};

const handleSelectedGroups = (
  selected: Group[],
  groupTypes: GroupType[],
  workingCopy: User,
  groupsToRemove: string[]
) => {
  selected.forEach((group: Group) => {
    const groupType: GroupType | undefined = groupTypes.find(
      t => t.groupTypeId === group.groupTypeId
    );
    if (groupType?.singleAssignment) {
      const groupRef: GroupRef | undefined = workingCopy.groups.find(
        g => g.groupTypeId == groupType.groupTypeId
      );
      if (groupRef) groupsToRemove.push(groupRef.groupId);
    }
  });
};
