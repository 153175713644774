/*
  This component implements the two use cases of:
  1.  Allowing the selection of one role when the userTypeConfig's
      singleDepartmentAssignment and singleRoleAssignment are both true
  2.  Otherwise, allows the selection of multiple roles amongst multiple departments
*/
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TrackByFunction
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSelectionListChange } from "@angular/material/list";
import {
  AssignedRolePerDepartment,
  Column,
  Organization,
  User,
  UserRole,
  UserTypeConfig
} from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import {
  RolesAssignmentService,
  UserAdministrationService
} from "@vp/user-administration/data-access/user-administration-state";
import { NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import {
  concatMap,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";

@Component({
  selector: "vp-user-assign-roles",
  templateUrl: "./user-assign-roles.component.html",
  styleUrls: ["./user-assign-roles.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAssignRolesComponent implements OnInit, OnDestroy {
  singleDepartmentAssignment = false;
  singleRoleAssignment = false;

  filters: Record<string, unknown> | undefined = undefined;
  excludeProperties: string[] = [];
  departmentSelector = new FormControl();
  columns$!: Observable<Column[]>;
  items$!: Observable<AssignedRolePerDepartment[]>;
  search$!: Observable<string | null>;
  selected$!: Observable<AssignedRolePerDepartment[]>;
  columns = [
    {
      field: "department",
      header: "Department"
    },
    {
      field: "role",
      header: "Role"
    }
  ];

  // Abastract
  departments$!: Observable<string[]>;

  private readonly _destroyed$ = new Subject();
  private readonly _columns$ = new BehaviorSubject<Column[]>([]);
  private readonly _search$ = new BehaviorSubject<string | null>(null);
  private readonly _selected$ = new BehaviorSubject<AssignedRolePerDepartment[]>([]);
  private readonly _filters$ = new BehaviorSubject<string[]>([]);

  constructor(
    private readonly appStore: AppStoreService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly rolesAssignmentService: RolesAssignmentService,
    private readonly userAdministrationService: UserAdministrationService,
    public permConst: PermissionsConstService
  ) {
    this.search$ = this._search$.asObservable();
    this.selected$ = this._selected$.asObservable();
  }

  ngOnInit(): void {
    this.userAdministrationService.workingCopy$
      .pipe(
        filterNullMap(),
        withLatestFrom(this.userAdministrationService.organization$),
        takeUntil(this._destroyed$)
      )
      .subscribe(([workingCopy, org]: [User, Organization]) => {
        const userTypeConfig: UserTypeConfig | undefined = org.userTypeConfig.find(
          c => c.type === workingCopy.userType.friendlyId
        );
        this.singleDepartmentAssignment = userTypeConfig?.singleDepartmentAssignment ?? false;
        this.singleRoleAssignment = userTypeConfig?.singleRoleAssignment ?? false;
      });

    this.items$ = this.rolesAssignmentService.assignableEntities$.pipe(
      mergeMap((assignable: AssignedRolePerDepartment[]) => {
        return combineLatest([of(assignable), this._filters$]);
      }),
      map(([assignable, filters]: [AssignedRolePerDepartment[], string[]]) => {
        if (filters.length > 0) {
          return assignable.filter(item => filters.includes(item.departmentId));
        }
        return assignable;
      }),
      takeUntil(this._destroyed$)
    );

    // TODO: Abstract this into its own component
    this.departments$ = this.appStore.user$.pipe(
      map(user => user.roles),
      map((userRoles: UserRole[]) =>
        userRoles
          .reduce((depts: string[], role: UserRole) => {
            const departments = role.departments.map(d => d.departmentId);
            depts = depts.concat(departments);
            return depts;
          }, [])
          .filter((value: string, index: number, array: string[]) => {
            return array.indexOf(value) === index;
          })
      )
    );

    this.columns$ = this._columns$.pipe(
      switchMap((columns: Column[]) => {
        return combineLatest([
          of(columns),
          this.ngxPermissionsService.hasPermission([this.permConst.Case.Assign.User.Write])
        ]);
      }),
      map(([columns, hasWritePermissions]) => {
        if (hasWritePermissions) {
          const cols: Column[] = [...columns];
          cols.push({
            field: "actions",
            header: "Actions"
          } as Column);
          return cols;
        }
        return columns;
      })
    );

    this._columns$.next(this.columns);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  searched(event: string) {
    this._search$.next(event);
  }

  assignSelected() {
    this.selected$
      .pipe(
        concatMap((roles: AssignedRolePerDepartment[]) => {
          if (this.singleDepartmentAssignment && this.singleRoleAssignment) {
            if (roles.length > 1) throw Error("More than one role selected");
            return this.userAdministrationService.addSingleDepartmentRole$(roles[0]);
          }
          return this.userAdministrationService.addDepartmentRoles$(roles);
        }),
        take(1)
      )
      .subscribe();
  }

  selectionChanged(event: MatSelectionListChange) {
    this._selected$
      .pipe(
        take(1),
        concatMap((selected: AssignedRolePerDepartment[]) => {
          if (this.singleDepartmentAssignment && this.singleRoleAssignment) {
            if (event.options[0].selected) {
              return of([event.options[0].value]);
            }
          }
          if (event.options[0].selected) {
            selected.push(event.options[0].value);
          } else {
            const index = selected.indexOf(event.options[0].value);
            selected.splice(index, 1);
          }
          return of(selected);
        })
      )
      .subscribe((selected: AssignedRolePerDepartment[]) => {
        this._selected$.next(selected);
      });
  }

  departmentsFilterChanged = (selected: string[]) => {
    this._filters$.next(selected);
  };

  isSelected(item: AssignedRolePerDepartment): Observable<boolean> {
    return this._selected$.pipe(
      map(
        (selected: AssignedRolePerDepartment[]) =>
          selected.findIndex(
            i => i.roleId === item.roleId && i.departmentId === item.departmentId
          ) > -1
      )
    );
  }

  trackById: TrackByFunction<AssignedRolePerDepartment> = (
    _: number,
    item: AssignedRolePerDepartment
  ) => item.roleId + item.departmentId;
}
