import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replaceString"
})
export class ReplaceStringPipe implements PipeTransform {
  transform(value: string, strToReplace: string, replacementStr: string): string {
    const regex = new RegExp(strToReplace, "g");
    return value.replace(regex, replacementStr);
  }
}
